<template>
  <div class="home">
    <AccessProcess title="TimeSheet logowanie" />
       
  </div>
</template>

<script>
import AccessProcess from "@/components/AccessProcess.vue";

export default {
  name: "Access",
  components: {
    AccessProcess,
  },
};
</script>
