<template>
      <Loader :loader="loader" />

      <base-dialog :show="error != null" :showCloseIcon="false" title="Something Fishy" @close="error == null">
            <template v-slot:content>                  
                  <div class="inv-wrapper nwd-wrap">
                        <div class="inv-form-item err-item">
                              <label>{{error}}</label>    
                        </div>
                  </div>
            </template>
             <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="error=null" title="Close"><i class="fas fa-times"></i></base-button>                  
            </template>
      </base-dialog>

      <base-dialog :show="showDialog" :title="dialogTitle" @close="showDialog=false">
            <template v-slot:content>
                  
                  <div class="inv-wrapper nwd-wrap">
                        <div class="inv-form-item">
                              <label>Description</label>
                              <input type="text" name="descr" id="descr" v-model="newPosition">  
                                    
                        </div>
                       
                        <div class="inv-form-item">
                              <label>Amount</label>
                              <input type="text" name="amount" id="amount" v-model="newAmount" @keypress="isNumber">         
                        </div>
                  

                  </div>
                  
            
            </template>

            <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="showDialog=false" title="Cancel"><i class="fas fa-times"></i></base-button>
                  <base-button class="d-btn d-edit" @click="onSaveClick()" title="Save"><i class="fas fa-save"></i></base-button>
            </template>
      </base-dialog>


      <div class="inner-wrap3 nwd-wrap np-wrap">
     
            <h3>Job title</h3>
                  <div class="criteria">
                        <div v-if="uFnCheck(11)" class="control-wrap">
                        <span>Agency: &nbsp;</span>
                        <vue-select 
                        v-model="agencyId" 
                        :options="agencies" 
                        label-by="Description" 
                        value-by="Id" 
                        :blur="onChange"
                        close-on-select />                               
                  </div>  
                        <div class="control-wrap">
                           
                              <span>New job title: &nbsp;</span>
                              <button title="New position" class="d-btn new-d" name="newPosition" @click="newPositionClick()"><i class="fas fa-plus"></i></button>      
                        </div>  
                  </div>

            <div class="center-pan">

            <vue-good-table
                  :columns="table1columns"
                  :rows="table1rows"
                  :line-numbers="false"
                  :search-options="{enabled: false}"
                  :sort-options="{enabled: true}"  
                  :pagination-options="{
                  mode: 'records'
                  }" 
                              
            >
                  <template v-slot:emptystate>
                  No data
                  </template>

                  <template v-slot:table-row="props">
                        <div v-if="props.column.field == 'Action'">
                                    <button title="Edit" class="d-btn" :name="props.row.Date" @click="onBtnClick(props.row.Id, props.row.Amount, props.row.Description)"><i class="fas fa-edit"></i></button>
                                    <button title="Delete" class="d-btn" :name="props.row.Date" @click="onBtnClick(props.row.Id, -111,'')"><i class="fas fa-trash"></i></button>
                        </div>
                        <span v-else>
                              {{props.formattedRow[props.column.field]}}
                        </span>
                  </template>
                  
            </vue-good-table>
            </div>        

      </div>
  
</template>

<script>
import shared from "@/shared";
// import moment from "moment";
import Loader from '../../components/Loader.vue';
import adminService from "@/services/admin.js";


export default {

      components: {
            Loader,           
      },
     
      data: function () {

            return {
                  showNav: true,
                  error: null,
                  loader: false,
                  showDialog: false,
                  showDialogNewPosition: false,
                  dialogTitle: '',
                  settDarkMode: false, 
                  newPosition: null,
                  newAmount: 0,
                  selectedPositionId: null,
                  agencyId: this.$store.getters.userData.agencyId,
                  agencies: [],
                  table1columns: [
                  {
                  label: 'Id',
                  field: 'Id',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Job title',
                  field: 'Description',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Users No.',
                  field: 'UsersCount',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Amount',
                  field: 'Amount',
                  tdClass: 'text-f14'
                  },
                  {
                  label: '',
                  field: 'Action',
                  tdClass: 'text-center',
                  sortable: false,
                  width: '128px',
                  }
                  ],
                  table1rows: []
                  
            };
      },

      created() {
            this.uFnCheck = shared.uFnCheck;
            this.CookieRemove = shared.CookieRemove;
      },

      mounted() {
            this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
            
            this.CookieCheckGoToLogin();

            this.getSettingFromArr = shared.getSettingFromArr;
            this.setTheme = shared.setTheme;

            this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
            this.setTheme(this.settDarkMode);

            const data = {ResultName: "AgencyList"};
            adminService
                  .uniRap(data)
                  .then((response) => {

                  if (response.status === 200) {                  
            
                        this.agencies = response.data;    

                         setTimeout(() => {                 
                              this.agencyId = parseInt(this.$store.getters.userData.agencyId);
                              this.onChange();
                         },380);
                  }
                  
                  })
                  .catch((e) => shared.handleError.call(this, '141', true, e));
           
            
            
      },

      methods: {

            isNumber(event) {
                  if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
            },

            onChange() {
                  this.loader = true;
                  
                  console.log('data change');


                  adminService
                  .positionList(this.agencyId)
                  .then((response) => {

                  if (response.status === 200) {          

                        this.table1rows = response.data;

                        // console.log("----table1rows----");
                        // console.log(this.table1rows);
                                          
                  }
                  
                  })
                  .catch((e) => shared.handleError.call(this, '142', false, e))
                  .finally(() => {
                  this.loader = false;
                  
                   
                  });

            },

            newPositionClick() {


                  this.newPosition = '';
                  this.newAmount = 0;
                  this.selectedPositionId = 0;


                  this.showDialogNewDay = true;
                  this.showDialog = true;
                  this.dialogTitle = 'New job title';

            },

            onBtnClick(PositionId, PositionAmount, PositionDescr) {

                  this.selectedPositionId = PositionId;

                  
                  if ( PositionAmount === -111) {

                        let data = {
                              id: PositionId,
                              descr: PositionDescr,
                              amount: PositionAmount,
                              agencyId: this.agencyId
                        };

                        this.positionSave(data);
                  }
                  else {
                       
                        this.showDialog = true;
                        this.dialogTitle = 'Edit job title  Id: '+PositionId;
   
                        
                        this.newPosition = PositionDescr;
                        this.newAmount = PositionAmount;

                        console.log('--on btn click');
                        console.log(this.selectedPositionId);
                        console.log(this.newPosition);
                        console.log(this.newAmount);

                  }

            },

            onSaveClick() {


                        let data = {
                              id: this.selectedPositionId,
                              descr:  this.newPosition,
                              amount: this.newAmount,
                              agencyId: this.agencyId
                        };

                        this.positionSave(data);

                        this.showDialog = false;
                        this.showDialogNewDay = false;
                        this.selectedPositionId = null;
                        this.newPosition = null;
                        this.newAmount = null;


            },


            positionSave(dataObj) {
                  
                  adminService
                  .positionEdit(dataObj)
                  .then((response) => {
                        

                        if ((response.status === 200) & (response.data.Code === "OK")) {
                              // console.log('response------');
                              // console.log(response);

                              console.log('--SAVED--');
                              this.onChange();
                   
                        } 
                        else {
                              this.error = response.data.Text;
                        }

                         console.log('response------');
                         console.log(response.data);


                  })
                  .catch((e) => shared.handleError.call(this, '143', true, e));


            }
      }

}
</script>

<style >

.d-btn {
    width: 38px;
    padding: 2px;
    margin: 0 5px;
}

.d-btn.new-d {
    padding: 10px;
    width: 46px;
}


.inv-wrapper.nwd-wrap .inv-form-item {
    margin: 8px 12px;
}


.inv-wrapper .inv-form-item input.dp-custom-input {
      width: 180px;
}

.inv-wrapper.nwd-wrap .inv-form-item label {
    text-align: center;
    margin-bottom: 12px;
}

.inv-wrapper.nwd-wrap .inv-form-item .vue-select input {
    border: none;
    padding: 8px 6px 4px 6px;
}

dialog .nwd-wrap .vue-input {
    padding: 4px 6px 6px 6px;
}

.dialog-box.edit .nwd-wrap .vue-select {
    width: 180px;
    margin: 0 auto;
}

.dialog-box.edit .nwd-wrap .vue-dropdown {
    max-height: 100px !important;
}


.text-center div {
      text-align: center !important;
}

.inner-wrap3.nwd-wrap .vue-select {
      width: 150px;
      margin-right: 16px;
}







</style>