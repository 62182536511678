<template>
    <Loader :loader="loader" />
   
    <div class="inner-wrap2 w-fin">
      <!-- <p style="font-size: 28px; font-family: 'Anton-Regular';">coming soon</p> -->
      <h3>Entries Employment Cost by month</h3>
      <div class="criteria">
        <div class="control-wrap r-fin">
            <span>Year: &nbsp;</span>
              <vue-select 
                v-model="year" 
                :options="years" 
                label-by="value" 
                value-by="no" 
                :blur="onDateChange"
                close-on-select />


            <span>Quarterly: </span>
            <label class="rad-label">
                  <input type="checkbox" class="rad-input" name="quarterly" v-model="quarterly" @change="onDateChange()" />
                  <div class="rad-design"></div>
            </label>
           
        </div>
        
        <div class="control-wrap r-fin excel">
            <vue-excel-xlsx class="d-btn ex-btn"
            :data="itemsEx"
            :columns="headersEx"
            :file-name="filename"
            :file-type="'xlsx'"
            :sheet-name="'Entries EC'"
            :disabled="itemsEx == null"
            title="export to Excel"
            >
            <i class="fas fa-file-excel"></i>
          </vue-excel-xlsx>
        </div>
      </div>


      <div class="radio-type">
          <ul class="answer-list">
                <li>
                      <label class="rad-label">
                      <input type="radio" class="rad-input" name="pc1" value="1" v-model="rapType" :checked="rapType == 1" @change=" onDateChange()" />
                      <div class="rad-design"></div>
                      <div class="rad-text">Clients</div>
                      </label>                        
                </li>
                <li>
                      <label class="rad-label">
                      <input type="radio" class="rad-input" name="pc2" value="2" v-model="rapType" :checked="rapType == 2" @change="onDateChange()" />
                      <div class="rad-design"></div>
                      <div class="rad-text">Jobs</div>
                      </label>                        
                </li>
          
          </ul>
        </div>
      
      <div v-if="uFnCheck(11)" class="criteria">  
        <div class="control-wrap agency-ec">
              <span>Agency: &nbsp;</span>
              <vue-select 
                v-model="agencyId" 
                :options="agencies" 
                label-by="Description" 
                value-by="Id" 
                :blur="onDateChange"
                close-on-select />                    
        </div>         
      </div>

      <div class="center-pan">
         
        <div v-if="!quarterly" class="m-view">
          <vue-good-table
            :columns="headersM"
            :rows="itemsM"
            :row-style-class="rowStyleClass"
            :line-numbers="false"
            :search-options="{enabled: true}"
            :sort-options="{enabled: true}"  
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 20
            }"                           
          >
            <template v-slot:emptystate>
                No data
            </template>            
            
          </vue-good-table>

        </div>
        <div v-else class="q-view">
          <vue-good-table
            :columns="headersQ"
            :rows="itemsQ"
            :row-style-class="rowStyleClass"
            :line-numbers="false"
            :search-options="{enabled: true}"
            :sort-options="{enabled: true}"  
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 20
            }" 
                       
                          
          >
            <template v-slot:emptystate>
                No data
            </template>

            <template #table-column="props">
              <div v-if="regMonth(props.column.field)">
                <div class="abs-m">
                  {{props.column.label2}} 
                </div>
                <div>
                  {{props.column.labelT}}
                </div>
              </div>
             
              <div v-else>
                  {{props.column.labelT}}
              </div>
            </template>
            
          </vue-good-table>

        </div>
        
      </div>
        

    </div>
 
  
</template>

<script>
import shared from "@/shared";
import moment from "moment";
//import reportService from "@/services/reports.js";
import financesService from "@/services/finances.js";
import adminService from "@/services/admin.js";
import Loader from '../../components/Loader.vue';
// import { DoughnutChart } from 'vue-chart-3';
// import { BarChart } from 'vue-chart-3';
// import { Chart, registerables } from "chart.js";

//Chart.register(...registerables);

export default {
  components: {
      Loader, 
      //DoughnutChart, 
      //BarChart 
  },
  name: "App",
  
  

  data: function () {
    return {
      year: 2022,
      showNav: true,
      error: null,
      loader: false,
      agencyId: this.$store.getters.userData.agencyId,
      agencies: [],
      quarterly: false,
      rapType: 1, //1- clients, 2- Jobs
      filename: 'filename',
      years: [],
      headersMC: [
                        { label2: "Client Name", labelT: "Client Name", label: "Client Name", field: "ClientName", tdClass: 'text-f14' },                        
                        { label2: "Jan", labelT: "Hrs", label: "Jan [h]", field: "m1h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Feb", labelT: "Hrs", label: "Feb [h]", field: "m2h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                        
                        { label2: "Mar", labelT: "Hrs", label: "Mar [h]", field: "m3h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                        
                        { label2: "Apr", labelT: "Hrs", label: "Apr [h]", field: "m4h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                       
                        { label2: "May", labelT: "Hrs", label: "May [h]", field: "m5h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                      
                        { label2: "Jun", labelT: "Hrs", label: "Jun [h]", field: "m6h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                       
                        { label2: "Jul", labelT: "Hrs", label: "Jul [h]", field: "m7h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                        
                        { label2: "Aug", labelT: "Hrs", label: "Aug [h]", field: "m8h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                        
                        { label2: "Sep", labelT: "Hrs", label: "Sep [h]", field: "m9h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                      
                        { label2: "Oct", labelT: "Hrs", label: "Oct [h]", field: "m10h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'},                        
                        { label2: "Nov", labelT: "Hrs", label: "Nov [h]", field: "m11h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'},                     
                        { label2: "Dec", labelT: "Hrs", label: "Dec [h]", field: "m12h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'},
                        { label2: "Sum", labelT: "Sum [h]", label: "Sum [h]", field: "mhSum", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'},
                        { label2: "Sum", labelT: "Sum [pln]", label: "Sum [pln]", field: "mecSum", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'}
                      
                  ],
      headersMJ: [
                        { label2: "Client Name", labelT: "Client Name", label: "Client Name", field: "ClientName", tdClass: 'text-f14' },
                        { label2: "Job No", labelT: "Job No", label: "Job No", field: "JobNo", tdClass: 'text-f14' },
                        { label2: "Job Name", labelT: "Job Name", label: "Job Name", field: "JobName", tdClass: 'text-f14' },
                        { label2: "Jan", labelT: "Hrs", label: "Jan [h]", field: "m1h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Feb", labelT: "Hrs", label: "Feb [h]", field: "m2h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                        
                        { label2: "Mar", labelT: "Hrs", label: "Mar [h]", field: "m3h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                        
                        { label2: "Apr", labelT: "Hrs", label: "Apr [h]", field: "m4h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                       
                        { label2: "May", labelT: "Hrs", label: "May [h]", field: "m5h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                      
                        { label2: "Jun", labelT: "Hrs", label: "Jun [h]", field: "m6h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                       
                        { label2: "Jul", labelT: "Hrs", label: "Jul [h]", field: "m7h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                        
                        { label2: "Aug", labelT: "Hrs", label: "Aug [h]", field: "m8h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                        
                        { label2: "Sep", labelT: "Hrs", label: "Sep [h]", field: "m9h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },                      
                        { label2: "Oct", labelT: "Hrs", label: "Oct [h]", field: "m10h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'},                        
                        { label2: "Nov", labelT: "Hrs", label: "Nov [h]", field: "m11h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'},                     
                        { label2: "Dec", labelT: "Hrs", label: "Dec [h]", field: "m12h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'},
                        { label2: "Sum", labelT: "Sum [h]", label: "Sum [h]", field: "mhSum", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'},
                        { label2: "Sum", labelT: "Sum [pln]", label: "Sum [pln]", field: "mecSum", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'}                      
                  ],
      headersQC: [
                        { label2: "Client Name", labelT: "Client Name", label: "Client Name", field: "ClientName", tdClass: 'text-f14' },                       
                        { label2: "Q-1", labelT: "Hrs", label: "Q1-Hrs", field: "q1h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-1", labelT: "EC", label: "Q1-EC", field: "q1ec", type: 'number', tdClass: 'text-f14' },
                        { label2: "Q-2", labelT: "Hrs", label: "Q2-Hrs", field: "q2h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-2", labelT: "EC", label: "Q2-EC", field: "q2ec", type: 'number', tdClass: 'text-f14' },
                        { label2: "Q-3", labelT: "Hrs", label: "Q3-Hrs", field: "q3h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-3", labelT: "EC", label: "Q3-EC", field: "q3ec", type: 'number', tdClass: 'text-f14' },
                        { label2: "Q-4", labelT: "Hrs", label: "Q4-Hrs", field: "q4h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-4", labelT: "EC", label: "Q4-EC", field: "q4ec", type: 'number', tdClass: 'text-f14' },
                        { label2: "Sum", labelT: "Sum [h]", label: "Sum [h]", field: "qhSum", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'},
                        { label2: "Sum", labelT: "Sum [pln]", label: "Sum [pln]", field: "qecSum", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'}                       
                  ],
      headersQJ: [
                        { label2: "Client Name", labelT: "Client Name", label: "Client Name", field: "ClientName", tdClass: 'text-f14' },
                        { label2: "Job No", labelT: "Job No", label: "Job No", field: "JobNo", tdClass: 'text-f14' },
                        { label2: "Job Name", labelT: "Job Name", label: "Job Name", field: "JobName", tdClass: 'text-f14' },
                        { label2: "Q-1", labelT: "Hrs", label: "Q1-Hrs", field: "q1h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-1", labelT: "EC", label: "Q1-EC", field: "q1ec", type: 'number', tdClass: 'text-f14' },
                        { label2: "Q-2", labelT: "Hrs", label: "Q2-Hrs", field: "q2h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-2", labelT: "EC", label: "Q2-EC", field: "q2ec", type: 'number', tdClass: 'text-f14' },
                        { label2: "Q-3", labelT: "Hrs", label: "Q3-Hrs", field: "q3h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-3", labelT: "EC", label: "Q3-EC", field: "q3ec", type: 'number', tdClass: 'text-f14' },
                        { label2: "Q-4", labelT: "Hrs", label: "Q4-Hrs", field: "q4h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-4", labelT: "EC", label: "Q4-EC", field: "q4ec", type: 'number', tdClass: 'text-f14' },
                        { label2: "Sum", labelT: "Sum [h]", label: "Sum [h]", field: "qhSum", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'},
                        { label2: "Sum", labelT: "Sum [pln]", label: "Sum [pln]", field: "qecSum", type: 'number', tdClass: 'text-f14', thClass: 'th-custom'}                       
                  ],
      headersM: [],
      itemsM: [],
      headersQ: [],
      itemsQ: [],
      headersEx: [],
      itemsEx: []

      
    };
  },

  created() {
    this.yearList = shared.yearList;
    this.uFnCheck = shared.uFnCheck;

    this.years = this.yearList();
    this.year = this.$store.getters.getCurrYear;

    

    // console.log('years');
    // console.log(this.years);
    // console.log(this.year);

  },
 
  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    this.CookieCheckGoToLogin();   

    
    const data = {ResultName: "AgencyList"};
    adminService
      .uniRap(data)
      .then((response) => {

      if (response.status === 200) {                  

            this.agencies = response.data;    

            // console.log('agencies');
            // console.log(this.agencies);

            //this.agencies.unshift({Id: 0, Description: '-- All Agencies --'});

              setTimeout(() => {                         
                  
                  this.agencyId = parseInt(this.$store.getters.userData.agencyId);
              }, 300);
      }
      
      })
      .catch((e) => {
      this.error = e.message || "Something went wrong!";
      
      
      if (e.response.status === 401) {
            console.log("go to CR");
            this.CookieRemove();
      }
      });
    


    this.setFileName();   

    setTimeout(() => {
            this.getData();
    }, 360);

   
   
  },
   
  methods: {

    regMonth(str) {
      const regex = /^[a-zA-Z]\d+[a-zA-Z]$/;
      return regex.test(str);
    },

    setFileName() {


      let prefix = this.rapType == 1 ? 'Entries-EC-Clients' : 'Entries-EC-Jobs';
      let type = this.quarterly ? 'Q' : 'M' ;    

      this.filename = ''+prefix+'-'+type+'-year-'+this.year+'-'+moment().format("YYMMDD.HHmmss");

    },

    

    getData() {

          let res = this.quarterly ? (this.rapType == 1 ? 'rapEntriesECQC' : 'rapEntriesECQJ') : (this.rapType == 1 ? 'rapEntriesECMC' : 'rapEntriesECMJ');

          const data = {
                "ResultName": res,
                "Year": this.year,
                "AgencyId": this.agencyId
          };

            // console.log("----data----");
            // console.log(data); 
          

          this.loader = true;
          financesService
          .uniRap(data)
          .then((response) => {

          if (response.status === 200) {


            this.headersM = [];
            this.itemsM = [];
            this.headersQ = [];
            this.itemsQ = [];
            this.headersEx = [];
            this.itemsEx = [];
            
          
            if (this.quarterly) {
              this.itemsQ = JSON.parse(response.data);               
              this.itemsEx = this.itemsQ;
              
              if (this.rapType == 1) {
                this.headersQ = this.headersQC;                                
              }            
              else {
                this.headersQ = this.headersQJ;
              }
                  
              this.headersEx = this.headersQ;
            }
            else {
              this.itemsM = JSON.parse(response.data);      
              this.itemsEx = this.itemsM;    
              
              if (this.rapType == 1) {
                this.headersM = this.headersMC;                                
              }            
              else {
                this.headersM = this.headersMJ;
              }
              
              this.headersEx = this.headersM;              
            }           

          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";
          
          
          if (e.response.status === 401) {
                console.log("go to CR");
                this.CookieRemove();
          }
          })
          .finally(() => {
          this.loader = false;
          });

    },

  
    onDateChange() {

      console.log('data change');

      this.setFileName();
      this.getData();   
      
     },

 
    rowStyleClass(row) {

      return row.name == '-Total-' ? 'cl-total' : (row.status == 'Inactive' ? 'cl-row-inactive' : 'cl-row');
    },

  },
  computed: {
    
  }

}
</script>
<style>

  .inner-wrap2.w-fin .criteria .control-wrap.r-fin {
      justify-content: flex-end;
  }

  .inner-wrap2.w-fin .criteria .control-wrap.t-list {
      justify-content: center;
  }

  .inner-wrap2.w-fin .criteria .control-wrap.t-list .vue-select {
      width: 369px;
  }

  .inner-wrap2.w-fin .criteria .control-wrap.agency-ec {
    justify-content: center;
  }


  .inner-wrap2.w-fin .r-fin .vue-select, 
  .inner-wrap2.w-fin .r-fin .vue-select .vue-dropdown {
    z-index: 6;
  }


  

  .th-custom .abs-m {
      position: absolute;
      top: 0px;
      right: -12px;
      background-color: #F1F3F6;
      padding: 5px 2px 9px 2px;
      z-index: 2;
  }


  .inner-wrap2.w-fin .th-custom {
    padding-top: 30px;
    font-size: 13px;
  }

  
  .inner-wrap2.w-fin .vgt-table th.sortable button:before,
  .inner-wrap2.w-fin .vgt-table th.sortable button:after {
    right: 5px;
    top: 69%;
  }



  .dark-mode .th-custom .abs-m {

      background-color: #2c2f36;

  }

</style>

