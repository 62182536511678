<template>
  <div class="main">
    <div class="container">
      <h1>404</h1>
      <h2>Don't You cryyy tonight<br/>
      Don't you ever cry, cry cryyy ...</h2>
      <h3><router-link to="/home" class="rl">just go home</router-link></h3>
    </div>
  </div>
  
</template>

<script>
export default {

}
</script>

<style scoped>

.main {
  width: 100%;
  height: 100%;
  position: relative;
}

.container {
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.main::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-color: black;
  background-image: url('../assets/dontYouCry-bw.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: -1;

}

h1 {
  font-size: 148px;
  margin-bottom: 54px;
}

.rl {
      color: inherit;
      text-decoration: none;

      background:
      linear-gradient(
            to right,
            #a10b0b,
            #730202
      ),
      linear-gradient(
            to right,
            rgba(255, 0, 0, 1),
            rgba(255, 0, 180, 1),
            rgba(0, 100, 200, 1)
      );
      background-size: 100% 2px, 0 2px;
      background-position: 100% 100%, 0 100%;
      background-repeat: no-repeat;
      transition: background-size 400ms;
}

.rl:hover {
      background-size: 0 2px, 100% 2px;
}




</style>