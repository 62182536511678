<template>
<router-link :to="{ name: 'Home'}">
<div class="m-wrapper">
      <div id="fly-in">  
            <div><span></span>stars</div>
            <div><span>Father the Director</span>Marcin Nieśmiałek</div>
            <div><span>the Polish Mother</span>Ola Jusińska</div>
            <div><span>JS and DB Programmer</span>Maciek Prucnal</div>
            <div><span>JS and .Net API Programmer</span>Marcin Olech</div>
            <div><span>Uncle the good advice</span>Sławek Zwarycz</div>
            <div><span>stuntsmen</span>Arek Narbutt<br/>Łukasz Owadowski<br/>Michał Margis<br/>Wojtek Panas</div>
            <div><span>with special guest</span></div>
            <div><span>Boss of all LQT Bosses</span>Łukasz Borek</div>
            <div><span class="b-big"><i class="fab fa-vuejs"></i> TimeThread <i class="fas fa-history"></i></span></div>
            <div></div>
            
      </div>
</div>
</router-link>
</template>

<script>
export default {

}
</script>

<style scoped>

.m-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #111;
      background-image: radial-gradient(#333, #111);
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: fixed;
      color: #fff;
      text-align: center;
      width: 100vw;
      font-weight: 700;
      overflow: hidden;
      font-family: 'Lato-Regular', Helvetica, Arial, sans-serif;
}

#fly-in {
  font-size: 3.25em;
  margin: 40vh auto;
  height: 20vh; 
  opacity: .9;
  /* text-transform: uppercase; */
}

#fly-in span {
  display: block;
  font-size: .4em;
  opacity: .8;
}

#fly-in span.b-big {
  display: block;
  font-size: 3.45em;
  opacity: .8;
}

#fly-in div {
 position: fixed; 
  margin: 2vh 0;
  opacity: 0;
  left: 10vw;
  width: 80vw;
  animation: switch 53s linear infinite;
}

#fly-in div:nth-child(2) { animation-delay: 5s}
#fly-in div:nth-child(3) { animation-delay: 10s}
#fly-in div:nth-child(4) { animation-delay: 15s}
#fly-in div:nth-child(5) { animation-delay: 20s}
#fly-in div:nth-child(6) { animation-delay: 25s}
#fly-in div:nth-child(7) { animation-delay: 30s}
#fly-in div:nth-child(8) { animation-delay: 35s}
#fly-in div:nth-child(9) { animation-delay: 40s}
#fly-in div:nth-child(10) { animation-delay: 45s}
#fly-in div:nth-child(11) { animation-delay: 50s}

@keyframes switch {
    0% { opacity: 0;filter: blur(20px); transform:scale(12)}
    3% { opacity: 1;filter: blur(0); transform:scale(1)}
    10% { opacity: 1;filter: blur(0); transform:scale(.9)}
    13% { opacity: 0;filter: blur(10px); transform:scale(.1)}
    80% { opacity: 0}
    100% { opacity: 0}
}

</style>