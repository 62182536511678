import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/Login.vue";
import Home from "../views/Home.vue";
import MissingJobs from "../views/MissingJobs.vue";
import Settings from "../views/Settings.vue";
import Reports from "../views/Reports.vue";
import Access from "../views/Access.vue";
import About from "../views/About.vue";
import v404 from "../views/v404.vue";
import Projects from "../views/Projects.vue";
import NoOwnerJobs from "../views/NoOwnerJobs.vue";
import Admin from "../views/Admin.vue";
import Finances from "../views/Finances.vue";

import RawData from "../views/Reports/RawData.vue";
import Dashboard from "../views/Reports/Dashboard.vue";
import JobsByMonth from "../views/Reports/JobsByMonth.vue";
import UsersByMonths from "../views/Reports/UsersByMonths.vue";
import AgenciesByMonth from "../views/Reports/AgenciesByMonth.vue";
import ClientsAgenciesPeriod from "../views/Reports/ClientsAgenciesPeriod.vue";
import ActiveNoJobsList from "../views/Reports/ActiveNoJobsList.vue";
import UsersWork from "../views/Reports/UsersWork.vue";
import JobInfo from "../views/Reports/JobInfo.vue";
import ProjectAmounts from "../views/Reports/ProjectAmounts.vue";

import AdmDashboard from "../views/Admin/Dashboard.vue";
import AdmJobs from "../views/Admin/Jobs.vue";
import AdmUsers from "../views/Admin/Users.vue";
import AdmPositions  from "../views/Admin/Positions.vue";
import AdmNoOwnerJobs from "../views/Admin/NoOwnerJobs.vue";
import AdmMissingJobs from "../views/Admin/MissingJobs.vue";
import AdmUsersWork from "../views/Reports/UsersWork.vue";
import NonWorkingDays from "../views/Admin/NonWorkingDays.vue";


import FnlDashboard from "../views/Finances/Dashboard.vue";
import AnnualSummaryRap from "../views/Finances/AnnualSummaryRap.vue";
import EntriesPC from "../views/Finances/EntriesPC.vue";
import EntriesEC from "../views/Finances/EntriesEC.vue";
import ClientsPeriod from "../views/Finances/ClientsPeriod.vue";

import MobileHome from "../views/MobileHome.vue";
import MobileReport from "../views/Mobile/Report.vue";


const routes = [
      {
            path: "/",
            name: "Login",
            component: Login,
      },
      {
            path: "/home",
            name: "Home",
            component: Home,
      },
      {
            path: "/home-mobile",
            name: "MobileHome",
            component: MobileHome,
            children: [
              {
               
                path: 'report-mobile',
                name: 'MobileReport',
                component: MobileReport
              }
            ]
      },
      {
            path: "/missing-jobs",
            name: "MissingJobs",
            component: MissingJobs,
      },
      {
            path: "/no-owner-jobs",
            name: "NoOwnerJobs",
            component: NoOwnerJobs,
      },
      {
            path: "/projects",
            name: "Projects",
            component: Projects,
      },
      {
            path: "/settings",
            name: "Settings",
            component: Settings,
      },
      {
            path: "/reports",
            name: "Reports",
            component: Reports,
            children: [
                  {
                   
                    path: 'raw-data',
                    name: 'RawData',
                    component: RawData
                  },
                  {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard
                    
                  },
                  {
                    path: 'jobs-by-month',
                    name: 'JobsByMonth',
                    component: JobsByMonth
                  },
                  {
                    path: 'users-by-months',
                    name: 'UsersByMonths',
                    component: UsersByMonths
                  },
                  {
                    path: 'agencies-by-months',
                    name: 'AgenciesByMonth',
                    component: AgenciesByMonth
                  },
                  {
                    path: 'client-agencies-by-period',
                    name: 'ClientsAgenciesPeriod',
                    component: ClientsAgenciesPeriod
                  },                  
                  {
                    path: 'no-job-list',
                    name: 'ActiveNoJobsList',
                    component: ActiveNoJobsList
                  },
                  {
                    path: 'users-work',
                    name: 'UsersWork',
                    component: UsersWork
                  },
                  {
                    path: 'project-amounts',
                    name: 'ProjectAmounts',
                    component: ProjectAmounts
                  },
                  {
                    path: 'job-info',
                    name: 'JobInfo',
                    component: JobInfo
                  }  
                ]

      },
      {
            path: "/admin",
            name: "Admin",
            component: Admin,
            children: [
                  {
                    path: 'dashboard',
                    name: 'AdmDashboard',
                    component: AdmDashboard
                  },
                  {
                    path: 'jobs',
                    name: 'AdmJobs',
                    component: AdmJobs
                  },
                  {
                    path: 'users',
                    name: 'AdmUsers',
                    component: AdmUsers
                  },
                  {
                    path: 'positions',
                    name: 'AdmPositions',
                    component: AdmPositions
                  },
                  {
                    path: 'non-working-days',
                    name: 'NonWorkingDays',
                    component: NonWorkingDays
                  },
                  {
                    path: 'missing-jobs',
                    name: 'AdmMissingJobs',
                    component: AdmMissingJobs
                  },
                  {
                    path: 'no-owner-jobs',
                    name: 'AdmNoOwnerJobs',
                    component: AdmNoOwnerJobs
                  },
                  {
                    path: 'users-work',
                    name: 'AdmUsersWork',
                    component: AdmUsersWork
                  }
                ]

      },
      {
            path: "/finances",
            name: "Finances",
            component: Finances,
            children: [
                  {
                    path: 'dashboard',
                    name: 'FnlDashboard',
                    component: FnlDashboard
                  },
                  {
                    path: 'summary',
                    name: 'AnnualSummaryRap',
                    component: AnnualSummaryRap
                  },
                  {
                    path: 'entries-pc',
                    name: 'EntriesPC',
                    component: EntriesPC
                  },
                  {
                    path: 'entries-ec',
                    name: 'EntriesEC',
                    component: EntriesEC
                  },
                  {
                    path: 'clients-period',
                    name: 'ClientsPeriod',
                    component: ClientsPeriod
                  }
                ]

      },
      {
            path: "/about",
            name: "About",
            component: About,
      },
      {
            path: "/access/:Uid",
            name: "Access",
            component: Access,
      },
      {
            path: "/:catchAll(.*)",
            name: "v404",
            component: v404,
      }

];

const router = createRouter({
      history: createWebHashHistory(process.env.BASE_URL),
      routes,
});
    
export default router;