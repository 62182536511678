<template>
    <Loader :loader="loader" />
   
    <div class="inner-wrap">
      <!-- <p style="font-size: 28px; font-family: 'Anton-Regular';">coming soon</p> -->
     
     <h3 v-if="employeeId === null">My Dashboard</h3>
     <h3 v-else @click="resetToMe()" class="r-cursor">Dashboard</h3>
      <div class="ds-person">{{userName}}</div>
      
     <div class="criteria">

       <span>Month: &nbsp;</span>
          <date-picker v-model="month" 
          @closed="onDateChange"
          menuClassName="dp-custom-menu"
          monthPicker 
          monthNameFormat="long"
          autoApply
          required
          :enableTimePicker="false"
          :dark="settDarkMode"
          >

             <template #input-icon>
                      &nbsp;&nbsp;<i class="far fa-calendar-alt"></i>
             </template>

          </date-picker>

     </div>

      <div class="chart-1">
        <div class="left-pan">

          <DoughnutChart 
            :chartData="donutData"
            :options="donutOptions"
            :height="320"
          />


        </div>
        <div class="right-pan">
          
          <vue-good-table
            :columns="table1columns"
            :rows="table1rows"
            :line-numbers="false"
            :search-options="{
              enabled: false
            }"
            :sort-options="{enabled: false}"   
                          
          >
          <template v-slot:emptystate>
              No data
          </template>
          
          </vue-good-table>

        </div> 
      </div>

      <div class="chart-2">
        <div class="left-pan">

          <BarChart 
            :chartData="barData"
            :options="barOptions"
            :height="320"
          />

        
        </div>
        <div class="right-pan">
          
          <DoughnutChart 
            :chartData="donut2Data"
            :options="donut2Options"
            :height="320"
          />
          
        </div> 
      </div>
     
     
      <!-- {{rapJobsData}}<br>
      {{rapHoursData}}<br>
      {{rapClientsData}}
      <br> -->

    </div>
 
  
</template>

<script>
import shared from "@/shared";
import reportService from "@/services/reports.js";
import Loader from '../../components/Loader.vue';
import { DoughnutChart } from 'vue-chart-3';
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default {
  components: {Loader, DoughnutChart, BarChart },
  name: "App",
  
  data: function () {
    return {
      userName: this.$store.getters.userData.userName,
      employeeId: null,     
      month: {},
      showNav: true,
      error: null,
      loader: true,
      settDarkMode: false,
      rapJobsData: [],
      rapHoursData: [],
      rapClientsData: [],
      donutOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Top 5 Jobs',
          },
        },
      },
      donut2Options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            display: true,
          },
          title: {
            display: true,
            text: 'Top 5 Clients',
          },
        },
      },
      barOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            display: false
          },
          title: {
            display: true,
            text: 'Days in a month',
          },
        },
        scales: {
            x: {
              title: {
                display: true,
                text: 'Days'
              },
              grid: {
                color: '#545454'
              }
            },
            y: {
              title: {
                display: true,
                text: 'Hours'
              },
              grid: {
                color: '#545454'
              }             
            }
         }
      },
      donutData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED', '#1cd4ce'],
            borderColor:  ['#fff']
          }
        ]
      },
      donut2Data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['#bf8aff', '#8c46e0', '#4e1d8a', '#e66cbd', '#9e2b77', '#942936'],
            borderColor:  ['#fff']
          }
        ]
      },
      barData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['#f21313'],
            borderColor: ['#f21313'],
            borderRadius: 2
          }
        ]
      },
      table1columns: [
        {
          label: 'Job Number',
          field: 'jobNo',
          tdClass: 'text-f14'
        },
        {
          label: 'Job Name',
          field: 'jobName',
          tdClass: 'text-f14',
          html: true
        },
        {
          label: 'Hours',
          field: 'hours',
          type: 'number',
          tdClass: 'text-f14',
          html: true

        }
      ],
      table1rows: []

    };
  },

  

 
  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.getSettingFromArr = shared.getSettingFromArr;
    this.setTheme = shared.setTheme;
    this.CookieCheckGoToLogin();
    this.CookieRemove = shared.CookieRemove;


    if (this.$store.getters.getRapDashUserId !== null) {
      this.employeeId = this.$store.getters.getRapDashUserId;
      this.userName = this.$store.getters.getRapDashUserName;
      this.$store.commit('setRapDashUserId', null);
      this.$store.commit('setRapDashUserName', null);
    }

    this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
    this.setTheme(this.settDarkMode);

    if (this.settDarkMode) {
      this.donutData.datasets[0].borderColor = ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED', '#1cd4ce'];
      this.donut2Data.datasets[0].borderColor = ['#bf8aff', '#8c46e0', '#4e1d8a', '#e66cbd', '#9e2b77', '#942936'];
      this.barOptions.scales.x.grid.color = '#545454';
      this.barOptions.scales.y.grid.color = '#545454';
    }
    else {
      this.donutData.datasets[0].borderColor = ['#fff'];
      this.donut2Data.datasets[0].borderColor = ['#fff'];
      this.barOptions.scales.x.grid.color = '#d1d1d1';
      this.barOptions.scales.y.grid.color = '#d1d1d1';
    }

    // console.log('borderColorArr1');
    // console.log(this.donutData.datasets[0].borderColor);

    this.month = {
      month: new Date().getMonth(), 
      year: new Date().getFullYear()
      };

    this.onDateChange();

   
  },
   
  methods: {

    resetToMe() {
      this.employeeId = null;
      this.userName = this.$store.getters.userData.userName;
      this.onDateChange();
    },

  
    onDateChange() {
      this.loader = true;

      const data = {
        "month": this.month.month + 1, 
        "year": this.month.year,
        "employeeId": this.employeeId
      };

      Promise.all([reportService.rap1jobs(data), reportService.rap1hours(data), reportService.rap1clients(data)])
      .then(([rapJobs, rapHours, rapClients]) => {
        if (rapJobs.status === 200) {
          this.rapJobsData = rapJobs.data;
          // console.log("----rapJobsData----");
          // console.log(this.rapJobsData);
          
        }

        if (rapHours.status === 200) {
          this.rapHoursData = rapHours.data;
        }

        if (rapClients.status === 200) {
          this.rapClientsData = rapClients.data;
        }
      })
      .catch((e) => {
        console.log("----error----");
        console.dir(e);

        this.error = e.message || "Something went wrong!";

        if (e.responses[0].status === 401) {
            this.CookieRemove();
        }
      })
      .finally(() => {
        this.loader = false;
        this.donutArr();
        this.tableArr();
        this.barArr();
        this.donut2Arr();
        //this.showNav = true;

      });

    },

    donutArr() {
      this.donutData.labels = [];
      this.donutData.datasets[0].data = [];

      this.rapJobsData.forEach(element => {
         this.donutData.labels.push(element.jobNo);
         this.donutData.datasets[0].data.push(element.hours);
      });
    },

    tableArr() {
      this.table1rows = this.rapJobsData;

      if (this.table1rows.length > 1) {

        let sum = 0.0;
        this.table1rows.forEach(element => {
          sum = sum + element.hours;
          
        });
        this.table1rows.push( { "jobNo": "", "jobName": "<b>Total</b>", "hours": "<b>"+sum+"</b>" });
      }

    },

    barArr() {
      this.barData.labels = [];
      this.barData.datasets[0].data = [];
      this.barData.datasets[0].backgroundColor = [];
      let dd = 1;
      let color = '#489464';

      this.rapHoursData.forEach(element => {
         this.barData.labels.push(element.date);
         this.barData.datasets[0].data.push(element.hours);

         dd = new Date(element.date).getDay();
         if( dd == 6 || dd == 0 ) {
            color = '#c9c30c';
         }
         else {

            if(element.hours < 3) {
              color = '#7bc998';
            } 
            else if (element.hours >= 3 && element.hours < 6 ) {
              color = '#489464';
            } 
            else {
              color = '#236e3f';
            }

         }

         this.barData.datasets[0].backgroundColor.push(color);
      });

      // console.log('barData');
      // console.log(this.barData);


    },
    donut2Arr() {
      this.donut2Data.labels = [];
      this.donut2Data.datasets[0].data = [];

      this.rapClientsData.forEach(element => {
         this.donut2Data.labels.push(element.clientName);
         this.donut2Data.datasets[0].data.push(element.hours);
      });
    },
 

  },
  computed: {
    
  }

}
</script>

<style >

.inner-wrap {
  text-align: center;

}

.inner-wrap .r-cursor {
  cursor: pointer;
}

.inner-wrap h3 {
  font-family: 'Anton-Regular';
  font-size: 22px;
  letter-spacing: 2px;
  margin-bottom: 0;
}

.inner-wrap .ds-person {
  font-size: 12px;
}

.inner-wrap .dp__main {
  width: 260px;
}

.inner-wrap .criteria {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 26px;
}

.inner-wrap .chart-1,
.inner-wrap .chart-2 {
  display: flex;
}


.inner-wrap .left-pan,
.inner-wrap .right-pan {
    box-shadow: 0px 3px 16px -5px rgb(120 122 123);
    position: relative;
    background-color: white;
    margin: 10px;
    padding: 18px;
   
}

.inner-wrap .left-pan {
   margin-left: 20px;
}

.inner-wrap .right-pan {
   margin-right: 20px;
}

.inner-wrap .chart-1 .left-pan,
.inner-wrap .chart-1 .right-pan {
    flex-grow: 1;
    width: 50%;
}

.inner-wrap .chart-2 .left-pan {
    flex-grow: 2;
    width: 70%;
}
.inner-wrap .chart-2 .right-pan {
    flex-grow: 1;
    width: 30%;
}


.inner-wrap .text-f14 {
  font-size: 14px;
}

.inner-wrap2 .text-f12 {
    font-size: 12px;
}


.dark-mode .center-pan,
.dark-mode .left-pan,
.dark-mode .right-pan {
  box-shadow: 0px 3px 16px -5px rgb(24 24 24) !important;
  background-color: #1c2630 !important;
   
}

.dark-mode table.vgt-table,
.dark-mode .vgt-table thead th {
    background-color: #1e3245;
    border: 1px solid #3e6487;
}

.dark-mode .vgt-table thead th {
    background: #2c2f36;
}

.dark-mode .vgt-table.bordered td, 
.dark-mode .vgt-table.bordered th {
    border: 1px solid #3e6487;
    color: #d1d1d1;
}

.dark-mode .vgt-global-search,
.dark-mode .vgt-wrap__footer {    
    background-color: #2c3e50;
    border: 1px solid #3e6487;  
    background: #2c3e50;
}

.dark-mode .vgt-global-search__input .input__icon .magnifying-glass {
    border: 2px solid #d1d1d1;
}

.dark-mode .vgt-global-search__input .input__icon .magnifying-glass:before {
    background: #d1d1d1;
}


.dark-mode table.vgt-table .cl-total {
    background-color: #2c2f36 !important;
}


.dark-mode .vgt-input, 
.dark-mode .vgt-select {

    color: #d1d1d1;
    background-color: #1e3245;
    border: 1px solid #3e6487;
}

.dark-mode .inner-wrap2 table.vgt-table td.red1-class {
  background-color: #5c3232;
}
.dark-mode .inner-wrap2 table.vgt-table td.red2-class {
  background-color: #663d1c;
}
.dark-mode .inner-wrap2 table.vgt-table td.green-class {
  background-color: #354d32;
}
.dark-mode .inner-wrap2 table.vgt-table td.yellow-class {
  background-color: #575708;
}

.dark-mode .inner-wrap2 .vue-select,
.dark-mode .inner-wrap3 .vue-select {
    border: 1px solid #2d2d2d !important;
    background-color: #212121 !important;
}

.dark-mode .inner-wrap2 .vue-dropdown,
.dark-mode .inner-wrap3 .vue-dropdown {
    border: 1px solid #3e6487 !important;
}

.dark-mode .inner-wrap2 .vue-dropdown-item.selected.highlighted,
.dark-mode .inner-wrap3 .vue-dropdown-item.selected.highlighted {
    background-color: #1c2630 !important;
}

.dark-mode .inner-wrap2 .vue-dropdown-item.highlighted,
.dark-mode .inner-wrap3 .vue-dropdown-item.highlighted {
    background-color: #212121 !important;
}

.dark-mode .control-wrap.excel .d-btn,
.dark-mode .ex-btn {
    background-color: #1e3245 !important;    
}

.dark-mode .vue-input input {
  color: #d1d1d1 !important;
}

.dark-mode .dp__input:hover,
.dark-mode .vue-select:hover {
  border: 1px solid #3e6487 !important;
}

.dark-mode .dp__preset_range {
  color: #d1d1d1;
}

.dark-mode .inner-wrap3 .center-pan .general-info-wrap {
  border-bottom: 1px solid #3e6487;
}




</style>