import axios from 'axios';
import store from "../store";

const API_URL = store.getters.getApiUrl;

export default {

      async usersList() {
           
            const headers = store.getters.getAuthHeader;
            
            const response = await axios.get(API_URL + "user/list", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async userNoJobCount() {
           
            const headers = store.getters.getAuthHeader;
            
            const response = await axios.get(API_URL + "user/nojob/count", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },


      async userNoJobList() {
           
            const headers = store.getters.getAuthHeader;
            
            const response = await axios.get(API_URL + "user/nojob/list", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async userNoJobOwnList() {
           
            const headers = store.getters.getAuthHeader;
            
            const response = await axios.get(API_URL + "user/nojob/ownList", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async userNoJobUpdate(noJobData) {
            noJobData.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.put(API_URL + "user/nojob/edit", noJobData, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      
      
     
}