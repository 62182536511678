<template>
  <Loader :loader="loader" />
  <nav-header :showNav="showNav" :clearButtons="true">
      <template v-slot:navView>
          <h3>Projects</h3>
      </template>      
     
      <template v-slot:navLog>
        <!-- <button class="nav-btn" @click="showMenu = !showMenu"><i :class="[showMenu ? 'far':'fas', 'fa-user']"></i></button> -->
        <button class="nav-btn" @click="showMenu = !showMenu"><i :class="[showMenu ? 'fa-ellipsis-v':'fa-ellipsis-h', 'fas']"></i></button>
      </template>
  </nav-header>


  <base-dialog :show="showInvDialog" :title="InvDialogTitle" @close="showInvDialog=false">
     <template v-slot:content>
      
      <div :class="['inv-wrapper', {'ex-cost': newInvType==='cost'} ]">
          <div class="inv-form-item">
            <label>Invoice no</label>
            <input type="text" name="no" id="no" v-model="newInvNo">            
          </div>
          <div class="inv-form-item">
            <label>Amount</label>
            <input type="text" name="cost" id="cost" v-model="newInvCost" @keypress="isNumber">            
          </div>
          <div class="inv-form-item">
            <label>Date</label>
            <date-picker v-model="newInvDate" 
                :clearable="false"
                autoApply
                menuClassName="dp-custom-menu"
                :format="format"
                :previewFormat="format"
                :dark="settDarkMode"
                :teleport="true"
                :enableTimePicker="false">

                  <template #input-icon>
                            &nbsp;&nbsp;<i class="far fa-calendar-alt"></i>
                  </template>

          </date-picker>            
          </div>
    
      </div>
      
     
    </template>

    <template v-slot:actions>
      <base-button class="d-btn d-edit" @click="showInvDialog=false" title="Cancel"><i class="fas fa-times"></i></base-button>
      <base-button class="d-btn d-edit" @click="newInvSave" title="Save" :disabled="newInvCost =='' || newInvNo =='' || newInvDate=='' "><i class="fas fa-save"></i></base-button>
    </template>
  </base-dialog>



  <div class="pro-wrapper">  
    <div class="wrap wrap-settings">
      <div class="criteria">
        <span>Search by: &nbsp;</span>
        <div>
          <label class="rad-label">
            <input type="radio" class="rad-input" name="search" :value="1" v-model="searchBy" :checked="true" />
            <div class="rad-design"></div>
            <div class="rad-text">Job number</div>
          </label>
          <label class="rad-label">
            <input type="radio" class="rad-input" name="search" :value="2" v-model="searchBy" :checked="false" />
            <div class="rad-design"></div>
            <div class="rad-text">Project name</div>
          </label>
        </div>
        <div v-if="searchBy == 1" class="control-wrap">                   
          <vue-select  
            v-model="jobId" 
            :options="jobs" 
            label-by="jobNoName" 
            value-by="jobId"
            placeholder="select Job number" 
            searchable 
            clear-on-select 
            clear-on-close 
            close-on-select
            @update:modelValue="loadData"
            class="v-select" 
          >
              <template #dropdown-item="{ option }">
                  <div :class="this.$store.getters.getCurrYear == option.jobYear ? 'y-active':'y-inactive'">{{ option.jobNoName }}</div>
              </template>
          </vue-select>
           
        </div>
        <div v-else class="control-wrap">                   
          <vue-select  
            v-model="projectId" 
            :options="projects" 
            label-by="projectName" 
            value-by="id"
            placeholder="select Project name" 
            searchable 
            clear-on-select 
            clear-on-close 
            close-on-select
            @update:modelValue="loadData"
            class="v-select" />
           
        </div>
      </div>
   
      
    </div>
    <div v-if="jobId==null && projectId==null" class="form-container-empty">
      <p>
        Select job or project name.
      </p>
    </div>
    <div v-else class="form-container">
        <div v-if="projectInfo[0]" class="general-info"> 

          <div v-if="projectNameEditable" class="info-item">
            <label>Project name</label>
            <input type="text" id="proNameEdit" :value="projectInfo[0].projectName">
            <button class="d-btn" @click="editProjectName" title="Save">
              <i class="fas fa-save"></i>
            </button>
          </div>
          <div v-else class="info-item">
            <label>Project name</label>
            <span id="proName" :title="projectInfo[0].id" class="f-bold">{{projectInfo[0].projectName}}</span>
            <button class="d-btn" @click="editProjectName" title="Edit">
              <i class="fas fa-edit"></i>
            </button>
          </div>
          <div v-if="projectNameEditable" class="info-item b-margin te-margin">
            <label>Owner</label>
            <vue-select
              v-model="projectInfo[0].ownerId" 
              :options="usersListGet()" 
              label-by="Name" 
              value-by="Id"
              placeholder="select an owner" 
              searchable 
              clear-on-select 
              clear-on-close 
              close-on-select
            >
              <template #dropdown-item="{ option }">
                <div :class="'st-'+option.Status">{{ option.Name }}</div>
              </template>
            </vue-select>

          </div>
           <div v-else class="info-item b-margin t-margin">
            <label>Owner</label>
            <span>{{projectInfo[0].owner}}</span>
          </div>

          <div class="info-item">
            <label>Status</label>
            <span v-if="projectInfo[0].status === 'True'">Auto generated</span>
            <span v-else>Modified</span>
          </div>
         
          <div class="info-item">
            <label v-if="projectInfo[0].status === 'True'">Created by</label>
            <label v-else>Modified by</label>
            <span>{{projectInfo[0].syslog}}</span>
          </div>
          <div class="info-item">
            <label v-if="projectInfo[0].status === 'True'">Created on</label>
            <label v-else>Modified on</label>
            <span>{{projectInfo[0].sysdate}}</span>
          </div>
          <div class="info-item cost-info ex-cost">
            <label>External cost</label>
            <span>{{noSeparator(projectInfo[0].totalCost)}}</span>
          </div>
          <div class="info-item cost-info">
            <label>Total income</label>
            <span>{{noSeparator(projectInfo[0].totalInvoice)}}</span>&nbsp;
            <!-- <a v-if="!showFI" class="info-item-lnk" href="" @click.prevent="showFI = true">Set forecast income</a> -->
          </div>
          <div class="info-item cost-info">
            <label>Total revenue</label>
            <span>{{noSeparator((projectInfo[0].totalInvoice - projectInfo[0].totalCost).toFixed(2))}}</span> 
          </div>
          <div class="info-item cost-ts-info">
            <label>Total TS hours</label>
            <span>{{projectInfo[0].totalHours.toFixed(1)}}</span>
          </div>
          <div class="info-item cost-ts-info">
            <label>Total TS amount</label>
            <span>{{noSeparator(projectInfo[0].totalTSCost.toFixed(2))}}</span>
          </div>
          <div class="info-item cost-ts-info">
            <label>Total TS amount-30</label>
            <span>{{noSeparator((projectInfo[0].totalTSCost - (projectInfo[0].totalTSCost * 0.3)).toFixed(2))}}</span>
          </div>

          <transition name="fade">
          <div class="info-frcast">              
                <div class="info-item cost-info">
                  <label>Forecast revenue</label>
                  <input type="text" id="forecast" v-model="forecast.reve" @change="forecastSet">                 
                </div>              
                <div class="info-item">
                  <label>Alert threshold</label>
                  <div class="item-slider">
                      <Slider 
                        v-model="forecast.alert" 
                        :min="50"
                        showTooltip="drag"
                        @update="forecastSet"
                      />
                  </div>
                </div>
                
                <div class="info-item">
                  <label>Send alerts</label>
                  <label class="rad-label">
                    <input type="checkbox" class="rad-input" name="sendalerts" v-model="forecast.send" @change="forecastSet" />
                    <div class="rad-design"></div>
                    <div class="rad-text">{{forecast.send ? 'YES' : 'NO'}}</div>
                  </label>
                </div>

                <div class="info-item">
                  <label>Current cost level</label>
                  <div class="item-progress">
                    <k-progress 
                        :percent="forecast.level2" 
                        :color="setProgressColor"
                        :show-text="false"  
                        :bg-color="settDarkMode?'#5c5c5c':'#ebeef5'"                      
                    >
                    </k-progress>
                    <div class="item-progress-lvl">{{ forecast.level }}%</div>
                  </div>
                  
                </div>

          </div>
          </transition>
          
          
        </div>

        <div class="bkm">
          <button :class="['d-btn', 'd-bkm', {'active': activeBkmSum}]" id="d-bkm-1" @click="activeBkmSum = !activeBkmSum" title="Project summary">
            <i class="fas fa-info-circle"></i>
          </button>
          <button :class="['d-btn', 'd-bkm', {'active': !activeBkmSum}]" id="d-bkm-2" @click="activeBkmSum = !activeBkmSum" title="Project statistics">
            <i class="fas fa-chart-line"></i>
          </button>
        </div>



        <div v-if="activeBkmSum" class="bkm-sum">        
          <div class="job-list">
            <ul class="job-list-ul">
              
              <li v-for="(item) in projectjobList" :key="item.id">  
                <div class="general-li">          
                    <div class="general-left">              
                      <div class="info-item">
                        <label>Job Number</label>
                        <span :title="item.id" class="f-bold">{{item.jobNo}}</span>

                        <button v-show="projectjobList.length > 1" class="d-btn d-abs" @click="removeJob(item.id)" title="Remove job from project">
                          <i class="fas fa-minus"></i>
                        </button>

                      </div>
                      <div class="info-item">
                        <label>Client name</label>
                        <span>{{item.clientName}}</span>
                      </div>
                      <div class="info-item">
                        <label>Job name</label>
                        <span class="f-bold">{{item.jobName}}</span>
                      </div>
                      <div class="info-item">
                        <label>SFM registered User</label>
                        <span>{{item.registeredUser}}</span>
                      </div>   
                      <div class="info-item">
                        <label>SFM date from</label>
                        <span>{{item.sfmDateFrom}}</span>
                      </div>
                      <div class="info-item">
                        <label>SFM date to</label>
                        <span>{{item.sfmDateTo}}</span>
                      </div>              


                            
                    </div>

                    <div class="general-right"> 
                      <div class="info-item cost-info ex-cost">
                        <label>Job external cost</label>
                        <span>{{noSeparator(item.totalCost)}}</span>
                      </div>
                      <div class="info-item cost-info">
                        <label>Job total income</label>
                        <span>{{noSeparator(item.totalIncome)}}</span>
                      </div>                                     
                      <div class="info-item cost-ts-info">
                        <label>First registered entry date</label>
                        <span>{{item.dateFrom}}</span>
                      </div>
                      <div class="info-item cost-ts-info">
                        <label>Last registered entry date</label>
                        <span>{{item.dateTo}}</span>
                      </div>
                      <div class="info-item cost-ts-info">
                        <label>Job TS hours</label>
                        <span>{{item.totalHours.toFixed(1)}}</span>
                      </div>
                      <div class="info-item cost-ts-info">
                        <label>Job total TS amount</label>
                        <span>{{noSeparator(item.totalTSCost.toFixed(2))}}</span>
                      </div>
                     
                    
                    </div>
                </div>

                <div class="btn-wrap">
                  <button @click="showCostList(item.id)">
                    <i v-if="showJob==item.id" class="fas fa-angle-up"></i>
                    <i v-else class="fas fa-angle-down"></i>
                  </button>
                </div>
                <!-- <Transition name="slide-fade"> -->
                <collapse-transition :duration="500">  
                <div v-show="showJob==item.id" class="cost-list" :id="'jobId-'+item.id">
                  <div class="cost-title">Job details</div>
                  <ul class="cost-list-ul cost-ul">
                    <li class="title-li ex-title">
                      Cost 
                      <button class="d-btn" @click="newInvoice(item.id, 'cost')" title="New invoice">
                        <i class="fas fa-plus"></i>
                      </button>

                      <button class="d-lnk" @click="jobCostListShowActive = !jobCostListShowActive" :title="jobCostListShowActive ? 'Show all invoices (with removed)' : 'Show active only'">
                        {{jobCostListShowActive ? 'show all' : 'active only'}}
                      </button>

                    </li>
                    <li>  

                      <table>
                        <thead>
                          <tr>
                              <th>Invoice no</th>
                              <th>Date</th>
                              <th>Amount</th>
                              <th>Source</th>
                              <th>Syslog</th>
                              <th>Sysdate</th>
                              <th></th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item2) in fnJobCostList()" :key="item2.id" :class="{'view-del': !item2.active}">
                                <td :title="item2.id">{{item2.supplierInvoiceNumber}}</td>
                                <td>{{item2.accountingDate}}</td>
                                <td>{{noSeparator(item2.matchingCost.toFixed(2))}}</td>
                                <td :class="[{'src-sfm': item2.source==='sfm'}, {'src-ts': item2.source==='ts'}]">{{item2.source}}</td>
                                <td>{{item2.syslog}}</td>
                                <td>{{item2.sysdate}}</td>
                                <td>
                                  <button v-if="item2.active" class="d-btn d-inv" @click="delInvoice(item2.id, 'cost')" title="Remove invoice">
                                    <i class="fas fa-minus"></i>
                                  </button>
                                  <button v-else class="d-btn d-inv" @click="delInvoice(item2.id, 'cost')" title="Restore invoice">
                                    <i class="fas fa-plus"></i>
                                  </button>
                                </td>
                            </tr>
                        </tbody>
                      </table>
                    
                    </li>
                  </ul>

                  <ul class="cost-list-ul invoice-ul">
                    <li class="title-li">
                      Income
                      <button class="d-btn" @click="newInvoice(item.id, 'income')" title="New invoice">
                        <i class="fas fa-plus"></i>
                      </button>

                      <button class="d-lnk right" @click="jobCostInvoiceShowActive = !jobCostInvoiceShowActive" :title="jobCostInvoiceShowActive ? 'Show all invoices (with removed)' : 'Show active only'">
                        {{jobCostInvoiceShowActive ? 'show all' : 'active only'}}
                      </button>
                    </li>
                    <li>
                    
                      <table>
                        <thead>
                          <tr>
                              <th>Invoice no</th>
                              <th>Date</th>
                              <th>Amount</th>
                              <th>Source</th>
                              <th>Syslog</th>
                              <th>Sysdate</th>
                              <th></th>
                          </tr>
                        </thead>
                        <tbody>   
                            <tr v-for="(item2) in fnJobCostInvoice()" :key="item2.id" :class="{'view-del': !item2.active}">
                                <td :title="item2.id">{{item2.sfmNumber}}</td>
                                <td>{{item2.issueDate }}</td>
                                <td>{{noSeparator(item2.ciCost.toFixed(2))}}</td>
                                <td :class="[{'src-sfm': item2.source==='sfm'}, {'src-ts': item2.source==='ts'}]">{{item2.source}}</td>
                                <td>{{item2.syslog}}</td>
                                <td>{{item2.sysdate}}</td>
                                <td>
                                  <button v-if="item2.active" class="d-btn d-inv" @click="delInvoice(item2.id, 'income')" title="Remove invoice">
                                    <i class="fas fa-minus"></i>
                                  </button>
                                   <button v-else class="d-btn d-inv" @click="delInvoice(item2.id, 'income')" title="Restore invoice">
                                    <i class="fas fa-plus"></i>
                                  </button>
                                </td>
                            </tr>
                        </tbody>
                      </table>
                    </li>
                  </ul>

                  <div class="ts-hours">
                    <div class="ts-title">TimeSheet hours</div>
                    <table>
                        <thead>
                          <tr>
                              <th>Position</th>
                              <th>Hours</th>
                              <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item3) in jobHours" :key="item3.position">
                                <td>{{item3.position}}</td>
                                <td>{{parseFloat(item3.tShours).toFixed(1)}}</td>
                                <td>{{noSeparator(item3.tSamount.toFixed(2))}}</td>                            
                            </tr>
                        </tbody>
                      </table>

                  </div>
                </div>
                </collapse-transition>
                <!-- </Transition> -->

                  
              </li>
            </ul>



          </div>

          <div v-if="!newJobClick" class="new-job new-butt">
            <span>Add a job to project </span>
            <button class="d-btn" title="Add a job" @click="newJobClick = !newJobClick"><i class="fas fa-plus"></i></button>
          </div>
          <div v-else class="new-job new-select">                              
                <vue-select  
                  v-model="newJobId" 
                  :options="newJobs" 
                  label-by="jobNoName" 
                  value-by="jobId"
                  placeholder="select Job number" 
                  searchable 
                  clear-on-select 
                  clear-on-close 
                  close-on-select
                  @update:modelValue="addJob()"
                  class="v-select" 
                >  
                  <template #dropdown-item="{ option }">
                    <div :class="this.$store.getters.getCurrYear == option.jobYear ? 'y-active':'y-inactive'">{{ option.jobNoName }}</div>
                  </template>
                </vue-select>

          </div>
        </div>


        <div v-else class="bkm-stat">
          <div class="cost-bar">

            <BarChart 
              :chartData="barData"
              :options="barOptions"
              :height="320"
            />

          </div>

          <div class="pos-tab">
            <h6>TS amount by team</h6>
              <vue-good-table
                :columns="table1columns"
                :rows="table1rows"
                :line-numbers="false"
                :search-options="{
                  enabled: false
                }"
                :sort-options="{enabled: false}"   
                              
              >
              <template v-slot:emptystate>
                  No data
              </template>
              
              </vue-good-table>

          </div>
          <div class="emp-tab-bt">
            <label class="rad-label">
                <input type="checkbox" class="rad-input" name="emp" :value="true" v-model="empTab" :checked="empTab" />
                <div class="rad-design"></div>
                <div class="rad-text">employees</div>
            </label>
          </div>
          <collapse-transition :duration="500">
            <div v-if="empTab" class="emp-tab">
              <vue-good-table
                  :columns="tableEmpColumns"
                  :rows="tableEmpRows"
                  :line-numbers="false"
                  :search-options="{
                    enabled: false
                  }"
                  :sort-options="{enabled: false}"   
                                
                >
                <template v-slot:emptystate>
                    No data
                </template>
                
                </vue-good-table>

            </div>
          </collapse-transition>

          <div class="pc-bar">

            <LineChart 
              :chartData="lineData1"
              :options="lineOptions1"
              :height="320"
            />

          </div>
          <div class="team-bar">

            <LineChart 
              :chartData="lineData2"
              :options="lineOptions2"
              :height="320"
            />

            <div class="inc-rad">
              <label class="rad-label">
                <input type="checkbox" class="rad-input" name="increment" :value="true" v-model="line2inc" :checked="line2inc" />
                <div class="rad-design"></div>
                <div class="rad-text">incremental</div>
              </label>
            </div>
          </div>

          <div class="ex-wrap">
            <vue-excel-xlsx class="d-btn ex-btn"
              :data="tableExrows"
              :columns="tableExcolumns"
              :file-name="filename"
              :file-type="'xlsx'"
              :sheet-name="'HoursByDay'"
              :disabled="tableExrows === null"
              title="export to Excel"
              >
              <i class="fas fa-file-excel"></i>
            </vue-excel-xlsx>
            <span> Hours by day</span>
          </div>
        </div>

    </div>
  </div>
 
  <nav-menu :showMenu="showMenu" ></nav-menu>
</template>

<script>
import shared from "@/shared";
import moment from "moment";
//import settings from "@/services/settings.js";
import jobsService from "@/services/jobs.js";
import projectsService from "@/services/project.js";
import userService from "@/services/user.js";
import reportService from "@/services/reports.js";
import Loader from '../components/Loader.vue';

import { BarChart } from 'vue-chart-3';
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
import Slider from '@vueform/slider';


export default {
  components: {Loader, BarChart, LineChart, Slider },
  name: "App",

  data: function () {
    return {
      usersList: [],
      showMenu: true,
      showNav: true,
      loader: false,
      error: null,
      settDarkMode: false,
      searchBy: 1,
      jobs: [], 
      projects: [],
      jobId: null,
      projectId: null,
      projectInfo: [],
      projectjobList: [],
      projectNameEditable: false,
      jobCostList: [],
      jobCostInvoice: [],
      jobHours: [],
      jobCostListShowActive: true,
      jobCostInvoiceShowActive: true,
      showJob: 0,
      showInvDialog: false,
      InvDialogTitle: '',
      newInvDate: new Date(),
      newInvCost: '',
      newInvNo: '',
      newInvType: null,
      newInvJobId: null,
      newJobs: [],
      newJobId: null,
      newJobClick: false,
      activeBkmSum: true,
      empTab: false,
      agencyId: this.$store.getters.userData.agencyId,
      agencyTeams: [],
      filename: 'filename',
      colorTable: [
        'rgba(33, 191, 117, 0.8)',
        'rgba(33, 167, 191, 0.8)',
        'rgba(33, 101, 191, 0.8)',
        'rgba(93, 53, 181, 0.8)',
        'rgba(153, 53, 181, 0.8)',
        'rgba(181, 53, 115, 0.8)',
        'rgba(99, 25, 25, 0.8)',
        'rgba(140, 98, 20, 0.8)',
        'rgba(168, 166, 20, 0.8)',
        'rgba(240, 237, 70, 0.8)',
        'rgba(138, 15, 95, 0.8)',
        'rgba(89, 6, 60, 0.8)',
        'rgba(247, 67, 103, 0.8)',
        'rgba(168, 8, 40, 0.8)',
        'rgba(28, 28, 145, 0.8)',
        'rgba(204, 132, 31, 0.8)',
        'rgba(247, 116, 22, 0.8)',
        'rgba(102, 76, 58, 0.8)'
      ],
      tableExcolumns: [
        {
          label: 'Date',
          field: 'date',
          tdClass: 'text-f14'
        },
        {
          label: 'Team',
          field: 'team',
          tdClass: 'text-f14'
        },
        {
          label: 'Name',
          field: 'name',
          tdClass: 'text-f14'
        },
        {
          label: 'Job No',
          field: 'jobName',
          tdClass: 'text-f14'
        },
        {
          label: 'Hours',
          field: 'hours',
          type: 'number',
          tdClass: 'text-f14'
        }
      ],
      tableExrows: [],
      barData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ['#d91204', '#105233', '#a11ca3'],
            borderColor: ['#d91204', '#105233', '#a11ca3'],
            borderRadius: 2
          }
        ]
      },
      barOptions: {
        
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            display: false
          },
          title: {
            display: true,
            text: 'Summary costs',
          },
        },
        scales: {
            x: {
              title: {
                display: true,
                text: 'PLN'
              },
              grid: {
                color: '#a4a4a4'
              }
            },
            y: {
              title: {
                display: false,
                text: ''
              },
              grid: {
                color: '#a4a4a4'
              }             
            }
         }
      },
      table1columns: [
        {
          label: 'Team',
          field: 'team',
          tdClass: 'text-f14'
        },
        {
          label: 'Hours',
          field: 'hours',
          type: 'number',
          tdClass: 'text-f14',
          html: true
        },
        {
          label: 'Price Card [pln]',
          field: 'priceCard',
          type: 'number',
          tdClass: 'text-f14',
          html: true
        },
        {
          label: 'Price Card -30% [pln]',
          field: 'priceCard30',
          type: 'number',
          tdClass: 'text-f14',
          html: true

        },
        {
          label: 'Team [%]',
          field: 'prc',
          type: 'number',
          tdClass: 'text-f14',
          html: true

        },
        {
          label: 'Team revenue [pln]',
          field: 'teamReve',
          type: 'number',
          tdClass: 'text-f14',
          html: true

        },
        {
          label: 'Real PC [pln]',
          field: 'realPrice',
          type: 'number',
          tdClass: 'text-f14',
          html: true

        },
        {
          label: 'Profit factor [%]',
          field: 'profitFactor',
          type: 'number',
          tdClass: this.tdClassFn2,
          html: true

        }
      ],
      table1rows: [],
      lineData1: {
        labels: [],
        datasets: [
          {
            label: 'Cost + PC',
            data: [],
            backgroundColor: ['#830d85'],
            borderColor: ['#830d85'],
            tension: 0.4
          },
          {
            label: 'Cost + (PC-30%)',
            data: [],
            backgroundColor: ['#bd6bd6'],
            borderColor: ['#bd6bd6'],
            tension: 0.4
          },
          {
            label: 'Income',
            data: [],
            borderColor: ['#105233'],
            backgroundColor: ['#105233'],
            pointStyle: 'circle',
            radius: 1,
            hoverRadius: 6

          }
        ]
      },
      lineOptions1: {
        
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            display: true
          },
          title: {
            display: true,
            text: 'Costs',
          },
        },
        scales: {
            x: {
              title: {
                display: true,
                text: 'Days'
              },
              grid: {
                color: '#a4a4a4'
              }
            },
            y: {
              title: {
                display: true,
                text: 'PLN'
              },
              grid: {
                color: '#a4a4a4'
              }             
            }
         }
      },
      lineData2: {
        labels: [],
        datasets: [
           
           {
            label: 'Comunication Strategy',
            data: [],
            backgroundColor: 'rgba(33, 191, 117, 0.8)',
            borderColor: 'rgba(33, 191, 117, 0.8)',
            tension: 0.4
          },

          {
            label: 'Content Solutions',
            data: [],
            backgroundColor: 'rgba(33, 191, 117, 0.8)',
            borderColor: 'rgba(33, 191, 117, 0.8)',
            tension: 0.4
          },
          {
            label: 'Integrated Communication (JL)',
            data: [],
            backgroundColor: 'rgba(33, 167, 191, 0.8)',
            borderColor: 'rgba(33, 167, 191, 0.8)',
            tension: 0.4
          },
          {
            label: 'Integrated Communication (MB)',
            data: [],
            backgroundColor: 'rgba(33, 101, 191, 0.8)',
            borderColor: 'rgba(33, 101, 191, 0.8)',
            tension: 0.4
          },
          {
            label: 'Integrated Communication (MP)',
            data: [],
            backgroundColor: 'rgba(93, 53, 181, 0.8)',
            borderColor: 'rgba(93, 53, 181, 0.8)',
            tension: 0.4
          },
          {
            label: 'Integrated Communication (MH)',
            data: [],
            backgroundColor: 'rgba(153, 53, 181, 0.8)',
            borderColor: 'rgba(153, 53, 181, 0.8)',
            tension: 0.4
          },
          {
            label: 'Creative Studio',
            data: [],
            backgroundColor: 'rgba(181, 53, 115, 0.8)',
            borderColor: 'rgba(181, 53, 115, 0.8)',
            tension: 0.4
          },
          {
            label: 'Digital Production',
            data: [],
            backgroundColor: 'rgba(99, 25, 25, 0.8)',
            borderColor: 'rgba(99, 25, 25, 0.8)',
            tension: 0.4
          },
          {
            label: 'Gaming Studio',
            data: [],
            backgroundColor: 'rgba(140, 98, 20, 0.8)',
            borderColor: 'rgba(140, 98, 20, 0.8)',
            tension: 0.4
          }
        ]
      },
      lineOptions2: {
        
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            display: true
          },
          title: {
            display: true,
            text: 'Team hours',
          },
        },
        scales: {
            x: {
              title: {
                display: true,
                text: 'Days'
              },
              grid: {
                color: '#a4a4a4'
              }
            },
            y: {
              title: {
                display: true,
                text: 'Hours'
              },
              grid: {
                color: '#a4a4a4'
              }             
            }
         }
      },
      line2inc: false,
      tableEmpColumns: [
        {
          label: 'Team',
          field: 'team',
          tdClass: 'text-f14'
        },
        {
          label: 'Employee',
          field: 'userName',
          tdClass: 'text-f14'
        },
        {
          label: 'Hours',
          field: 'hours',
          type: 'number',
          tdClass: 'text-f14',
          html: true
        },
        {
          label: 'Price Card [pln]',
          field: 'pc',
          type: 'number',
          tdClass: 'text-f14',
          html: true
        },
        {
          label: 'Price Card -30% [pln]',
          field: 'pc30',
          type: 'number',
          tdClass: 'text-f14',
          html: true

        },
        {
          label: 'Total Price Card [pln]',
          field: 'totalPC',
          type: 'number',
          tdClass: 'text-f14',
          html: true

        },
        {
          label: 'Team revenue [pln]',
          field: 'teamReve',
          type: 'number',
          tdClass: 'text-f14',
          html: true

        }
      ],
      tableEmpRows: [],
      forecast: {
        reve: 0,
        alert: 50,
        level: 0,
        level2: 0,
        send: false
      },
      

    };
  },
  
  created() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    this.getSettingFromArr = shared.getSettingFromArr;
    this.setTheme = shared.setTheme;
    this.noSeparator = shared.noSeparator;
    this.convertToYMD = shared.convertToYMD;
    this.CookieCheckGoToLogin();

    this.uFnCheck = shared.uFnCheck;

    if (!this.uFnCheck(2)) {
      this.CookieRemove();    
    }

    this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
    this.setTheme(this.settDarkMode);


    if (this.settDarkMode) {
      this.barOptions.scales.x.grid.color = '#545454';
      this.barOptions.scales.y.grid.color = '#545454';
      this.lineOptions1.scales.x.grid.color = '#545454';
      this.lineOptions1.scales.y.grid.color = '#545454';
      this.lineOptions2.scales.x.grid.color = '#545454';
      this.lineOptions2.scales.y.grid.color = '#545454';

      this.barData.datasets[0].backgroundColor = [];
      this.barData.datasets[0].backgroundColor = ['#bf6f69', '#105233', '#a11ca3'];
      this.barData.datasets[0].borderColor = [];
      this.barData.datasets[0].borderColor = ['#bf6f69', '#105233', '#a11ca3'];
    }
    else {
      this.barOptions.scales.x.grid.color = '#d1d1d1';
      this.barOptions.scales.y.grid.color = '#d1d1d1';
      this.lineOptions1.scales.x.grid.color = '#d1d1d1';
      this.lineOptions1.scales.y.grid.color = '#d1d1d1';
      this.lineOptions2.scales.x.grid.color = '#d1d1d1';
      this.lineOptions2.scales.y.grid.color = '#d1d1d1';

      this.barData.datasets[0].backgroundColor = [];
      this.barData.datasets[0].backgroundColor = ['#d91204', '#105233', '#a11ca3'];
      this.barData.datasets[0].borderColor = [];
      this.barData.datasets[0].borderColor = ['#d91204', '#105233', '#a11ca3'];
    }

   

    //this.answers(2);  

    jobsService
      .jobs()
      .then((response) => {

        if (response.status === 200) {
          this.jobs = response.data;    
          this.newJobs = response.data;        
        }
        
      })
      .catch((e) => {
        this.error = e.message || "Something went wrong!";        
        
        if (e.response.status === 401) {
            this.CookieRemove();
        }
      })

    projectsService
      .projectList()
      .then((response) => {

        if (response.status === 200) {
          this.projects = response.data;          
        }
        
      })
      .catch((e) => {
        this.error = e.message || "Something went wrong!";        
        
        if (e.response.status === 401) {
            this.CookieRemove();
        }
      })

    userService
      .usersList()
      .then((response) => {

        if (response.status === 200) {
          this.usersList = response.data;
          // console.log("----user list----");
          // console.log(this.usersList);
        }
        
      })
      .catch((e) => {
        this.error = e.message || "Something went wrong!";
        
        
        if (e.response.status === 401) {
            this.CookieRemove();
        }
      })
    
  },

  mounted() {

    document.body.style.overflowY = "scroll";
  },

  watch: {

    searchBy: {
      handler: function () {
        // setTimeout(() => {
        //     this.loadData(); 
        // }, 200);

        this.loadData();

      },
      deep: true

    },

    showInvDialog: {
      handler: function () {

        if (!this.showInvDialog) {

          this.newInvCost = '';
          this.newInvNo = '';
          this.newInvType = null;
          this.newInvJobId = null;
        }

      },
      deep: true

    },

    projectInfo: {
      handler: function () {
      
        this.agencyTeamsList();
        this.barDataRefresh();
        this.tableDataRefresh();
        this.line1DataRefresh();
        this.line2DataRefresh();
        this.tableEmpDataRefresh();
        this.ExDataRefresh();
        this.forecastGet();

      },
      deep: true


    },

    line2inc: {
      handler: function () {
      
       //console.log('--line2inc--');
       //console.log(this.line2inc);

      this.line2DataRefresh();        

      },
      deep: true

    },

          
  },
   
  methods: {  

    setProgressColor(percent) {

      //console.log('##setProgressColor');
      //console.log(percent);
      
      let retVal = '#42b982';

      
      if (percent > 50 && percent < 75 ) {
        retVal = '#ffdb6e';
      } else if (percent >= 75) {
        retVal = '#d91204';
      } else {
        retVal = '#42b982';
      }     

      return retVal;

    },

    forecastGet() {

        const data = {
                ResultName: "forecastIncomeGet",
                ProjectId: this.projectInfo[0].id
          };

          projectsService
          .uniRap(data)
          .then((response) => {

          if (response.status === 200) {
              
                //this.teamId = JSON.parse(response.data)[0].teamId;   
                this.forecast = response.data[0];
                
                //console.log('forecastGet - forecastGet');
                //console.log(response.data[0]);                

          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";        
          
          if (e.response.status === 401) {
                this.CookieRemove();
          }
          })

   
    },

    forecastSet() {

        const data = {
                ResultName: "forecastIncomeSet",
                ProjectId: this.projectInfo[0].id,
                ForecastReve: this.forecast.reve,
                Alert: this.forecast.alert,
                Send: this.forecast.send,
          };

                // console.log('forecastSET - 0 -----');
                // console.log(data);   

          projectsService
          .uniRap(data)
          .then((response) => {

          if (response.status === 200) {
              
               
                //console.log('forecastSET -----');
                //console.log(response.data);   

                this.forecastGet();              
                

          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";      
          
          console.log('ERRORRRRRRR!!!!!!');
          console.log(e); 
          
          // if (e.response.status === 401) {
          //       this.CookieRemove();
          // }
          })



    },

    agencyTeamsList() {

        const data = {
                ResultName: "teamRapList",
                ProjectId: this.projectInfo[0].id
          };

          projectsService
          .uniRap(data)
          .then((response) => {

          if (response.status === 200) {
              
                //this.teamId = JSON.parse(response.data)[0].teamId;   
                this.agencyTeams = response.data;
                
                // console.log('agencyTeams');
                // console.log(this.agencyTeams);                

          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";        
          
          if (e.response.status === 401) {
                this.CookieRemove();
          }
          })

   
    },


    usersListGet() {
      let newArr = [];

       newArr.push({Id: 0, Name: '-- Brak --'});
      this.usersList.forEach(element => {
         newArr.push({Id: element.Id, Name: element.FirstName+' '+element.LastName, Status: element.Status});
      });

      return newArr;
    },

    isNumber(event) {
      if (!/\d/.test(event.key) && event.key !== '.') return event.preventDefault();
    },

    format(date) {
         return ''+moment(date).format("YYYY-MM-DD");    
    },

    editProjectName() {

      this.projectNameEditable = !this.projectNameEditable

      var element = document.getElementById('proName');

      if (this.projectNameEditable) {

        var positionInfo = element.getBoundingClientRect();
        var width = positionInfo.width;
        var width3 = positionInfo.width+9;

        // console.log('--width--');
        // console.log(width);


             setTimeout(() => {
              var element2 = document.getElementById('proNameEdit');
              var element3 = document.querySelector(".pro-wrapper .info-item .vue-select");
              element2.style.width = ""+width+"px";
              element3.style.width = ""+width3+"px";    
              element2.focus();

             }, 1);        

      }
      else {
        console.log('--SAVE--');
        let newName = document.getElementById('proNameEdit').value;
        this.projectInfo[0].projectName = newName;


        const data = {
              projectId: this.projectInfo[0].id,
              projectName: this.projectInfo[0].projectName,
              owner: this.projectInfo[0].ownerId,
              syslog: ""

        }


        projectsService
          .projectEdit(data)
          .then((response) => {

            if (response.status === 200) {
                      console.log('--SAVED--');  

                      this.refreshData();                   
            
            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";        
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          })



      }

     

    },

    loadData() {
      //console.log("-data-");

      let Id = this.projectId;

      if (this.searchBy === 1) {
        Id = this.jobId * (-1);
      }
    

      if (Id !== 0 && Id != null) {

        projectsService
          .projectInfo(Id)
          .then((response) => {

            if (response.status === 200) {
              this.projectInfo = response.data;  
              
              // console.log('--projectInfo--');
              // console.log(this.projectInfo);
              
              this.jobList(this.projectInfo[0].id);

              this.agencyTeamsList();
              this.barDataRefresh();
              this.tableDataRefresh();
              this.line1DataRefresh();
              this.line2DataRefresh();
              this.tableEmpDataRefresh();
              this.ExDataRefresh();
              this.forecastGet();
            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";        
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          })

      }

      this.newJobId = null;
      this.newJobClick = false;
      

    },

    refreshData() {

      projectsService
          .projectList()
          .then((response) => {

            if (response.status === 200) {
              this.projects = response.data;   
              
              projectsService
                .projectInfo(this.projectInfo[0].id)
                .then((response) => {

                  if (response.status === 200) {
                    this.projectInfo = response.data;  
                    
                    // console.log('--projectInfo--');
                    // console.log(this.projectInfo);
                    
                    this.jobList(this.projectInfo[0].id);
                  }
                  
                })
                .catch((e) => {
                  this.error = e.message || "Something went wrong!";        
                  
                  if (e.response.status === 401) {
                      this.CookieRemove();
                  }
                })


            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";        
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          })

    },

    showCostList(jobId) {

        // console.log('--click -jobId --');
        // console.log(jobId);
         this.jobCostList = [];
         this.jobCostInvoice = [];
         this.jobHours =[];
        //  this.jobHours = [{position: null, tSamount: null, tShours: null}];

        let oldShowJob = this.showJob;

        this.showJob = 0;


       projectsService
          .projectjobCost(jobId)
          .then((response) => {

            if (response.status === 200) {          
              // console.log('--projectjobCost--');
              // console.log(response.data);                                   
              this.jobCostList = response.data;
            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";        
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          })

        projectsService
          .projectjobInvoice(jobId)
          .then((response) => {

            if (response.status === 200) {
              // console.log('--projectjobInvoice--');
              // console.log(response.data); 
                this.jobCostInvoice =  response.data;
            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";        
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          }) 


        projectsService
          .projectjobTShours(jobId)
          .then((response) => {

            if (response.status === 200) {
              // console.log('--jobHours--');
              // console.log(response.data); 
                this.jobHours = response.data;
            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";        
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          }) 


      setTimeout(() => {
          if(oldShowJob===jobId) {
            this.showJob = 0;
          }
          else {
            this.showJob = jobId;
          }      
      }, 300);

     
      // let els = document.getElementsByClassName("cost-list");
      // let elsM = document.getElementById("jobId-"+jobId);
      // let elsMdisplay = elsM.style.display;

      // for(var i = 0; i < els.length; i++) {
      //   els[i].style.display = "none";
      // }
     
      // console.log(elsMdisplay);

      // if (elsMdisplay === "flex") {
      //   elsM.style.display = "none";
      // }
      // else {
      //   elsM.style.display = "flex";
      // }

      

    },

    jobList(project) {

      projectsService
          .projectjobList(project)
          .then((response) => {

            if (response.status === 200) {
              this.projectjobList = response.data;          
            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";        
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          })
    },

    newInvoice(jobId, type) {

      // console.log(jobId);
      // console.log(type);
      this.showInvDialog = true;
      this.InvDialogTitle = 'New '+type+' invoice';
      this.newInvType = type;
      this.newInvJobId = jobId;


    },

    newInvSave() {

       console.log('to save');

      // console.log(this.newInvType);
      // console.log( this.newInvJobId);
      // console.log( this.newInvCost);
      // console.log( this.newInvNo);

      
      const data = {
              jobId: this.newInvJobId,
              type: this.newInvType,
              invoiceNo: this.newInvNo,
              date: this.newInvDate,
              cost: this.newInvCost,
              syslog: ''

        }


        projectsService
          .jobInvAdd(data)
          .then((response) => {

            if (response.status === 200) {
                      console.log('--SAVED--');  

                      this.refreshData();
                      this.showInvDialog = false;       
                      this.showJob = 0;
                      this.showCostList(this.newInvJobId);
            
            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";        
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          })


    },

    delInvoice(invId, type) {

       console.log('--del--');

      // console.log(invId);
      // console.log(type);

        const data = {
          invoiceId: invId,
          type: type,
          syslog: ''
        }


        projectsService
          .jobInvDel(data)
          .then((response) => {

            if (response.status === 200) {
                      console.log('--SAVED--');  

                      let jobList = [];
                      if (type ==='cost') {
                        jobList = this.jobCostList;
                      }
                      else {
                        jobList = this.jobCostInvoice;
                      }                     
                      
                      let index = jobList.map(function(e) { return e.id; }).indexOf(invId);
                  
                      // if(jobList[index].source === "ts") {
                      //   jobList.splice(index, 1);
                      // } 
                      // else {
                        jobList[index].active = !jobList[index].active;
                      // }                    
                                        
                      if (type ==='cost') {
                          this.jobCostList = jobList;
                      }
                      else {
                          this.jobCostInvoice = jobList;
                      }

                      this.refreshData();


            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";        
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          })

    },

    addJob() {

      console.log('--add job--');
      // console.log(this.projectId);
      // console.log(this.newJobId);


      const data = {
              jobId: this.newJobId,
              projectId: this.projectInfo[0].id,
              syslog: ''
        }


        projectsService
          .projectJobAdd(data)
          .then((response) => {

            if (response.status === 200) {
                      console.log('--SAVED--');  

                      this.refreshData(); 
                      this.newJobClick = !this.newJobClick;            
            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";        
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          })      
    },

    removeJob(jobId) {

      console.log('--remove--');


        const data = {
          jobId: jobId,
          syslog: ''
        }


        projectsService
          .projectJobRemove(data)
          .then((response) => {

            if (response.status === 200) {
                      console.log('--SAVED--');    
                      this.refreshData();
            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";        
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          })

    },

    barDataRefresh() {

      this.barData.labels = [];
      this.barData.datasets[0].data = [];

      this.barData.labels.push('External cost');
      this.barData.labels.push('Total income');
      //this.barData.labels.push('Total TS amount');

      this.barData.datasets[0].data.push(this.projectInfo[0].totalCost);
      this.barData.datasets[0].data.push(this.projectInfo[0].totalInvoice);
      //this.barData.datasets[0].data.push(this.projectInfo[0].totalTSCost.toFixed(2));
    },

    tableDataRefresh() {

      projectsService
        .projectRap3(this.projectInfo[0].id)
        .then((response) => {

          if (response.status === 200) {
            this.table1rows = response.data;        

          }
          
        })
        .catch((e) => {
          this.error = e.message || "Something went wrong!";        
          
          if (e.response.status === 401) {
              this.CookieRemove();
          }
        })


    },

    tableEmpDataRefresh() {

      projectsService
        .projectRap4(this.projectInfo[0].id)
        .then((response) => {

          if (response.status === 200) {
            this.tableEmpRows = response.data;        

          }
          
        })
        .catch((e) => {
          this.error = e.message || "Something went wrong!";        
          
          if (e.response.status === 401) {
              this.CookieRemove();
          }
        })


    },

    line1DataRefresh() {

      projectsService
        .projectRap1(this.projectInfo[0].id)
        .then((response) => {

          if (response.status === 200) {
            

            this.lineData1.labels = [];
            for (let i = 0; i < 3; i++) {
                this.lineData1.datasets[i].data = [];
            }
            
            response.data.forEach(element => {
              this.lineData1.labels.push(element.date);
              this.lineData1.datasets[0].data.push(element.amountInc);
              this.lineData1.datasets[1].data.push(element.amount30Inc);
              this.lineData1.datasets[2].data.push( this.projectInfo[0].totalInvoice);
            });

            if(this.lineData1.labels[0].substring(0, 2) == 'wk') {
              this.lineOptions1.scales.x.title.text = 'Weeks';
            }
            else {
              this.lineOptions1.scales.x.title.text = 'Days';
            }    


             //console.log('this.lineData1 - po');
             //console.log(this.lineData1);

          }
          
        })
        .catch((e) => {
          this.error = e.message || "Something went wrong!";        
          
          if (e.response.status === 401) {
              this.CookieRemove();
          }
        })


    },

    line2DataRefresh() {

      projectsService
        .projectRap2(this.projectInfo[0].id)
        .then((response) => {

          if (response.status === 200) {
            

            // console.log('this.lineData2 - response data');
            // console.log(response.data);


            this.lineData2.datasets = [];
            this.lineData2.labels = [];
            let tmpCount = 0;
            let tmpName = '';
            

            this.lineData2.labels = response.data.map(item => item["date"]),

            this.agencyTeams.forEach(element => {

              tmpName =  this.line2inc ? element.shortRap+'Inc' : element.shortRap;        

              this.lineData2.datasets.push(
                { label: element.projectGroup,
                  data: response.data.map(item => item[tmpName]),
                  backgroundColor: this.colorTable[tmpCount],
                  borderColor: this.colorTable[tmpCount],
                  tension: 0.3 
                }
                );

              tmpCount++;

            });

            // this.lineData2.labels = [];
            // for (let i = 0; i < 9; i++) {
            //     this.lineData2.datasets[i].data = [];
            // }
            
            // response.data.forEach(element => {

            //   this.lineData2.labels.push(this.convertToYMD(element.date));
              
            //   if (this.line2inc) {
            //     this.lineData2.datasets[0].data.push(element.strategyInc);
            //     this.lineData2.datasets[1].data.push(element.contentInc);
            //     this.lineData2.datasets[2].data.push(element.communication_JLInc);
            //     this.lineData2.datasets[3].data.push(element.communication_MBInc);
            //     this.lineData2.datasets[4].data.push(element.communication_MPInc);
            //     this.lineData2.datasets[5].data.push(element.communication_MHInc);
            //     this.lineData2.datasets[6].data.push(element.creativeInc);
            //     this.lineData2.datasets[7].data.push(element.productionInc);
            //     this.lineData2.datasets[8].data.push(element.gamingInc);

            //   }
            //   else {
            //     this.lineData2.datasets[0].data.push(element.strategy);
            //     this.lineData2.datasets[1].data.push(element.content);
            //     this.lineData2.datasets[2].data.push(element.communication_JL);
            //     this.lineData2.datasets[3].data.push(element.communication_MB);
            //     this.lineData2.datasets[4].data.push(element.communication_MP);
            //     this.lineData2.datasets[5].data.push(element.communication_MH);
            //     this.lineData2.datasets[6].data.push(element.creative);
            //     this.lineData2.datasets[7].data.push(element.production);
            //     this.lineData2.datasets[8].data.push(element.gaming);

            //   }
              
            // });

            if(this.lineData2.labels[0].substring(0, 2) == 'wk') {
              this.lineOptions2.scales.x.title.text = 'Weeks';
            }
            else {
              this.lineOptions2.scales.x.title.text = 'Days';
            }    


             //console.log('this.lineData2 - po');
             //console.log(this.lineData2);

          }
          
        })
        .catch((e) => {
          this.error = e.message || "Something went wrong!";        
          
          if (e.response.status === 401) {
              this.CookieRemove();
          }
        })


    },

    ExDataRefresh() {

      let pId = this.projectInfo[0].id * (-1);
      
      reportService
          .rap6Hours(pId, this.agencyId)
          .then((response) => {

              if (response.status === 200) {
              
              this.tableExrows = response.data;

              for(var i = 0; i <  this.tableExrows.length; i++)
              {
                  //this.data[i].hours = this.data[i].hours.replace('.', ',');
                  this.tableExrows[i].hours = parseFloat(this.tableExrows[i].hours);
              }


              this.filename = 'Project-HoursByDay-'+moment().format("YYMMDD.HHmmss");
              // console.log("----jobInfo----");
              // console.log(this.jobInfo);
            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";
            
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          })


    },




    fnJobCostList() {

      if (this.jobCostListShowActive) {

        return this.jobCostList.filter(function (item) {
              return item.active === true;
            });

      }
      else {
        return this.jobCostList;
      }

      
    },

    fnJobCostInvoice() {

      if (this.jobCostInvoiceShowActive) {

        return this.jobCostInvoice.filter(function (item) {
              return item.active === true;
            });

      }
      else {
        return this.jobCostInvoice;
      }


      
    },


    tdClassFn2(row) {

      if (row.profitFactor < -50) {
        return 'red1-class';
      } else if (row.profitFactor >= -50 && row.profitFactor < -30) {
        return 'red2-class';
      } else if (row.profitFactor >= -30 && row.profitFactor < 0) {
        return 'yellow-class';
      } else {
        return 'green-class';
      }

    }

    


  },
  computed: {
    
  }

}
</script>

<style>

/* .slide-fade-enter-active {
  transition: all 1s ease-out;
}

.slide-fade-leave-active {

  transition: all 1s ease-in;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(100px);
  opacity: 0;
} */


:root {
  --slider-connect-bg: #c25a10;
  --slider-tooltip-bg: #c25a10;
  --slider-handle-ring-color: #c25a1078;  
}


.wrap.wrap-settings {
  flex-direction: column;
  /* padding-right: 62px; */
}

.wrap.wrap-settings::before {
  content: ' ';
      display: block;
      position: absolute;
      left: 51px;
      top: 89px;
      width: 194px;
      height: 268px;
      opacity: 0.1;
      background-image: url('../assets/project1.png');
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;
}

.wrap.wrap-settings::after {
  content: ' ';
      display: block;
      position: absolute;
      right: 71px;
      bottom: 50px;
      width: 300px;
      height: 260px;
      opacity: 0.1;
      background-image: url('../assets/project2.png');
      background-repeat: no-repeat;
      background-size: contain;
      z-index: -1;
}

.pro-wrapper .criteria {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
}

.pro-wrapper .form-container,
.pro-wrapper .form-container-empty {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
  padding: 26px;
  padding-bottom: 40px;
  box-shadow: 0px 3px 16px -5px rgb(120 122 123);
  position: relative;
  background-color: white;
}

.pro-wrapper .form-container-empty {
  text-align: center;
}


.pro-wrapper .form-container .general-info {
  margin: 0 auto;
  margin-bottom: 40px;
  width: max-content;
  /* position: relative; */
}





.rad-label {
  display: flex;
  align-items: center;

  border-radius: 100px;
  padding: 3px 16px;
  margin: 3px 0;

  cursor: pointer;
  transition: .3s;
}

.rad-label:hover,
.rad-label:focus-within {
  background: hsla(0, 0%, 80%, .14);
}

.rad-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.rad-design {
  width: 16px;
  height: 16px;
  /* border-radius: 6px; */

  background: linear-gradient(to right bottom,  #c25a10, #ffdb6e);
  position: relative;
}

.rad-design::before {
  content: '';

  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: .3s;
}

.rad-input:checked+.rad-design::before {
  transform: scale(0);
}

.rad-text {
  color: hsl(0, 0%, 60%);
  margin-left: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 900;
  transition: .3s;
}

.pro-wrapper .rad-text {
  font-size: 11px;
}

.rad-input:checked~.rad-text {
  color: hsl(0, 0%, 40%);
}



.pro-wrapper .vue-select {
  border-radius:initial;
  border-radius: 4px;
  z-index: 2;
  border: 1px solid #e3e3e3;
  background-color: white;
  width: 560px;
}

.pro-wrapper .vue-input {
  padding: 10px 6px 10px 6px;
}
.pro-wrapper .vue-input input {
  background-color: transparent;
  font-size: 16px;
}

.pro-wrapper .vue-dropdown {
  border: 1px solid #e3e3e3;
}

.pro-wrapper .vue-input .icon.arrow-downward  {
  border-width: 8px 5px 0;
}


.pro-wrapper .vue-dropdown-item.highlighted {
    background-color: #fff8e3;
}


.pro-wrapper .vue-dropdown-item.selected.highlighted {
    background-color: #fceec2;
}



.pro-wrapper .vue-dropdown-item .st-Inactive {
    color: #d9d9d9 !important;
}

/* .dialog-box.edit .vue-select {
  width: 100%;

} */

.pro-wrapper .v-select ul li {
   text-align: left;
}

.pro-wrapper .v-select ul li span {
    padding: 0;
}

.pro-wrapper .control-wrap {
    display: flex;
    justify-content: flex-end;
    flex-grow: initial;
    align-items: center;
    padding-left: 6px;
}



.general-info .info-item label {
    overflow-wrap: break-word;
    text-align: left;
    font-size: 13px;
    font-weight: 800;
    color: #c25a10;
    display: inline-block;
    width: 124px;
    /* vertical-align: top; */
}

.general-info .info-item .item-slider {
    display: inline-block; 
    width: 215px;
}

.general-info .info-item .item-slider .slider-connects {
    background-color: rgb(235, 238, 245);
}



.general-info .info-item #forecast {
    width: 205px;
}

.general-info .info-item .item-progress {
    width: 228px;
    display: inline-block;
    position: relative;
}


.general-info .info-item .item-progress .item-progress-lvl {
    font-size: 12px;
    position: absolute;
    right: 12px;
    top: -16px;
    width: 60px;
    text-align: right;

}

.general-info .info-item .item-progress .k-progress-outer {
    padding-right: 5px;
}




.general-info .info-item label.rad-label {
    display: inline-flex;
    align-items: center;
    border-radius: 100px;
    padding: 0px;
    margin: 0px;
    cursor: pointer;
    transition: .3s;
    /* vertical-align: top; */
}

.general-info .info-item.cost-info label,
.general-li .info-item.cost-info label {
    color: #42b982 !important;
}

.general-info .info-item.ex-cost label,
.general-li .info-item.ex-cost label {
    color: #d91204 !important;
}

.general-info .info-item.cost-ts-info label,
.general-li .info-item.cost-ts-info label {
    color: #a11ca3 !important;
}


.general-info .info-item.b-margin {
  margin-bottom: 12px;
}

.general-info .info-item.t-margin {
   margin-top: -4px;
}
 

.general-info .info-item span {
    font-size: 13px;
    
    display: inline-block;
    /* min-width: 350px; */
    max-width: 590px;
    /* vertical-align: top; */
    margin-right: 12px;
}

.general-info .info-item>input {
    font-size: 13px;
    border-radius: unset;
    border: 1px solid #e3e3e3;
    color: #667;
    padding: 3px 4px;
}

.general-info .info-item .d-btn,
.cost-list-ul .d-btn  {
    width: 36px;
    margin-left: 16px;
    margin-bottom: 0px;
    padding: 2px;
}

.general-info .info-item .info-item-lnk {
    font-size: 13px;
    font-weight: 800;
}



.general-info .info-frcast {
    position: absolute;
    top: 78px;
    right: 26px;
    border: 1px solid #e0e0e0;
    padding: 12px 2px 12px 12px;
    width: 360px;
}


.general-li .info-item  {
    position: relative;
}

.general-li .info-item .d-btn.d-abs  {
    position: absolute;
    top: 1px;
    margin: 0 auto;
    left: 224px;
    width: 26px;
    margin: 2px 4px;
    padding: 0;
    font-size: 11px;
}

.cost-list-ul .d-btn.d-inv  {
    width: 26px;
    margin: 2px 4px;
    padding: 0;
    font-size: 11px;
}



.general-info .info-item>input:focus,
.general-info .info-item>input:active,
.general-info .info-item>input:hover,
.general-info .info-item>input:focus-visible {
    outline: none;
     border: 1px solid #e3e3e3 !important;
}





.general-info .info-item .btn-link {
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-weight: bold;
  color: #c25a10;
  margin-left: 16px;
  vertical-align: top;
}


.general-info .info-frcast .info-item {
    height: 30px;
    
}



.pro-wrapper .job-list-ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding:0;
  padding-bottom: 16px;
  width: 100%;
  margin: 0 auto;
  list-style: none;
}

.pro-wrapper .job-list-ul>li {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 5px;
  margin-bottom: 12px;
  position: relative;
}

.pro-wrapper .job-list-ul li .general-li {
  display: flex;
  justify-content: space-evenly;
}


.pro-wrapper .job-list-ul li .job-details-btn {
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 50px;
  height: 20px;
  font-size: 22px;
  color: #998;

    border: none;
    background: transparent;
    cursor: pointer;
    padding: 3px
}


.pro-wrapper .job-list-ul li .job-details {
 width: 100%;
}




.pro-wrapper .job-list-ul .general-left,
.pro-wrapper .job-list-ul .general-right {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.pro-wrapper .job-list-ul .general-left {
  margin-right: 16px;
}

.pro-wrapper .job-list-ul .general-right {
  margin-left: 16px;
}

.pro-wrapper .job-list-ul .info-item label {
    overflow-wrap: break-word;
    text-align: left;
    font-size: 13px;
    font-weight: 800;
    color: #c25a10;
    display: inline-block;
    width: 166px;
}

.pro-wrapper .job-list-ul .general-left .info-item label {
  width: 130px;
}

.pro-wrapper .job-list-ul .info-item span {
    font-size: 13px;
    
    display: inline-block;
    min-width: 350px;
    max-width: 450px;
}

.pro-wrapper .job-list-ul .general-right .info-item span {
  min-width: 200px;
}


.pro-wrapper .job-list-ul li .cost-list {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-color: #fafafa;
  /* display: none; */
  margin-top: 22px;
  margin-bottom: 16px;
  padding-top: 12px;
  position: relative;
}

.pro-wrapper .job-list-ul li .cost-list .cost-title {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 8px;
  width: 26%;
  text-align: center;
  font-size: 12px;
  color: #b9b9b9;
}

.pro-wrapper .job-list-ul li .cost-list-ul {
    margin: 0 auto;
    list-style: none;
    padding: 0;
    min-width: 350px;
}

.pro-wrapper .job-list-ul li .cost-list-ul li.title-li {
    color: #42b982;
    font-weight: bold;
    text-align: center;
    position: relative;
}

.pro-wrapper .job-list-ul li .cost-list-ul li.ex-title {
    color: #d91204;
}

.pro-wrapper .job-list-ul li .cost-list-ul li.title-li .d-lnk {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 10px;
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: #42b982;
    cursor: pointer;
    padding: 0;
}

.pro-wrapper .job-list-ul li .cost-list-ul li.title-li .d-lnk.right {
    left: auto;
    left: unset;
    right: 0;

}


.pro-wrapper .job-list-ul li .cost-list-ul .src-ts {
    color: #a11ca3;
}

.pro-wrapper .job-list-ul li .cost-list-ul .src-sfm {
    color: #42b982;
}

.pro-wrapper .job-list-ul li .cost-list-ul .view-del td {
    color: #b9b9b9 !important;
    text-decoration: line-through;

}

.pro-wrapper .cost-list table {
    font-size: 12px;
    border-top: 1px solid #e0e0e0;
    margin: 0 auto;
    margin-top: 5px;
}

.pro-wrapper .cost-list table th,
.pro-wrapper .cost-list table td {
    padding: 4px 6px;
}

.pro-wrapper .cost-list table th {
    text-align: center;
    color: #c25a10;
}


.pro-wrapper .cost-list .ts-hours {
    margin-top: 24px;
    margin-bottom: 16px;
    min-width: 44%;
}

.pro-wrapper .cost-list .ts-hours .ts-title {
    text-align: center;
    color: #a11ca3;
    font-weight: bold;
    margin-bottom: 12px;
}

.pro-wrapper .cost-list .ts-hours table {
    width: 100%;
}


.pro-wrapper .btn-wrap {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100px;
    bottom: -15px;
    text-align: center;
}

.pro-wrapper .btn-wrap button {
    border: none;
    background-color: white;
    cursor: pointer;
    font-size: 18px;
    color: #998;
}


.inv-wrapper {
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inv-wrapper .inv-form-item {
  margin: 4px 12px;
}

.inv-wrapper .inv-form-item label {
  display: block;
  color: #42b982;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 2px;
}

.inv-wrapper .inv-form-item.err-item label {
  color: #d91204;
}

.inv-wrapper.ex-cost .inv-form-item label {
  color: #d91204;
}

.inv-wrapper .inv-form-item input {
  border: 1px solid #e3e3e3;
  outline: 0;
  padding: 8px 6px 10px 6px;
  width: 360px;
  font-size: 13px;
  line-height: 16px;
  border-radius: inherit;
  box-sizing: border-box;
}

.inv-wrapper .inv-form-item .dp__input_icon {
  left: auto;
  left: unset;
  right: 10px;
}

.new-job {
  text-align: center;
}

.new-job.new-butt span {
  color: #c25a10;
  font-weight: bold;
}

.pro-wrapper .new-job.new-select .vue-select {
      margin: 0 auto;
}


.pro-wrapper .bkm {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 32px;
  padding: 0 16px;
  position: relative;

}

.pro-wrapper .bkm .d-bkm {
  position: absolute;
  bottom: -1px;
  left: 16px;
  margin: 0;
  width: 120px;
  font-size: 16px;
  z-index: 5;
}

.pro-wrapper .bkm .d-bkm.active {
  background-color: #faecc3;
  z-index: 6;
}

.pro-wrapper .bkm #d-bkm-2 {
  left: 126px;
}

.pro-wrapper .bkm-stat .cost-bar {
    width: 60%;
    margin: 0 auto;
}

.pro-wrapper .bkm-stat .text-f14 {
  font-size: 14px;
}

.pro-wrapper .bkm-stat .vgt-table thead th {
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
  font-size: 13px;
}


.pro-wrapper .bkm-stat .pos-tab,
.pro-wrapper .bkm-stat .pc-bar,
.pro-wrapper .bkm-stat .team-bar
 {
   margin: 48px 0;
}

.pro-wrapper .bkm-stat .pos-tab h6 {
   font-size: 13px;
   text-align: center;
   margin: 14px;
}


.pos-tab table.vgt-table td.red1-class {
  background-color: #ffeded;
}

.pos-tab table.vgt-table td.red2-class {
  background-color: #fff5ed;
}

.pos-tab table.vgt-table td.yellow-class {
  background-color: #ffffed;
}

.pos-tab table.vgt-table td.green-class {
  background-color: #efffed;
}

.pro-wrapper .bkm-stat .team-bar {
  position: relative;
  
}

.pro-wrapper .emp-tab-bt {
  margin-top: -40px;  
  width: 136px;
  margin-bottom: 9px;
}



.pro-wrapper .bkm-stat .team-bar .inc-rad {
  position: absolute;
  right: -10px;
  top: 0;

}

.pro-wrapper .info-item .vue-select {
    width: 200px;
    display: inline-block;
    border-radius: initial;
    border-radius: unset;
}

.pro-wrapper .info-item.te-margin .vue-select {
    margin-top: 4px;
}






.pro-wrapper .info-item .vue-input {
      padding: 2px 6px;
}

.pro-wrapper .info-item .vue-input input {
      font-size: 13px;
}


.pro-wrapper .ex-wrap {
  text-align: left;
  margin-top: -44px;
  margin-left: 12px;
}

.pro-wrapper .ex-wrap span {
    color: #999;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 900;
}



/* ----dark mode---- */

.dark-mode .pro-wrapper input,
.dark-mode .inv-wrapper input {
    border: 1px solid #2d2d2d!important;
    background-color: #212121;
    color: #d1d1d1;
}

.dark-mode .pro-wrapper .vue-input input,
.dark-mode .inv-wrapper .vue-input input {
    border: none !important;

}

.dark-mode .pro-wrapper input:focus,
.dark-mode .inv-wrapper input:focus,
.dark-mode .pro-wrapper input:hover,
.dark-mode .inv-wrapper input:hover {
    border: 1px solid #3e6487!important;
}

.dark-mode .pro-wrapper .vue-input input:focus,
.dark-mode .inv-wrapper .vue-input input:focus,
.dark-mode .pro-wrapper .vue-input input:hover,
.dark-mode .inv-wrapper .vue-input input:hover {
    border: none !important;
}


.dark-mode .general-info .info-item input:focus, 
.dark-mode .general-info .info-item input:active, 
.dark-mode .general-info .info-item input:hover, 
.dark-mode .general-info .info-item input:focus-visible {
    outline: none;
    border: 1px solid #3e6487 !important;
}

.dark-mode .general-info .info-item .vue-input input:focus, 
.dark-mode .general-info .info-item .vue-input  input:active, 
.dark-mode .general-info .info-item .vue-input  input:hover, 
.dark-mode .general-info .info-item .vue-input  input:focus-visible {
    outline: none;
    border: none !important;
}



.dark-mode .pro-wrapper .form-container, 
.dark-mode .pro-wrapper .form-container-empty {
    box-shadow: 0px 3px 16px -5px rgb(24 24 24);
    background-color: #1c2630;
}

.dark-mode .pro-wrapper .vue-select {
    background-color: #212121 !important;
    border: 1px solid #212121 !important;
}

.dark-mode .pro-wrapper .vue-dropdown-item.highlighted, 
.dark-mode .pro-wrapper .vue-dropdown-item.selected, 
.dark-mode .pro-wrapper .vue-dropdown-item.selected.highlighted {
    background-color: #1c2630;
}

.dark-mode .pro-wrapper .vue-dropdown-item .st-Inactive {
    color: #808080 !important;
}

.dark-mode .pro-wrapper .bkm .d-bkm.active {
  background-color: #1c2630;
}


.dark-mode .pro-wrapper .bkm,
.dark-mode .pro-wrapper .job-list-ul>li  {
  border-bottom: 1px solid #5c5c5c;
}


.dark-mode .pro-wrapper .btn-wrap button {
    background-color: #1c2630;    
}

.dark-mode .pro-wrapper .job-list-ul li .cost-list {
    background-color: #2c3e50;
}



.dark-mode .pos-tab table.vgt-table td.red1-class {
  background-color: #5c3232;
}
.dark-mode .pos-tab table.vgt-table td.red2-class {
  background-color: #663d1c;
}
.dark-mode .pos-tab table.vgt-table td.green-class {
  background-color: #354d32;
}
.dark-mode .pos-tab table.vgt-table td.yellow-class {
  background-color: #575708;
}



.dark-mode .general-info .info-item.ex-cost label,
.dark-mode .general-li .info-item.ex-cost label {
    color: #bf6f69 !important;
}

.dark-mode .pro-wrapper .job-list-ul li .cost-list-ul li.ex-title {
    color: #bf6f69;
}

.dark-mode .inv-wrapper.ex-cost .inv-form-item label {
  color: #bf6f69;
}


.dark-mode .inv-wrapper .inv-form-item.err-item label {
  color: #bf6f69;
}


.dark-mode .general-info .info-frcast {
    border: 1px solid #5c5c5c;
}


.dark-mode .general-info .info-item .item-slider .slider-connects {
    background-color: rgb(92, 92, 92);
}

.dark-mode .general-info .info-item .item-slider .slider-handle {
  background-color: rgb(92, 92, 92);
}

</style>