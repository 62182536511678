<template>
     
  
    <nav-header :showNav="showNav" :clearButtons="true">
      <template v-slot:navButtons>
          <button class="nav-btn nav-cmp" @click="hideLeftPanel = !hideLeftPanel">
            <i :class="['fas', hideLeftPanel ? 'fa-expand-alt':'fa-compress-alt']"></i>
          </button>
         
      </template>
      
      <template v-slot:navView>
          <h3>Reports</h3>
      </template>      
     
      <template v-slot:navLog>
        <!-- <button class="nav-btn" @click="showMenu = !showMenu"><i :class="[showMenu ? 'far':'fas', 'fa-user']"></i></button> -->
        <button class="nav-btn" @click="showMenu = !showMenu"><i :class="[showMenu ? 'fa-ellipsis-v':'fa-ellipsis-h', 'fas']"></i></button>
      </template>
  </nav-header>

  <div class="wrap wrap-reports">

    <div class="cnt left-container" :class="hideLeftPanel ? 'hide-leftPanel':'show-leftPanel'">
      <RapMenu :showMenu="true" />
      
    </div>
    <div class="cnt right-container">
      <router-view />
    </div>
     
  </div>


  <nav-menu :showMenu="showMenu" ></nav-menu>
</template>

<script>
import shared from "@/shared";
import RapMenu from '../components/RapMenu.vue';


export default {
 components: { RapMenu },
 name: "App",

 data: function () {
    return {
      loader: false,
      error: null,
      showMenu: false,
      showNav: true,
      hideLeftPanel: false
      

    };
  },

  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieCheckGoToLogin();
    this.uFnCheck = shared.uFnCheck;
    this.CookieRemove = shared.CookieRemove;
    //this.$store.commit('setAxiosState', 1);

    if (this.uFnCheck(6)) {
      this.$router.push({ name: "Dashboard" });
    }
    else {
      this.CookieRemove();    
    }
  
  },

  methods: {

  
  },

}
</script>

<style scoped>


.hide-leftPanel {
  -webkit-animation: slide-out-left2 0.6s ease-out both;
	animation: slide-out-left2 0.6s ease-out both;
}

.show-leftPanel {
  -webkit-animation: slide-in-left2 0.6s ease-out both;
	animation: slide-in-left2 0.6s ease-out both;
}

@-webkit-keyframes slide-out-left2 {
  0% {
    margin-left: 0px; 
    opacity: 1;
  }
  100% {
    margin-left: -216px; 
    opacity: 0.5;
  }
}
@keyframes slide-out-left2 {
  0% {
    margin-left: 0px;
    opacity: 1;
  }
  100% {
    margin-left: -216px; 
    opacity: 0.5;
  }
}

@-webkit-keyframes slide-in-left2 {
  0% {
    margin-left: -216px; 
    opacity: 0.5;
  }
  100% {
    margin-left: 0px; 
    opacity: 1;
  }
}
@keyframes slide-in-left2 {
  0% {
    margin-left: -216px; 
    opacity: 0.5;
  }
  100% {
    margin-left: 0px; 
    opacity: 1;
  }
}



.wrap.wrap-reports {
  flex-direction: row;
  /* padding-right: 62px; */
}


.wrap.wrap-reports::after {
    content: ' ';
    display: block;
    position: absolute;
    right: 70px;
    bottom: 56px;
    width: 211px;
    height: 207px;
    opacity: 0.1;
    background-image: url('../assets/report.svg');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
}

/* .cnt {
  border: 1px solid red;
} */

/* .left-container {
  width: 200px;
  background-color: white;
  box-shadow: 0px 3px 16px -5px rgb(120 122 123);
  z-index: 2;
  min-height: 400px;
} */

.right-container {
  width: 100%;
}


</style>