<template>
      <Loader :loader="loader" />

      <base-dialog :show="error != null" :showCloseIcon="false" title="Something Fishy" @close="error == null">
            <template v-slot:content>                  
                  <div class="inv-wrapper nwd-wrap">
                        <div class="inv-form-item">
                              <label>{{error}}</label>    
                        </div>
                  </div>
            </template>
             <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="error=null" title="Close"><i class="fas fa-times"></i></base-button>                  
            </template>
      </base-dialog>

      <base-dialog :show="showDialog" :title="dialogTitle" @close="closeDialog">
            <template v-slot:content>
                  
                  <div class="inv-wrapper nwd-wrap u-wrap">
                        <div class="u-form-item">
                              <label>First Name</label>
                              <div class="f-item">
                                    <input type="text" name="firstName" id="firstName" v-model="userData.FirstName">
                              </div>                                      
                        </div>
                        <div class="u-form-item">
                              <label>Last Name</label>
                              <div class="f-item">
                                    <input type="text" name="LastName" id="LastName" v-model="userData.LastName">       
                              </div>                               
                        </div>
                       <div class="u-form-item">
                              <label>Email</label>
                              <div class="f-item">
                                    <input type="text" name="Email" id="Email" v-model="userData.Email">  
                              </div>                                    
                        </div>
                        <div class="u-form-item">
                              <label>User level</label>
                              <div class="f-item">
                                    <vue-select 
                                          v-model="userData.RoleId" 
                                          :options="userRoles" 
                                          label-by="Description" 
                                          value-by="Id" 
                                          openDirection="bottom"
                                          close-on-select />  
                              </div>                                      
                        </div> 
                         
                        <div class="u-form-item">
                              <label>Job title</label>
                              <div class="f-item">
                                    <vue-select 
                                          v-model="userData.PositionId" 
                                          :options="userPositions" 
                                          label-by="Description" 
                                          value-by="Id" 
                                          openDirection="bottom"
                                          searchable 
                                          clear-on-close 
                                          close-on-select  />  
                              </div>                                      
                        </div> 
                           
                        <div class="u-form-item">
                              <label>Team</label>
                              <div class="f-item">
                                    <vue-select 
                                          v-model="userData.TeamId" 
                                          :options="userTeams" 
                                          label-by="ProjectGroup" 
                                          value-by="Id" 
                                          openDirection="bottom"
                                          searchable 
                                          clear-on-close 
                                          close-on-select  /> 
                              </div>                                       
                        </div>  
                            
                        <div class="u-form-item">
                              <label>Manager</label>
                              <div class="f-item">
                                    <vue-select 
                                          v-model="userData.ManagerId" 
                                          :options="userManagers" 
                                          label-by="Name"
                                          value-by="Id" 
                                          openDirection="bottom"
                                          searchable 
                                          clear-on-close 
                                          close-on-select  />   

                                          

                              </div>                                     
                        </div>    
                        <div class="u-form-item">
                              <label>Status</label>
                              <div class="f-item">
                                    <vue-select 
                                          v-model="userData.StatusId" 
                                          :options="userStatus" 
                                          label-by="Status" 
                                          value-by="Id" 
                                          openDirection="bottom"
                                          close-on-select />          
                              </div>                              
                        </div>
                        <div class="u-form-item">
                              <label>User roles</label>
                              <div class="f-item">
                                    <base-button class="d-btn d-btn-s" @click="onBtnFClick(selectedUserId)" title="Show user roles"><i class="fas fa-arrow-alt-circle-right"></i></base-button>
                              </div>                                    
                        </div>                  
                                          
                  

                  </div> 
                 
            
            </template>

            <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="closeDialog" title="Cancel"><i class="fas fa-times"></i></base-button>
                  <base-button class="d-btn d-edit" @click="onSaveClick()" title="Save"><i class="fas fa-save"></i></base-button>
            </template>
      </base-dialog>

      <base-dialog :show="showDialogF" :title="dialogTitleF" @close="closeDialogF">
            <template v-slot:content>
                  
                  <div class="inv-wrapper nwd-wrap u-wrap">
             
                   <ul class="function-list">
                        <li v-for="item  in userFunction" :key="item.Id">

                        
                        <label class="rad-label">
                              <input type="checkbox" :disabled="item.Global && !uFnCheck(11)" class="rad-input" :id="'ch'+item.Id" v-model="item.Active">
                              <div class="rad-design"></div>
                              <!-- <div class="rad-text"></div> -->
                        </label>  
                        <div :class="item.Global && !(uFnCheck(11)) ? 'function-list-disabled':''">{{ item.FunctionName }}</div>
                        

                        </li>
                   </ul>                    
                  

                  </div>
                  
            
            </template>

            <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="closeDialogF" title="Cancel"><i class="fas fa-times"></i></base-button>
                  <base-button class="d-btn d-edit" @click="onSaveFClick()" title="Save"><i class="fas fa-save"></i></base-button>
            </template>
      </base-dialog>




      <div class="inner-wrap3 nwd-wrap u-wrap">                  
            <h3>Users</h3>

            <div class="criteria">
                  <div v-if="uFnCheck(11)" class="control-wrap">
                        <span>Agency: &nbsp;</span>
                        <vue-select 
                        v-model="agencyId" 
                        :options="agencies" 
                        label-by="Description" 
                        value-by="Id" 
                        :blur="onChange"
                        close-on-select />  
                             
                  </div>  
                  <div class="control-wrap">
                        <span>Role: &nbsp;</span>
                        <vue-select 
                        v-model="role" 
                        :options="roles" 
                        label-by="Description" 
                        value-by="Id" 
                        :blur="onChange"
                        close-on-select />  
                        <span>New user: &nbsp;</span>
                        <button title="New user" class="d-btn new-d" name="newUser" @click="newUserClick()"><i class="fas fa-user-plus"></i></button>      
                  </div>  
            </div>

            <div class="center-pan">
                  <vue-good-table
                        :columns="table1columns"
                        :rows="table1rows"
                        :line-numbers="false"
                        :row-style-class="rowStyleClass"
                        :search-options="{enabled: true}"
                        :sort-options="{enabled: true}"  
                        :pagination-options="{
                              mode: 'records'
                        }" 
                                    
                  >
                        <template v-slot:emptystate>
                        No data
                        </template>

                        <template v-slot:table-row="props">
                              <div v-if="props.column.field == 'Action'">
                                          <button title="Edit" class="d-btn" :name="props.row.Date" @click="onBtnClick(props.row.Id)"><i class="fas fa-edit"></i></button>
                                          <button title="User roles" class="d-btn" :name="props.row.Date" @click="onBtnFClick(props.row.Id)"><i class="fas fa-user-shield"></i></button>                                           
                              </div>
                              <span v-else>
                                    {{props.formattedRow[props.column.field]}}
                              </span>
                        </template>
                        
                  </vue-good-table>
            </div>      

      </div>
  
</template>

<script>
import shared from "@/shared";
import Loader from '../../components/Loader.vue';
import adminService from "@/services/admin.js";

export default {

      components: {
            Loader,           
      },
     
      data: function () {

            return {
                  role: 0,
                  showNav: true,
                  error: null,
                  loader: false,
                  showDialog: false,
                  showDialogNewUser: false,
                  dialogTitle: '',
                  showDialogF: false,
                  dialogTitleF: '',
                  settDarkMode: false, 
                  selectedUserId: null,
                  agencyId: this.$store.getters.userData.agencyId,
                  agencies: [],
                  roles: [],
                  userRoles: [],
                  userFunction: [],
                  userPositions: [],
                  userTeams: [],
                  userManagers: [],
                  userStatus: [
                        {
                              Id: 1,
                              Status: 'Active'              
                        },
                         {
                              Id: 2,
                              Status: 'Inactive'              
                        },
                  ],
                  table1columns: [
                  {
                  label: 'Id',
                  field: 'Id',
                  type: 'number',
                  tdClass: 'text-f14'                 
                  },
                  {
                  label: 'First Name',
                  field: 'FirstName',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Last Name',
                  field: 'LastName',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Email',
                  field: 'Email',
                  tdClass: 'text-f12'
                  },
                  {
                  label: 'Job title',
                  field: 'Position',
                  tdClass: 'text-f12'
                  },
                  {
                  label: 'Level',
                  field: 'Role',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Team',
                  field: 'Team',
                  tdClass: 'text-f12'
                  },
                  {
                  label: 'Status',
                  field: 'Status',
                  tdClass: 'text-f14'
                  },
                  {
                  label: '',
                  field: 'Action',
                  tdClass: 'text-center',
                  sortable: false,
                  width: '98px',
                  }
                  ],
                  table1rows: [],
                  userData: {
                        Id: 0,
                        FirstName: '',
                        LastName: '',
                        Email: '',
                        RoleId: 1,
                        Role: '',
                        PositionId: 1,
                        Position: '',
                        TeamId: 1,
                        Team: '',
                        ManagerId: null,
                        DirectorId: 0,
                        StatusId: 1,
                        Status: ''
                  },
                  
                  
            };
      },


      created(){
            this.uFnCheck = shared.uFnCheck;
            this.CookieRemove = shared.CookieRemove;
      },

      mounted() {
            this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
            this.CookieCheckGoToLogin();

            this.getSettingFromArr = shared.getSettingFromArr;
            this.setTheme = shared.setTheme;

                      
            // console.log('userSettings');
            // console.log(this.$store.getters.userSettings);

            this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
            this.setTheme(this.settDarkMode);


            const data = {ResultName: "AgencyList"};
            adminService
                  .uniRap(data)
                  .then((response) => {

                  if (response.status === 200) {                  
            
                        this.agencies = response.data;    

                         setTimeout(() => {                 
                              this.agencyId = parseInt(this.$store.getters.userData.agencyId);
                         },380);
                  }
                  
                  })
                  .catch((e) => shared.handleError.call(this, '151', true, e));

            
            this.roleList();                       
            this.onChange();

            setTimeout(() => {
                  this.role = -1;
            },380);


      },

      methods: {

            rowStyleClass(row) {
                  return  row.Status == 'Inactive' ? 'cl-row-inactive' : 'cl-row';
            },

            closeDialog() {
                  this.showDialog = false;
                  this.showDialogNewUser = false;
                  this.dialogTitle = '';
            },

            closeDialogF() {
                  this.showDialogF = false;
                  this.dialogTitleF = '';
            },


            roleList() {
                  this.loader = true;

                  adminService
                  .roleList()
                  .then((response) => {

                  if (response.status === 200) {         
                        
                        // console.log("----response.data----");
                        // console.log(response.data);

                        this.roles = response.data;
                        this.roles.push({Id: -1, Description: '-All-'});

                        this.userRoles = this.roles.slice(0, -1);

                                               
                        // console.log("----this.roles----");
                        // console.log(this.roles);

                        // console.log("----this.userRoles----");
                        // console.log(this.userRoles);
                                          
                  }
                  
                  })
                  .catch((e) => shared.handleError.call(this, '152', false, e))
                  .finally(() => {
                  this.loader = false;                  
                   
                  });
            },

            positionList() {

                  adminService
                  .positionList(this.agencyId)
                  .then((response) => {

                  if (response.status === 200) {          

                        this.userPositions = response.data;                       
                  }
                  
                  })
                  .catch((e) => shared.handleError.call(this, '153', false, e))
                  .finally(() => {
                        this.loader = false;
                  
                   
                  });

            },

            teamList() {

                  adminService
                  .teamAgencyList(this.agencyId)
                  .then((response) => {

                  if (response.status === 200) {          

                        this.userTeams = response.data; 
                        
                        //  console.log("---- this.userTeams----");
                        //  console.log(this.userTeams);
                        
                  }
            
                  
                  })
                  .catch((e) => shared.handleError.call(this, '154', false, e))
                  .finally(() => {
                        this.loader = false;            
                   
                  });

            },


            onChange() {
                  this.loader = true;
                  
                  console.log('data change');

                  this.teamList();
                  this.positionList();

                  // console.log("++++ this.userManagers+++");
                  // console.log(this.userManagers);

                  
                  adminService
                  .usersAgencyList(this.agencyId)
                  .then((response) => {

                  if (response.status === 200) {          
                       
                        if(this.role > 0) {
                             
                              let r = this.role;
                              this.table1rows = response.data.filter(function(el) { return (el.RoleId == r ); });
                        }  
                        else {
                              this.table1rows = response.data;                              

                              //  console.log("++++ this.userManagers+++");
                              //  console.log(this.userManagers);
                        } 

                        this.userManagers = response.data.filter(function(el) { return (el.Role != 'Employee' ); });
                        this.userManagers.forEach((element, index) => {
                        this.userManagers[index].Name = element.FirstName+' '+element.LastName;
                        });
                         

                        //  console.log("++++ this.userManagers2+++");
                        // console.log(this.userManagers);
                                          
                  }
                  
                  })
                  .catch((e) => shared.handleError.call(this, '155', false, e))
                  .finally(() => {
                        this.loader = false;
                  
                   
                  });


                  

            },

            onBtnClick(UserId) {

                  
                  this.selectedUserId = UserId;

                        //  console.log("userData");
                        //  console.log(this.userData);
                        //  console.log(this.selectedUserId);

                  

                  // console.log("userManagers2+++");
                  // console.log(this.userManagers);


                  adminService
                  .userGet(this.selectedUserId)
                  .then((response) => {

                  if (response.status === 200) {          

                        this.userData = response.data;                       


                        // ---- show Edit User Dialog -----
                        
                        setTimeout(() => {
                              this.showDialog = true;
                              this.showDialogNewUser = false;
                              this.dialogTitle = 'Edit user Id: '+this.selectedUserId;
                        },200);

                  }
                  
                  })
                  .catch((e) => shared.handleError.call(this, '156', false, e))
                  .finally(() => {
                  this.loader = false;                  
                   
                  });


                             


            },


            onBtnFClick(UserId) { 

                        this.selectedUserId = UserId;

                              // console.log(dateVal);
                              // console.log(type);


                        const data = {
                              "ResultName": "AdmUserFunction",
                              "UserId": this.selectedUserId
                        };

                        this.loader = true;
                        adminService
                              .uniRap(data)
                              .then((response) => {

                              if (response.status === 200) {
                              
                        
                              this.userFunction = response.data;    
                              
                              //   console.log("----this.userFunction----");
                              //   console.log(this.userFunction); 


                              this.showDialog = false;
                              this.showDialogF = true;
                              this.dialogTitleF = 'Edit User roles - user Id: '+this.selectedUserId;

                              }
                              
                              })
                              .catch((e) => shared.handleError.call(this, '157', false, e))
                              .finally(() => {
                              this.loader = false;
                              });

  

            },

            onSaveClick() {
                  // console.log("----SAVE----");  
                  // console.log(this.userData);  
                  
                  let data = {
                        userId: this.selectedUserId,
                        firstName: this.userData.FirstName,
                        lastName: this.userData.LastName,
                        email: this.userData.Email,
                        roleId: this.userData.RoleId,
                        positionId: this.userData.PositionId,
                        managerId: this.userData.ManagerId,
                        teamId: this.userData.TeamId,
                        statusId: this.userData.StatusId                       
                  }

                  //  console.log(data);
                  
                  
                  if (this.showDialogNewUser) {

                        adminService
                        .userAdd(data)
                        .then((response) => {
                              

                              if ((response.status === 200) & (response.data.Code === "OK")) {
                                    // console.log('response------');
                                    // console.log(response);

                                    console.log('--SAVED--');
                                    this.onChange();
                        
                              } 
                              else {
                                    this.error = response.data.Text;
                              }
                              // console.log('response------');
                              // console.log(response.data);


                        })
                        .catch((e) => shared.handleError.call(this, '158', true, e));


                  } 
                  else {


                        adminService
                        .userEdit(data)
                        .then((response) => {
                              

                              if ((response.status === 200) & (response.data.Code === "OK")) {
                                    // console.log('response------');
                                    // console.log(response);

                                    console.log('--SAVED--');
                                    this.onChange();
                        
                              } 
                              else {
                                    this.error = response.data.Text;
                              }
                              // console.log('response------');
                              // console.log(response.data);


                        })
                        .catch((e) => shared.handleError.call(this, '159', true, e));


                  }


                  this.showDialog = false;
                  this.showDialogNewUser = false;



            },


            onSaveFClick() { 

                  const functTab = {
                              "ResultName": "AdmUserFunctionSave",
                              "UserId": this.selectedUserId,
                              "FunctionTab": this.userFunction
                        };

                  console.log("----SAVE----");  
                  console.log(functTab);  


                  adminService
                              .uniRap(functTab)
                              .then((response) => {

                                    if ((response.status === 200) & (response.data.Status === "OK")) {
                                          // console.log('response------');
                                          // console.log(response);

                                          console.log('--SAVED--');
                                          
                              
                                    } 
                                    else {
                                          this.error = response.data.Message;
                                    }

                                    this.showDialogF = false;
                                    // console.log('response------');
                                    // console.log(response.data);
                              
                              })
                              .catch((e) => shared.handleError.call(this, '150', false, e))
                              .finally(() => {
                                    this.loader = false;
                              });

            },


            newUserClick() {


                  this.userData = {
                        Id: 0,
                        FirstName: '',
                        LastName: '',
                        Email: '',
                        RoleId: 1,
                        Role: '',
                        PositionId: null,
                        Position: '',
                        TeamId: null,
                        Team: '',
                        ManagerId: null,
                        DirectorId: null,
                        StatusId: 1,
                        Status: ''
                  };

                  //  console.log('new User------');
                  //  console.log(this.userPositions);
                  //  console.log(this.userTeams);
                  //  console.log(this.userManagers);



                  setTimeout(() => {
                              this.showDialogNewUser = true;
                              this.showDialog = true;
                              this.dialogTitle = 'New User';
                        },200);

                  


            }



      }

}
</script>

<style>


.inner-wrap3 table.vgt-table .cl-row-inactive td span {
    color: #d1d1d1!important;
}

dialog .nwd-wrap.u-wrap .vue-input {
    padding: 6px;
}

.u-form-item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 6px 0;
      align-items: baseline;
}


.u-form-item label {
      display: block;
      width: 30%;
      text-align: right;
      margin-right: 10px;

      overflow-wrap: break-word;
      font-size: 12px;
      font-weight: 800;
      color: #c25a10;

}

.u-form-item .f-item {
      width: 70%;
      text-align: left;
      margin-left: 10px;
}

.u-form-item .f-item .d-btn-s {
    width: 38px;
    min-width: 38px;
    padding: 2px;
    margin: 0;
    font-size: 13px;
}

.u-form-item input {
    border: 1px solid #e3e3e3;
    outline: 0;
    padding: 6px;
    width: 360px;
    font-size: 13px;
    line-height: 16px;
    border-radius: inherit;
    box-sizing: border-box;
}

.u-form-item .vue-select input {
      border: none;
}

.u-form-item:nth-of-type(1) .f-item {
    z-index: 9;
}

.u-form-item:nth-of-type(2) .f-item {
    z-index: 8;
}

.u-form-item:nth-of-type(3) .f-item {
    z-index: 7;
}

.u-form-item:nth-of-type(4) .f-item {
    z-index: 6;
}
.u-form-item:nth-of-type(5) .f-item {
    z-index: 5;
}
.u-form-item:nth-of-type(6) .f-item {
    z-index: 4;
}
.u-form-item:nth-of-type(7) .f-item {
    z-index: 3;
}
.u-form-item:nth-of-type(8) .f-item {
    z-index: 2;
}


.dialog-box.edit .nwd-wrap.u-wrap {
     margin-top: 16px;

}

.dialog-box.edit .nwd-wrap.u-wrap .vue-select {
      margin: initial;
      margin: unset;
      width: 260px;

}

.dialog-box.edit .nwd-wrap.u-wrap .f-item input {
      width: 260px;
      color: #667;

}

.dialog-box.edit .nwd-wrap.u-wrap .vue-dropdown {
     z-index: 10;
}



ul.function-list {
      list-style-type: none;
      width: 86%;
}

ul.function-list, .function-list li {
  padding: 0;
  margin: 0;
}

.function-list>li {
  display: flex;
  flex-direction: row;
  padding: 6px 0;
  padding-left: 10px;
  border-bottom: 1px solid #e0e0e0;
  
  
}

.function-list li>div {
  width: 75%;
  color: hsl(0, 0%, 40%);
  margin-top: 4px;
  margin-left: 6px;
}


.function-list .function-list-disabled {
      color: #d1d1d1;
}


.dark-mode .function-list .function-list-disabled {
      color: #3a3a3a;
}

</style>