<template>
      <Loader :loader="loader" />

      <base-dialog :show="error != null" :showCloseIcon="false" title="Something Fishy" @close="error == null">
            <template v-slot:content>                  
                  <div class="inv-wrapper nwd-wrap">
                        <div class="inv-form-item">
                              <label>{{error}}</label>    
                        </div>
                  </div>
            </template>
             <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="error=null" title="Close"><i class="fas fa-times"></i></base-button>                  
            </template>
      </base-dialog>

      <base-dialog :show="showDialog" :title="dialogTitle" @close="showDialog=false">
            <template v-slot:content>
                  
                  <div class="inv-wrapper nwd-wrap">
                        <div class="inv-form-item">
                              <label>Working hours</label>
                              <vue-select v-model="newWorkingHours" :options="workingHours" label-by="value" value-by="lp" clear-on-select clear-on-close close-on-select />
                                    
                        </div>
                       
                        <div v-if="showDialogNewDay" class="inv-form-item">
                              <label>Date</label>
                              <date-picker v-model="selectedDate" 
                                    :clearable="false"
                                    autoApply
                                    input-class-name="dp-custom-input"
                                    :format="format"
                                    :teleport="true"
                                    :previewFormat="format"
                                    :dark="settDarkMode"
                                    :enableTimePicker="false">

                                          <template #input-icon>
                                                &nbsp;&nbsp;<i class="far fa-calendar-alt"></i>
                                          </template>

                              </date-picker>            
                        </div>
                  


                  </div>
                  
            
            </template>

            <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="showDialog=false" title="Cancel"><i class="fas fa-times"></i></base-button>
                  <base-button class="d-btn d-edit" @click="onSaveClick()" title="Save"><i class="fas fa-save"></i></base-button>
            </template>
      </base-dialog>


      <div class="inner-wrap3 nwd-wrap">
     
            <h3>Non-working days</h3>
                  <div class="criteria">
                        <div class="control-wrap">
                              <span>Year: &nbsp;</span>
                              <vue-select 
                              v-model="year" 
                              :options="years" 
                              label-by="value" 
                              value-by="no" 
                              :blur="onDateChange"
                              close-on-select />  
                              <span>New day: &nbsp;</span>
                              <button title="New day" class="d-btn new-d" name="newDay" @click="newDayClick()"><i class="fas fa-plus"></i></button>      
                        </div>  
                  </div>

            <div class="center-pan">
                  <vue-good-table
                        :columns="table1columns"
                        :rows="table1rows"
                        :line-numbers="false"
                        :search-options="{enabled: false}"
                        :sort-options="{enabled: true}"  
                        :pagination-options="{
                        mode: 'records'
                        }" 
                                    
                  >
                        <template v-slot:emptystate>
                        No data
                        </template>

                        <template v-slot:table-row="props">
                              <div v-if="props.column.field == 'Action'">
                                          <button title="Edit" class="d-btn" :name="props.row.Date" @click="onBtnClick(props.row.Date, props.row.WorkingHours, 'edit')"><i class="fas fa-edit"></i></button>
                                          <button title="Delete" class="d-btn" :name="props.row.Date" @click="onBtnClick(props.row.Date,props.row.WorkingHours, 'del')"><i class="fas fa-trash"></i></button>
                              </div>
                              <span v-else>
                                    {{props.formattedRow[props.column.field]}}
                              </span>
                        </template>
                        
                  </vue-good-table>
            </div>        

      </div>
  
</template>

<script>
import shared from "@/shared";
import moment from "moment";
import Loader from '../../components/Loader.vue';
import adminService from "@/services/admin.js";


export default {

      components: {
            Loader,           
      },
     
      data: function () {

            return {
                  year: 0,
                  showNav: true,
                  error: null,
                  loader: false,
                  showDialog: false,
                  showDialogNewDay: false,
                  dialogTitle: '',
                  settDarkMode: false, 
                  newWorkingHours: 0,
                  selectedDate: null,
                  workingHours: [
                    {lp: 0, value: "0"},
                    {lp: 1, value: "1"},
                    {lp: 2, value: "2"},
                    {lp: 3, value: "3"},
                    {lp: 4, value: "4"},
                    {lp: 5, value: "5"},
                    {lp: 6, value: "6"},
                    {lp: 7, value: "7"},
                    {lp: 8, value: "8"}  
                  ],     
                  
                  years: [],
                  table1columns: [
                  {
                  label: 'Id',
                  field: 'ID',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Year',
                  field: 'Year',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Date',
                  field: 'Date',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Working hours',
                  field: 'WorkingHours',
                  tdClass: 'text-f14'
                  },
                  {
                  label: '',
                  field: 'Action',
                  tdClass: 'text-center',
                  sortable: false,
                  width: '128px',
                  }
                  ],
                  table1rows: []
                  
            };
      },

      mounted() {
            this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
            this.CookieRemove = shared.CookieRemove;
            this.CookieCheckGoToLogin();

            this.getSettingFromArr = shared.getSettingFromArr;
            this.setTheme = shared.setTheme;

                      
            // console.log('userSettings');
            // console.log(this.$store.getters.userSettings);

            this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
            this.setTheme(this.settDarkMode);

            this.yearList = shared.yearList;

            this.years = this.yearList();
            this.years.push({no: -1, value: '-All-'});


            //this.year = this.$store.getters.getCurrYear;
            this.onDateChange();

            setTimeout(() => {
                  this.year = -1;
            },480);

      },

      methods: {

            format(date) {
                  return ''+moment(date).format("YYYY-MM-DD");    
            },

            onDateChange() {
                  this.loader = true;
                  
                  console.log('data change');


                  adminService
                  .holidayList()
                  .then((response) => {

                  if (response.status === 200) {                 
                        

                                               
                        if(this.year > 0) {
                             
                              let y = this.year;
                              this.table1rows = response.data.filter(function(el) { return (el.Year == y ); });
                        }  
                        else {
                              this.table1rows = response.data;
                        } 

                        // console.log("----table1rows----");
                        // console.log(this.table1rows);
                       
                                          
                  }
                  
                  })
                  .catch((e) => shared.handleError.call(this, '121', false, e))
                  .finally(() => {
                  this.loader = false;
                  
                   
                  });

            },

            newDayClick() {

                  this.selectedDate = new Date();
                  this.newWorkingHours = 0;

                  this.showDialogNewDay = true;
                  this.showDialog = true;
                  this.dialogTitle = 'New date';
                  

            },

            onBtnClick(dateVal, hVal, type) {
                  console.log(dateVal);
                  console.log(type);

                  this.selectedDate = dateVal;

                  if ( type === 'del') {

                        let data = {
                              date: dateVal,
                              hour: -1
                        };

                        this.holidaySave(data);
                  }
                  else {

                        console.log(dateVal);
                        console.log(type);

                        this.selectedDate = dateVal;
                        this.showDialog = true;
                        this.dialogTitle = 'Edit date: '+dateVal;

                        this.newWorkingHours = hVal;                     


                  }

            },

            onSaveClick() {

                   let data = {
                              date: this.selectedDate,
                              hour: this.newWorkingHours
                        };

                        this.holidaySave(data);

                        this.showDialog = false;
                        this.showDialogNewDay = false;
                        this.selectedDate = null;


            },


            holidaySave(dataObj) {
                  
                  adminService
                  .holidayAdd(dataObj)
                  .then((response) => {
                        

                        if ((response.status === 200) & (response.data.Code === "OK")) {
                              // console.log('response------');
                              // console.log(response);

                              console.log('--SAVED--');
                              this.onDateChange();
                   
                        }
                  })
                  .catch((e) => shared.handleError.call(this, '122', true, e));


            }
      }

}
</script>

<style >

.d-btn {
    width: 38px;
    padding: 2px;
    margin: 0 5px;
}

.d-btn.new-d {
    padding: 10px;
    width: 46px;
}


.inv-wrapper.nwd-wrap .inv-form-item {
    margin: 8px 12px;
}


.inv-wrapper .inv-form-item input.dp-custom-input {
      width: 180px;
}

.inv-wrapper.nwd-wrap .inv-form-item label {
    text-align: center;
    margin-bottom: 12px;
}

.inv-wrapper.nwd-wrap .inv-form-item .vue-select input {
    border: none;
    padding: 8px 6px 4px 6px;
}

dialog .nwd-wrap .vue-input {
    padding: 4px 6px 6px 6px;
}

.dialog-box.edit .nwd-wrap .vue-select {
    width: 180px;
    margin: 0 auto;
}

.dialog-box.edit .nwd-wrap .vue-dropdown {
    max-height: 100px !important;
}


.text-center div {
      text-align: center !important;
}

.inner-wrap3.nwd-wrap .vue-select {
      width: 150px;
      margin-right: 16px;
}







</style>