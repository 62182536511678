<template>
    <Loader :loader="loader" />
   
    <div class="inner-wrap inner-wrap2">
      <h3>Export to Excel</h3>
      <div class="cnt-excel">
        <p>Export of raw data. You can process data in excel as you want.</p>
        <p class="info">(content depends on your permission)</p>

         
        <div class="date-wrapper">
          <span>Date range: &nbsp;</span>
          <date-picker v-model="date" 
          @closed="onDateChange"
          :clearable="false"
          menuClassName="dp-custom-menu"
          range 
          :partialRange="true"
          multiCalendars 
          multiCalendarsSolo 
          :presetRanges="presetRanges" 
          :format="format"
          :previewFormat="format"
          :dark="settDarkMode"
          :enableTimePicker="false">

             <template #input-icon>
                      &nbsp;&nbsp;<i class="far fa-calendar-alt"></i>
             </template>

          </date-picker>
         
        </div>

        <div class="date-wrapper all-users">
          <span>Show all users: </span>
              <label class="rad-label">
                    <input type="checkbox" class="rad-input" name="pagination" v-model="allUsers" @change="onDateChange" />
                    <div class="rad-design"></div>
            </label>
            <p class="info">(All users, including those without registered hours)</p>
        </div>


        <div v-if="uFnCheck(11)" class="date-wrapper agency">
              <span>Agency: &nbsp;</span>
              <vue-select 
              v-model="agencyId" 
              :options="agencies" 
              label-by="Description" 
              value-by="Id" 
              :blur="onDateChangeAgency"
              close-on-select />  
                                
        </div>  


        <vue-excel-xlsx class="d-btn ex-btn"
          :data="data"
          :columns="columns"
          :file-name="filename"
          :file-type="'xlsx'"
          :sheet-name="'timeThreadSheet'"
          :disabled="date === null"
          title="export to Excel"
          >
          <i class="fas fa-download"></i>
        </vue-excel-xlsx>
      </div>

      <div class="msg msg-err" v-if="errMessage != null">
                  <p>{{errMessage}}</p>
      </div>

    </div>

 
 
  
</template>

<script>
import reportService from "@/services/reports.js";
import moment from "moment";
import shared from "@/shared";
import Loader from '../../components/Loader.vue';
import adminService from "@/services/admin.js";

export default {
  components: {Loader },
  name: "App",
   

  data: function () {
    return {
      agencyId: this.$store.getters.userData.agencyId,
      agencies: [],
      date: [],
      getDate: new Date(),
      allUsers: false,
      loader: true,
      errMessage: null,
      settDarkMode: false,
      
      presetRanges: [],
      filename: 'filename',
      columns: [
                    {
                        label: "Agency",
                        field: "agency",
                    },
                    {
                        label: "Team",
                        field: "team",
                    },
                    {
                        label: "Director",
                        field: "director",                       
                    },
                    {
                        label: "Manager",
                        field: "manager",
                    },
                    {
                        label: "UserName",
                        field: "userName",
                    },
                    {
                        label: "Status",
                        field: "status",
                    },
                    {
                        label: "JobTitle",
                        field: "jobTitle",
                    },
                    {
                        label: "ClientName",
                        field: "clientName",
                    },
                    {
                        label: "JobNo",
                        field: "jobNo",
                    },
                    {
                        label: "JobName",
                        field: "jobName",
                    },
                    {
                        label: "Date",
                        field: "date",
                    },
                    {
                        label: "Hours",
                        field: "hours",
                    },
                    {
                        label: "Amount",
                        field: "amount",
                    }

                ],
      data: [],
    };
  },

  created() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    this.monthDays = shared.monthDays;
    this.getSettingFromArr = shared.getSettingFromArr;
    this.setTheme = shared.setTheme;
    this.uFnCheck = shared.uFnCheck;
  },

  mounted() {

    this.CookieCheckGoToLogin();

    this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
    this.setTheme(this.settDarkMode);
    
    
    this.filename = 'timeThread-rawdata-'+moment().format("YYMMDD.HHmmss");
    
    const startDate = moment().utc().add(-7, 'days');
    const endDate = moment().utc();
    this.date = [startDate, endDate];


    const dataA = {ResultName: "AgencyList"};
    adminService
          .uniRap(dataA)
          .then((response) => {

          if (response.status === 200) {                  
    
                this.agencies = response.data;    

                // console.log('agencies');
                // console.log(this.agencies);

                this.agencies.unshift({Id: 0, Description: '-- All Agencies --'});

                  setTimeout(() => {                         
                      
                      this.agencyId = parseInt(this.$store.getters.userData.agencyId);
                      //this.dashboardData();
                },480);
          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";
          
          
          if (e.response.status === 401) {
                this.CookieRemove();
          }
          });

    //let prevMonthf =  new Date(this.getDate.getFullYear(), this.getDate.getMonth() - 1, 1);
    //let prevMonthl =  new Date(this.getDate.getFullYear(), this.getDate.getMonth(), 0);

    this.presetRanges = [{ label: 'today', range: [moment().utc(), moment().utc()] },
 
          { label: 'this month', range: [new Date(Date.UTC(this.getDate.getUTCFullYear(), this.getDate.getUTCMonth(), 1,0,0,0)), new Date(Date.UTC(this.getDate.getUTCFullYear(), this.getDate.getUTCMonth() + 1, 0,0,0,0))] },
          { label: 'previous month', range: [new Date(Date.UTC(this.getDate.getUTCFullYear(), this.getDate.getUTCMonth()-1, 1,0,0,0)), new Date(Date.UTC(this.getDate.getUTCFullYear(), this.getDate.getUTCMonth(), 0,0,0,0))] },
          { label: 'this year', range: [new Date(Date.UTC(this.getDate.getUTCFullYear(), 0, 1,0,0,0)), new Date(Date.UTC(this.getDate.getUTCFullYear() + 1, 0, 0,0,0,0))] },
          { label: 'previous year', range: [new Date(Date.UTC(this.getDate.getUTCFullYear() - 1, 0, 1,0,0,0)), new Date(Date.UTC(this.getDate.getUTCFullYear(), 0, 0,0,0,0))] }]


    const data = {
      dateFrom: moment(this.date[0]).format("YYYY-MM-DD"),
      dateTo: moment(this.date[1]).format("YYYY-MM-DD"),
      agencyId: this.agencyId
    };

    this.dataToExport(data);

  },
 
   
  methods: {

    onDateChangeAgency(){
      console.log('data change agency');
       this.onDateChange();
    },

    format(date) {
   
      return ''+moment(date[0]).format("YYYY-MM-DD")+' - '+moment(date[1]).format("YYYY-MM-DD");
      
    },

    dataToExport(data) {
      reportService
        .rap0(data)
        .then((response) => {
          if (response.status == "200") {

            this.errMessage = null;
            this.data = response.data;
            
            if (this.data.length === 0) {
            this.errMessage ='No data available in the selected range.'
            }
            else {

              console.log('---data---');
              for(var i = 0; i < this.data.length; i++)
              {
                  //this.data[i].hours = this.data[i].hours.replace('.', ',');
                  this.data[i].hours = parseFloat(this.data[i].hours);
                  this.data[i].amount = parseFloat(this.data[i].amount);
              }
              // console.log(this.data);
            }
            
          }
          else {
            this.errMessage ='Something fishy with data! Reload the page.'
          }
          


        })
        .catch((e) => {
          this.errMessage = e.message || "Something went wrong!";
          if (e.response.status === 401) {
              this.CookieRemove();
          }
        })
        .finally(() => {
        this.loader = false;
        //this.$store.commit('setAxiosState', 0);
      });
    },

    onDateChange() {
      this.loader = true;

      const data = {
      dateFrom: moment(this.date[0]).format("YYYY-MM-DD"),
      dateTo: moment(this.date[1]).format("YYYY-MM-DD"),
      agencyId: this.agencyId,
      allUsers: this.allUsers ? 1 : 0
      };

      this.dataToExport(data);
    }
 

  },
  computed: {
    
  }

}
</script>

<style scoped>



.inner-wrap {
  text-align: center;

}

.inner-wrap h3 {
  font-family: 'Anton-Regular';
  font-size: 22px;
  letter-spacing: 2px;
}

.inner-wrap .vue-select {
    border-radius: 4px;
    z-index: 2;
    border: 1px solid #e3e3e3;
    background-color: #fff;
    width: 235px;
}

/* .inner-wrap .vue-input {
    padding: 10px 6px 10px 6px;
} */

.cnt-excel {
  margin-top: 46px;
}
.cnt-excel p {
  margin: 5px;
}


.cnt-excel .info {
  font-size: 12px;
  margin-bottom: 36px;
}

.date-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  justify-content: center;
}

.date-wrapper.all-users {
  flex-wrap: wrap;
}

.date-wrapper.all-users p.info {
  width: 100%;
  margin-bottom: 12px;
}

.date-wrapper .dp__main {
  width: 260px;
}

.ex-btn  {
  width: 102px;
  padding: 6px;
  margin-top: 22px;

}

.ex-btn i {
  font-size: 24px;
}

.dp-custom-menu  .dp__preset_range {
    font-size: 14px;
    font-family: 'Lato-Regular';
    color: #667;
}


.msg {
      color: #c25a10;
}

.msg-err p {
  margin-top: 12px;
}




</style>