//import axios from 'axios';
import store from "../store";


const API_URL = store.getters.getApiUrl;


export default {
      async createLoginPost(post) {
            
            const requestOptions = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify(post)
            };
            
            const response = await fetch(API_URL+"account/login", requestOptions);
            const postData = await response.json();

            //console.log(postData);
            return postData;

      },

      async createAccessPost(post) {
      
            const requestOptions = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: post
            };
      
            return await fetch(API_URL+'account/confirmation', requestOptions);
      }

             

}