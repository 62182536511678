<template>
    <Loader :loader="loader" />
   
    <div :class="['w-aqs', 'inner-wrap2', uFnCheck(11) ? '':'w-fin-d' ]">
      <!-- <p style="font-size: 28px; font-family: 'Anton-Regular';">coming soon</p> -->
     <h3>Annual quota summary</h3>
      <div class="criteria">
        <div class="control-wrap p-year">
            <span>Year: &nbsp;</span>
              <vue-select 
                v-model="year" 
                :options="years" 
                label-by="value" 
                value-by="no" 
                :blur="onDateChange"
                close-on-select />


            <span>Pagination: </span>
            <label class="rad-label">
                  <input type="checkbox" class="rad-input" name="pagination" v-model="pagination" />
                  <div class="rad-design"></div>
          </label>           
        </div>


        <div v-if="uFnCheck(11)" class="control-wrap agency">
              <span>Agency: &nbsp;</span>
              <vue-select 
              v-model="agencyId" 
              :options="agencies" 
              label-by="Description" 
              value-by="Id" 
              :blur="onDateChangeAgency"
              close-on-select />  
                                
        </div>  
       
        
        <div class="control-wrap excel">
            <vue-excel-xlsx class="d-btn ex-btn"
            :data="tableRows"
            :columns="tableColumns"
            :file-name="filename"
            :file-type="'xlsx'"
            :sheet-name="'AnnualQuotaSummary'"
            :disabled="tableRows == null"
            title="export to Excel"
            >
            <i class="fas fa-file-excel"></i>
          </vue-excel-xlsx>
        </div>
      </div>
      <div class="radio-type">
            <ul class="answer-list">
                  <li>
                        <label class="rad-label">
                          <input type="radio" class="rad-input" name="name1" value="1" v-model="rapType" :checked="rapType == 1" @change="onChangeType($event)" />
                          <div class="rad-design"></div>
                          <div class="rad-text">Jobs</div>
                        </label>                        
                  </li>
                  <li>
                        <label class="rad-label">
                          <input type="radio" class="rad-input" name="name2" value="2" v-model="rapType" :checked="rapType == 2" @change="onChangeType($event)" />
                          <div class="rad-design"></div>
                          <div class="rad-text">Projects</div>
                        </label>                        
                  </li>
                  <li v-if="uFnCheck(4)">
                        <label class="rad-label">
                          <input type="radio" class="rad-input" name="name3" value="3" v-model="rapType" :checked="rapType == 3" @change="onChangeType($event)" />
                          <div class="rad-design"></div>
                          <div class="rad-text">Teams</div>
                        </label>                        
                  </li>
            </ul>
        </div>

      <div class="center-pan">

        <vue-good-table
            :columns="tableColumns"
            :rows="tableRows"
            :row-style-class="rowStyleClass"
            :line-numbers="false"
            :search-options="{enabled: true}"
            :sort-options="{enabled: true}"  
            :pagination-options="{
              enabled: pagination,
              mode: 'records'
            }" 
                          
          >
            <template v-slot:emptystate>
                No data
            </template>
            
          </vue-good-table>
      </div>
        

    </div>
 
  
</template>

<script>
import shared from "@/shared";
import moment from "moment";
import adminService from "@/services/admin.js";
import reportService from "@/services/reports.js";
import financesService from "@/services/finances.js";
import Loader from '../../components/Loader.vue';
// import { DoughnutChart } from 'vue-chart-3';
// import { BarChart } from 'vue-chart-3';
// import { Chart, registerables } from "chart.js";

//Chart.register(...registerables);

export default {
  components: {
      Loader, 
      //DoughnutChart, 
      //BarChart 
  },
  name: "App",
  
  

  data: function () {
    return {
      year: 2022,
      teamId: 0,
      agencyId: this.$store.getters.userData.agencyId,
      agencies: [],
      showNav: true,
      error: null,
      loader: false,
      pagination: true,
      rapType: 1, // 1 - joby, 2 - projekty, 3 - dyrektorzy
      filename: 'filename',
      years: [],
      tableColumns: [],
      tableRows: [],
      table1columns: [
        {
          label: 'Client Name',
          field: 'clientName',
          tdClass: 'text-f12'
        },
        {
          label: 'Job No',
          field: 'jobNo',
          tdClass: 'text-f14'
        },
        {
          label: 'Job Name',
          field: 'jobName',
          tdClass: 'text-f12'
        },  
        { label: 'SFM Cost [pln]', field: 'sfmCost_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'SFM Income [pln]', field: 'sfmInvoice_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries [h]', field: 'entries_h', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries PC [pln]', field: 'entriesAmount_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries PC-30 [pln]', field: 'entriesAmount30_pln', type: 'number', tdClass: 'text-f14'}
      ],
      table1rows: [],
      table2columns: [
        {
          label: 'Client Name',
          field: 'clientName',
          tdClass: 'text-f12'
        },
        {
          label: 'Project Name',
          field: 'projectName',
          tdClass: 'text-f12'
        },  
        { label: 'SFM Cost [pln]', field: 'sfmCost_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'SFM Income [pln]', field: 'sfmInvoice_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries [h]', field: 'entries_h', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries PC [pln]', field: 'entriesAmount_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries PC-30 [pln]', field: 'entriesAmount30_pln', type: 'number', tdClass: 'text-f14'}
      ],
      table2rows: [],
      table3columns: [
        {
          label: 'Team',
          field: 'teamName',
          tdClass: 'text-f12'
        },
        {
          label: 'Client Name',
          field: 'clientName',
          tdClass: 'text-f12'
        }, 
        {
          label: 'Job No',
          field: 'jobNo',
          tdClass: 'text-f14'
        },  
        {
          label: 'Job Name',
          field: 'jobName',
          tdClass: 'text-f12'
        }, 
        { label: 'SFM Cost [pln]', field: 'sfmCost_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'SFM Income [pln]', field: 'sfmInvoice_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Revenue [pln]', field: 'reve_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries [h]', field: 'entries_h', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries PC [pln]', field: 'entriesAmount_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries PC-30 [pln]', field: 'entriesAmount30_pln', type: 'number', tdClass: 'text-f14'}
      ],
      table3rows: [],
      
    };
  },

  created() {
    this.yearList = shared.yearList;
    this.uFnCheck = shared.uFnCheck;

    this.years = this.yearList();
    this.year = this.$store.getters.getCurrYear;

    // console.log('years');
    // console.log(this.years);
    // console.log(this.year);

  },
 
  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    //this.yearList = shared.yearList;
    this.CookieCheckGoToLogin();   

    
    let userData = this.$store.getters.userData;    
                          
    this.teamId = userData.teamId;

    if (typeof this.teamId === 'undefined' ||  this.teamId == null) {
        

          const data = {
                ResultName: "teamShortToId",
                Short: userData.team
          };

          financesService
          .uniRap(data)
          .then((response) => {

          if (response.status === 200) {
              
                this.teamId = JSON.parse(response.data)[0].teamId;     
                
                if (this.uFnCheck(4)) {
                  this.teamId = 0;
                }

          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";        
          
          if (e.response.status === 401) {
                this.CookieRemove();
          }
          })

    }

    const data = {ResultName: "AgencyList"};
    adminService
          .uniRap(data)
          .then((response) => {

          if (response.status === 200) {                  
    
                this.agencies = response.data;    

                // console.log('agencies');
                // console.log(this.agencies);

                this.agencies.unshift({Id: 0, Description: '-- All Agencies --'});

                  setTimeout(() => {                         
                      
                      this.agencyId = parseInt(this.$store.getters.userData.agencyId);
                      //this.dashboardData();
                },480);
          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";
          
          
          if (e.response.status === 401) {
                this.CookieRemove();
          }
          });




    if (this.uFnCheck(4)) {
      this.teamId = 0;
    }

    if (this.uFnCheck(11)) {
      this.agencyId = 0;
    }

    setTimeout(() => {
      this.onDateChange();
    }, 200);


   
  },
   
  methods: {


    onDateChangeAgency(){
      console.log('data change agency');
       this.onDateChange();
    },
  
    onDateChange() {

      console.log('data change');

      this.loader = true;
      Promise.all([reportService.rap7Jobs(this.year, this.teamId, this.agencyId), reportService.rap7Projects(this.year, this.teamId, this.agencyId), reportService.rap8(this.year, this.teamId, this.agencyId)])
      .then(([rapJobs, rapProjects, rapDirectors]) => {
        
        if (rapJobs.status === 200) {

          this.table1rows = rapJobs.data; 
            // console.log("----rapJobs----");
            // console.log(this.table1rows);      
        }

        if (rapProjects.status === 200) {

          this.table2rows = rapProjects.data;
        }

        if (rapDirectors.status === 200) {

          this.table3rows = rapDirectors.data;
            // console.log("----rapDirectors----");
            // console.log(this.table3rows);      
        }
      })
      .catch((e) => {
        console.log("----error----");
        console.dir(e);

        this.error = e.message || "Something went wrong!";

        if (e.responses[0].status === 401) {
            this.CookieRemove();
        }
      })
      .finally(() => {
               
        this.onChangeType();
        this.loader = false;
      });

      

    },

    rowStyleClass(row) {

      return row.name == '-Total-' ? 'cl-total' : (row.status == 'Inactive' ? 'cl-row-inactive' : 'cl-row');
    },

    onChangeType() {
        
          console.log('rapType');
          console.log(this.rapType);

      let prefix = '';

      if (this.rapType == 1) {
            this.tableColumns = this.table1columns;
            this.tableRows = this.table1rows;
            prefix = 'Amounts-Jobs';
      } 
      else if (this.rapType == 2) {
            this.tableColumns = this.table2columns;
            this.tableRows = this.table2rows;
            prefix = 'Amounts-Projects';
      }
      else {
            this.tableColumns = this.table3columns;
            this.tableRows = this.table3rows;
            prefix = 'Amounts-Directors';
      }

      this.filename = ''+prefix+'-year-'+this.year+'-'+moment().format("YYMMDD.HHmmss");
      
        // console.log(this.tableColumns);
        // console.log(this.tableRows);

        
    }

   


    
 

  },
  computed: {
    
  }

}
</script>


<style scoped>

.control-wrap {
  flex-grow: unset;
}

.control-wrap.p-year {
  flex-basis: 53%;
  padding-right: 22px;
}

.control-wrap.agency {
    flex-basis: 34%;
    justify-content: flex-start;
    padding-left: 16px;
}

.control-wrap.excel {
    flex-basis: 10%;
}


.inner-wrap2.w-fin-d .criteria {
  justify-content: space-between;
}




</style>
