import axios from 'axios';
import store from "../store";

const API_URL = store.getters.getApiUrl;

export default {

      async userSettingsList() {
           
            const headers = store.getters.getAuthHeader;
            
            const response = await axios.get(API_URL + "user/setting/list", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async settingsAnswerList(id) {
           
            const headers = store.getters.getAuthHeader;
            
            const response =  await axios.get(API_URL + "setting/answer/"+id, {headers}).then();
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async userSettingsSave(settings) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "user/setting", settings, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async holiday(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "setting/holiday", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      }
      
     
}