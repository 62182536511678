<template>
      <Loader :loader="loader" />
      <div class="inner-wrap2 w-fin">
            <h3>Clients by period</h3>
            <div :class="['criteria', uFnCheck(11) ? 'v-sa':'' ]">
                  <div class="control-wrap r-fin">
                        <span>Year: &nbsp;</span>
                        <vue-select 
                        v-model="year" 
                        :options="years" 
                        label-by="value" 
                        value-by="no" 
                        :blur="onDateChange"
                        close-on-select />


                        <span>Period: &nbsp;</span>
                        <vue-select 
                        v-model="period" 
                        :options="periods" 
                        label-by="value" 
                        value-by="no" 
                        :blur="onDateChange"
                        close-on-select />
                  
                  </div>
            
                  <div class="control-wrap r-fin excel">
                        <vue-excel-xlsx class="d-btn ex-btn"
                              :data="itemsExcel"
                              :columns="headersExcel"
                              :file-name="filename"
                              :file-type="'xlsx'"
                              :sheet-name="'ClientsData'"
                              :disabled="itemsExcel == null"
                              title="export to Excel"
                        >
                        <i class="fas fa-file-excel"></i>
                        </vue-excel-xlsx>
                  </div>
            </div>

            <div class="radio-type">
                  <ul class="answer-list">
                        <li>
                              <label class="rad-label">
                              <input type="radio" class="rad-input" name="pc1" value="1" v-model="rapType" :checked="rapType == 1" @change=" onDateChange()" />
                              <div class="rad-design"></div>
                              <div class="rad-text">Projects</div>
                              </label>                        
                        </li>
                        <li>
                              <label class="rad-label">
                              <input type="radio" class="rad-input" name="pc2" value="2" v-model="rapType" :checked="rapType == 2" @change="onDateChange()" />
                              <div class="rad-design"></div>
                              <div class="rad-text">Jobs</div>
                              </label>                        
                        </li>
                  
                  </ul>
            </div>
            
            <div :class="['criteria', uFnCheck(11) ? 'v-sa':'' ]">

                        <div v-if="uFnCheck(11)" class="control-wrap agency">
                              <span>Agency: &nbsp;</span>
                              <vue-select 
                              v-model="agencyId" 
                              :options="agencies" 
                              label-by="Description" 
                              value-by="Id" 
                              :blur="onDateChangeAgency"
                              close-on-select />  
                                
                        </div>

                        <div v-if="uFnCheck(4)" class="control-wrap t-list">
                                    <span>Team name: &nbsp;</span>
                                    <vue-select
                                          v-model="teamId" 
                                          :options="teamList" 
                                          label-by="ProjectGroup" 
                                          value-by="Id"
                                          placeholder="select a team" 
                                          :blur="onDateChange"
                                          close-on-select
                                          clear-on-select 
                                          clear-on-close 
                                          >
                                          <template #dropdown-item="{ option }">
                                          <div :class="'ft-'+option.Financial.toString()">{{ option.ProjectGroup }}</div>
                                          </template>
                                    </vue-select>
                                                            
                        </div>
                  
                  
            </div>


            <div class="pan center-pan">
         
                  <div v-if="rapType == 1" class="p-view">

                        <EasyDataTable 
                              :headers="headers" 
                              :items="items"    
                              table-class-name="outer-custom"   
                              body-text-direction="right"
                              header-text-direction="center"                                             
                        >
                              <template #item-clientName="{ clientName }">
                                    <div style="text-align: left;">{{ clientName }}</div>
                              </template>

                              <template #expand="item">
                                    <div class="inner-table">  

                                                <!-- <table style="width: 100%; border: 1px solid lightgray;" cellspacing="0" cellpadding="0" >
                                                      <tr v-for="row in item.projects" :key="row.projectId">
                                                            <td style="padding: 5px; border: 1px solid lightgray;">{{row.projectName}}</td>
                                                            <td style="padding: 5px; border: 1px solid lightgray;">{{row.totalClientInv}}</td>
                                                            <td style="padding: 5px; border: 1px solid lightgray;">{{row.totalClientCost}}</td>
                                                            <td style="padding: 5px; border: 1px solid lightgray;">{{row.totalClientReve}}</td>
                                                      </tr>
                                                </table>                                               -->

                                          <EasyDataTable 
                                                :headers="headers2" 
                                                :items="item.projects" 
                                                :hide-footer="true"
                                                table-class-name="inner-custom"   
                                                body-text-direction="right"
                                                header-text-direction="center"
                                          >
                                                <template #empty-message>
                                                      <p>no data</p>
                                                </template>
                                                
                                                <template #item-projectName="{ projectName }">
                                                      <div style="text-align: left; padding-left: 72px">{{ projectName }}</div>
                                                </template>

                                          </EasyDataTable>
                                    
                                    </div>
                              </template>
                              <template #empty-message>
                                    <p>no data</p>
                              </template>
                              
                        </EasyDataTable>
                        

                  </div>
                  <div v-else class="j-view">

                        <EasyDataTable 
                              :headers="headers" 
                              :items="itemsJ"    
                              table-class-name="outer-custom"   
                              body-text-direction="right"
                              header-text-direction="center"                                             
                        >
                              <template #item-clientName="{ clientName }">
                                    <div style="text-align: left;">{{ clientName }}</div>
                              </template>

                              <template #expand="item2">
                                    <div class="inner-table">

                                                <!-- <table style="width: 100%; border: 1px solid lightgray;" cellspacing="0" cellpadding="0" >
                                                      <tr v-for="row in item2.jobs" :key="row.jobId">
                                                            <td style="padding: 5px; border: 1px solid lightgray;">{{row.jobName}}</td>
                                                            <td style="padding: 5px; border: 1px solid lightgray;">{{row.totalClientInv}}</td>
                                                            <td style="padding: 5px; border: 1px solid lightgray;">{{row.totalClientCost}}</td>
                                                            <td style="padding: 5px; border: 1px solid lightgray;">{{row.totalClientReve}}</td>
                                                      </tr>
                                                </table>                                  -->

                                          <EasyDataTable 
                                                :headers="headersJ2" 
                                                :items="item2.jobs" 
                                                :hide-footer="true"
                                                table-class-name="inner-custom"   
                                                body-text-direction="right"
                                                header-text-direction="center"
                                          >
                                                <template #empty-message>
                                                      <p>no data</p>
                                                </template>
                                                
                                                <template #item-jobName="{ jobName }">
                                                      <div style="text-align: left; padding-left: 72px">{{ jobName }}</div>
                                                </template>

                                          </EasyDataTable>
                                    
                                    </div>
                              </template>
                              <template #empty-message>
                                    <p>no data</p>
                              </template>
                              
                        </EasyDataTable>
                        

                  </div>
                  
            </div>

      </div>

</template>

<script>
import shared from "@/shared";
import moment from "moment";
import adminService from "@/services/admin.js";
//import reportService from "@/services/reports.js";
import financesService from "@/services/finances.js";
import Loader from '../../components/Loader.vue';

export default {
      components: {
            Loader, 
 
      },
      name: "App",

      data: function () {
      return {
            year: 2022,
            period: "Year",
            agencyId: this.$store.getters.userData.agencyId,
            agencies: [],
            showNav: true,
            error: null,
            loader: false,
            teamId: 0,
            filename: 'filename',
            years: [],
            periods: [
                  {no: "Year", value: "Year"},
                  {no: "H1", value: "H1"},
                  {no: "H2", value: "H2"},
                  {no: "Q1", value: "Q1"},
                  {no: "Q2", value: "Q2"},
                  {no: "Q3", value: "Q3"},
                  {no: "Q4", value: "Q4"},
            ],
            teamList: [],
            rapType: 1,
            headers: [
                        { text: "Client Name", value: "clientName", sortable: true },
                        { text: "Total Cost", value: "totalClientCost", sortable: true },
                        { text: "Total Income", value: "totalClientInv", sortable: true },
                        { text: "Revenue", value: "totalClientReve", sortable: true },
                  ],
            headers2: [
                        { text: "Project Name", value: "projectName", sortable: true },
                        { text: "Total Cost", value: "totalClientCost", sortable: true },
                        { text: "Total Income", value: "totalClientInv", sortable: true },
                        { text: "Revenue", value: "totalClientReve", sortable: true },
                      
                  ],
            headersJ2: [
                        { text: "Job Name", value: "jobName", sortable: true },
                        { text: "Total Cost", value: "totalClientCost", sortable: true },
                        { text: "Total Income", value: "totalClientInv", sortable: true },
                        { text: "Revenue", value: "totalClientReve", sortable: true },
                      
                  ],
            items: [],
            itemsJ: [],
            itemsExcel: [],
            headersExcel: [],
            headersExcelType1: [
                        { label: 'Client Name', field: 'clientName'},
                        { label: 'Project Name', field: 'projectName'},  
                        { label: 'Total Cost', field: 'totalClientCost', type: 'number'},
                        { label: 'Total Income', field: 'totalClientInv', type: 'number'},
                        { label: 'Revenue', field: 'totalClientReve', type: 'number'}
                      
                  ],
            headersExcelType2: [
                        { label: 'Client Name', field: 'clientName'},
                        { label: 'Job Name', field: 'jobName'},  
                        { label: 'Total Cost', field: 'totalClientCost', type: 'number'},
                        { label: 'Total Income', field: 'totalClientInv', type: 'number'},
                        { label: 'Revenue', field: 'totalClientReve', type: 'number'}
                      
                  ],
      };
      },

      created() {
            this.yearList = shared.yearList;
            this.uFnCheck = shared.uFnCheck;

            this.years = this.yearList();
            this.year = this.$store.getters.getCurrYear;

      },

      mounted() {
            this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
            this.CookieRemove = shared.CookieRemove;
            this.CookieCheckGoToLogin(); 
            

            let userData = this.$store.getters.userData;     
            this.teamId = userData.teamId;
            let teamTmp = this.teamId;

            console.log('teamId - 1');
            console.log(this.teamId);

            this.getDictTeam();

            if (typeof this.teamId === 'undefined' ||  this.teamId == null) {                  

                  const data = {
                        ResultName: "teamShortToId",
                        Short: userData.team
                  };

                  financesService
                  .uniRap(data)
                  .then((response) => {

                  if (response.status === 200) {
                        
                        this.teamId = JSON.parse(response.data)[0].teamId;   
                        
                        console.log('teamId - 2');
                        console.log(this.teamId);   
                        
                        teamTmp = this.teamId;

                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";        
                  
                  if (e.response.status === 401) {
                        this.CookieRemove();
                  }
                  })

            }


            const data = {ResultName: "AgencyList"};
            adminService
            .uniRap(data)
            .then((response) => {

            if (response.status === 200) {                  
      
                  this.agencies = response.data;    

                  // console.log('agencies');
                  // console.log(this.agencies);

                  this.agencies.unshift({Id: 0, Description: '-- All Agencies --'});

                        setTimeout(() => {                         
                        
                        this.agencyId = parseInt(this.$store.getters.userData.agencyId);
                        //this.dashboardData();
                  },480);
            }
            
            })
            .catch((e) => {
            this.error = e.message || "Something went wrong!";
            
            
            if (e.response.status === 401) {
                  this.CookieRemove();
            }
            });


            this.setFileName();   

            setTimeout(() => {
                        this.getData();
                        this.teamId = teamTmp;
            }, 300);

            
            
      },

      methods: {

            setFileName() {

                  let prefix = this.rapType == 1 ? 'Projects' : 'Jobs';
                  this.filename = ''+prefix+'-'+this.period+'-y'+this.year+'-'+moment().format("YYMMDD.HHmmss");

                  console.log('setFileName');
                  console.log(this.filename);

            },

            getDictTeam() {

                        financesService
                              .teamListA(this.agencyId)
                              .then((response) => {

                              if (response.status === 200) {                  
                              
                              this.teamList = response.data;                   
                              this.teamList.unshift({Id: 0, ProjectGroup: '-- All Teams --', Short: 'all', Financial: true});

                              }
                              
                              })
                              .catch((e) => {
                              this.error = e.message || "Something went wrong!";
                              
                              
                              if (e.response.status === 401) {
                                    this.CookieRemove();
                              }
                              });

            },

            onDateChange() {

                  console.log('data change');
                  console.log(this.rapType);

                  this.setFileName();                  
                  this.getData();                
                                    
            },

            onDateChangeAgency() {
                  this.getDictTeam();
                  this.onDateChange();

            },

            getData() {

                  let res = this.rapType == 1 ? 'rapClientProjects' : 'rapClientJobs';

                  const data = {
                        "ResultName": res,
                        "Year": this.year,
                        "TeamId": this.teamId,
                        "Period": this.period,
                        "AgencyId": this.agencyId
                  };

                 

                  this.loader = true;
                  financesService
                  .uniRap(data)
                  .then((response) => {

                        if (response.status === 200) {

 
                              if (this.rapType == 1) {
                                    this.items = JSON.parse(response.data);
                                    //this.itemsExcel = this.items;
                                    this.headersExcel = this.headersExcelType1;

                                    if (this.items == null) {
                                          this.items = [];
                                    }
                                    

                                    this.itemsExcel = [].concat(...this.items.map(client => {
                                          return client.projects.map(project => {
                                                return {
                                                      clientId: client.clientId,
                                                      clientName: client.clientName,
                                                      projectId: project.projectId,
                                                      projectName: project.projectName,
                                                      totalClientInv: project.totalClientInv,
                                                      totalClientCost: project.totalClientCost,
                                                      totalClientReve: project.totalClientReve
                                                }
                                          });
                                    }));

                              }
                              else {
                                    this.itemsJ = JSON.parse(response.data);
                                    //this.itemsExcel = this.itemsJ;
                                    this.headersExcel = this.headersExcelType2;

                                    if (this.itemsJ == null) {
                                          this.itemsJ = [];
                                    }


                                    this.itemsExcel = [].concat(...this.itemsJ.map(client => {
                                          return client.jobs.map(job => {
                                                return {
                                                      clientId: client.clientId,
                                                      clientName: client.clientName,
                                                      jobId: job.jobId,
                                                      jobName: job.jobName,
                                                      totalClientInv: job.totalClientInv,
                                                      totalClientCost: job.totalClientCost,
                                                      totalClientReve: job.totalClientReve
                                                }
                                          });
                                    }));


                                    
                              }                                 

                              

                               console.log('itemsExcel');
                               console.dir(this.itemsExcel);


                              

                        }
                        
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";
                  
                  
                  if (e.response.status === 401) {
                        this.CookieRemove();
                  }
                  })
                  .finally(() => {
                  this.loader = false;

                  
                              





                  });

            },
      },

      computed: {
            // showRes() {                 
            //      return this.items;
            // },
            // showResJ() {                 
            //      return this.itemsJ;
            // }
      }


}
</script>

<style scoped>

      .inner-wrap2.w-fin .criteria.v-sa .control-wrap.t-list {
            justify-content: start;
      }

      .inner-wrap2.w-fin .criteria.v-sa .control-wrap.agency {
            flex-basis: 15%;
      }

</style>