<template>
    <Loader :loader="loader" />
   
    <div class="inner-wrap2">
      <!-- <p style="font-size: 28px; font-family: 'Anton-Regular';">coming soon</p> -->
     
     <h3>Active noJob list</h3>
      <div class="criteria">
        <div class="control-wrap">
           
        </div>

        <div class="control-wrap excel">
            <vue-excel-xlsx class="d-btn ex-btn"
            :data="table1rows"
            :columns="table1columns"
            :file-name="filename"
            :file-type="'xlsx'"
            :sheet-name="'UsersByMonth'"
            :disabled="table1rows.length === 0"
            title="export to Excel"
            >
            <i class="fas fa-file-excel"></i>
          </vue-excel-xlsx>
        </div>
      </div>

      <div class="center-pan">

        <vue-good-table
            :columns="table1columns"
            :rows="table1rows"
            :line-numbers="false"
            :search-options="{enabled: true}"
            :sort-options="{enabled: true}"  
            :pagination-options="{
              enabled: false,
              mode: 'records'
            }" 
                          
          >
            <template v-slot:emptystate>
                No data
            </template>
            
          </vue-good-table>
      </div>
        

    </div>
 
  
</template>

<script>
import shared from "@/shared";
import moment from "moment";
import reportService from "@/services/reports.js";
import Loader from '../../components/Loader.vue';
// import { DoughnutChart } from 'vue-chart-3';
// import { BarChart } from 'vue-chart-3';
// import { Chart, registerables } from "chart.js";

//Chart.register(...registerables);

export default {
  components: {
      Loader, 
      //DoughnutChart, 
      //BarChart 
  },
  name: "App",
  
  

  data: function () {
    return {
      
      showNav: true,
      error: null,
      loader: false,
      filename: 'filename',
      table1columns: [
        {
          label: 'NoJobId',
          field: 'noJobId',
          tdClass: 'text-f14'
        },
        {
          label: 'From',
          field: 'from',
          tdClass: 'text-f14'
        },
        {
          label: 'To',
          field: 'to',
          tdClass: 'text-f14'
        },
        {
          label: 'Date',
          field: 'date',
          tdClass: 'text-f14'
        },
        {
          label: 'Hours',
          field: 'hours',
          tdClass: 'text-f14'
        },
        {
          label: 'Description',
          field: 'description',
          tdClass: 'text-f14'
        }
      ],
      table1rows: []
      
    };
  },
 
  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    this.CookieCheckGoToLogin();
    this.filename = 'ActiveNoJobList-'+moment().format("YYMMDD.HHmmss");


    this.onDateChange();
    

   
  },
   
  methods: {

  
    onDateChange() {
      this.loader = true;

     

      reportService
      .rap3()
      .then((response) => {

        if (response.status === 200) {
         this.table1rows = response.data;
          // console.log("----user list----");
          // console.log(this.usersList);
        }
        
      })
      .catch((e) => {
        this.error = e.message || "Something went wrong!";
        
        
        if (e.response.status === 401) {
            this.CookieRemove();
        }
      })
      .finally(() => {
        this.loader = false;
        
        //this.tableArr();

      });

    },
      
 

  },
  computed: {
    
  }

}
</script>

<style scoped >

.inner-wrap2 {
  text-align: center;

}

.inner-wrap2 h3 {
  font-family: 'Anton-Regular';
  font-size: 22px;
  letter-spacing: 2px;
}

.inner-wrap2 .dp__main {
  width: 260px;
}


.inner-wrap2 .criteria {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 26px;
  margin-top: -66px;
}


.inner-wrap2 .criteria span { 
  margin-left: 10px; 
  }

.inner-wrap2 .vue-select {
  border-radius:initial;
  border-radius: 4px;
  z-index: 2;
  border: 1px solid #e3e3e3;
  background-color: white;
  
}

.inner-wrap2 .vue-input {
  padding: 10px 6px 10px 6px;
}
.inner-wrap2 .vue-input input {
  background-color: transparent;
  font-size: 16px;
}

.inner-wrap2 .vue-dropdown {
  border: 1px solid #e3e3e3;
}

.inner-wrap2 .vue-input .icon.arrow-downward  {
  border-width: 8px 5px 0;
}

.inner-wrap2 .vue-dropdown-item.highlighted {
    background-color: #fff8e3;
}

.inner-wrap2 .vue-dropdown-item.selected.highlighted {
    background-color: #fceec2;
}

/* .dialog-box.edit .vue-select {
  width: 100%;

} */


.inner-wrap2 .center-pan {
    box-shadow: 0px 3px 16px -5px rgb(120 122 123);
    position: relative;
    background-color: white;
    margin: 10px;
    margin-left: 20px;
    padding: 18px;
    min-height: 380px;
   
}

.inner-wrap2 .text-f14 {
    font-size: 14px;
}

.inner-wrap2 table.vgt-table {
  font-size: 14px;
}

.inner-wrap2 .vgt-wrap__footer {
  padding: 10px 16px;
}

.inner-wrap2 .vgt-wrap__footer .footer__navigation, 
.inner-wrap2 .vgt-wrap__footer .footer__row-count__label,
.inner-wrap2 .vgt-wrap__footer .footer__navigation__page-btn span {
  font-size: 14px;
}


.dark-mode .inner-wrap2 .center-pan {
  box-shadow: 0px 3px 16px -5px rgb(24 24 24);
  background-color: #1c2630;
   
}






</style>