<template>
      <Teleport to="body">
            <div :class="['copied-cld', showCopied ? 'copied-active':'copied-inactive' ]" v-bind:style="{ top: modalTopCopied, left: modalLeftCopied }">Copied</div>
      </Teleport>
  <div :class="['event-wrap', 'prop', leftSide ? 'l-prop':'r-prop', noTitle ? 'n-title':'' ]">
      <div class="icons">
            <button class="i-btn" @click="closePropertyDialog" title="Close"><i class="fas fa-times"></i></button>
      </div>
      <div class="job-title">
            <div class="vuecal__event-title job-no">
                  <button title="Copy to clipboard" class="copy-btn i-btn" name="copyJob" @click="copy(title, $event)">{{ title }}</button>
            </div>
            
      </div>
      <slot name="modalBody"></slot>

  </div>
</template>

<script>
import shared from "@/shared";

export default {
      props: {
            title: String,
            leftSide: Boolean,
            noTitle: Boolean
      },

      data: function () {
            return {
                  modalTopCopied: 0,
                  modalLeftCopied: 0,
                  showCopied: false
            }
      },

      created() {
            this.copyToClipboard = shared.copyToClipboard;
      },

      methods: {
            closePropertyDialog() {
                  this.$emit('closePropertyDialog');
            },

            copy(title, e) {
                  
                        // console.log("---onEventCopy---");
                        // console.log(event);
                        // console.log(e);

                  let rClientX = e.pageX - 18;
                  let rClientY = e.pageY - 30;
                  this.modalTopCopied = "" + rClientY + "px";
                  this.modalLeftCopied = "" + rClientX + "px";

                  this.showCopied = true;

                  setTimeout(() => {
                  this.showCopied = false;
                  },1600);

                  this.copyToClipboard(title);
            }
      }
}
</script>

<style scoped>

.event-wrap.prop {
  background-color: #fafafa;
  border-top: 1px solid #ffdb6e;
  position: relative;
  margin-top: 36px;
}


.event-wrap.prop.r-prop::before {

    content: "";
    position: absolute;
    top: -25px;
    left: -15px;
    z-index: 1;
    border: solid 8px transparent;
    border-right-color: #fff8e3;
}

.event-wrap.prop.l-prop::before {

    content: "";
    position: absolute;
    top: -25px;
    right: -15px;
    z-index: 1;
    border: solid 8px transparent;
    border-left-color: #fff8e3;
}

.event-wrap.prop .icons {
      top: -32px;
      right: 6px;
      z-index: 10;
}

.event-wrap.prop .job-title {
      top: -27px;
      left: 6px;
}

.event-wrap.prop.n-title {
      margin-top: 0;
      border: none;
}

.event-wrap.prop.r-prop::before,
.event-wrap.prop.l-prop::before {
      display: none;
}

.event-wrap.n-title .icons {
      top: 0;
}

.event-wrap.n-title .job-title {
      display: none;
}

.job-title .copy-btn {
      font-family: Lato-Bold;
      font-size: 14px;
      color: #667;
      letter-spacing: .02em;
}
      

</style>