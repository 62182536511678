<template>
      <Loader :loader="loader" />

      <base-dialog :show="error != null" :showCloseIcon="false" title="Something Fishy" @close="error == null">
            <template v-slot:content>                  
                  <div class="inv-wrapper nwd-wrap">
                        <div class="inv-form-item">
                              <label>{{error}}</label>    
                        </div>
                  </div>
            </template>
             <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="error=null" title="Close"><i class="fas fa-times"></i></base-button>                  
            </template>
      </base-dialog>


      <base-dialog :show="showEmp" title="Team Employees" @close="showEmp=false" :windowWidth="720">
            <template v-slot:content>                  
                  <div class="inner-wrap2 emp-wrapper">

                        <vue-good-table
                              :columns="tableEmpColumns"
                              :rows="teamEmp"
                              max-height="480px"
                              
                              :line-numbers="false"
                              :search-options="{enabled: false}"
                              :sort-options="{enabled: true}"  
                        >
                              <template v-slot:emptystate>
                                    No data
                              </template>
                        
                        </vue-good-table>
                        
            
                  </div> 
            </template>

            <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="showEmp=false" title="Cancel"><i class="fas fa-times"></i></base-button>  
            </template>                
      </base-dialog>


      

            <div :class="['w-fin', 'inner-wrap2', uFnCheck(11) ? '':'w-fin-d' ]">
                  <h3>Dashboard</h3>
                  <div class="criteria"> 
                        <div class="control-wrap">
                              <span>Year: &nbsp;</span>
                              <vue-select 
                              v-model="year" 
                              :options="years" 
                              label-by="value" 
                              value-by="no" 
                              :blur="onDateChange"
                              close-on-select />

                        </div>

                        <div v-if="uFnCheck(11)" class="control-wrap">
                              <span>Agency: &nbsp;</span>
                              <vue-select 
                              v-model="agencyId" 
                              :options="agencies" 
                              label-by="Description" 
                              value-by="Id" 
                              :blur="onDateChangeAgency"
                              close-on-select />  
                              
                        </div>  
                  </div>

                  <div class="chart-1">
                        <div class="left-pan">

                        <h6>Team info</h6>
                        <div class="general-info">
                              
                              <div v-if="teamEditable" class="info-item">
                                    <label>Team name:</label>
                                    <vue-select
                                          v-model="teamId" 
                                          :options="teamList" 
                                          label-by="ProjectGroup" 
                                          value-by="Id"
                                          placeholder="select a team" 
                                          searchable 
                                          clear-on-select 
                                          clear-on-close 
                                          close-on-select
                                          >
                                          <template #dropdown-item="{ option }">
                                          <div :class="'ft-'+option.Financial.toString()">{{ option.ProjectGroup }}</div>
                                          </template>
                                    </vue-select>
                                    <a href="#" @click.prevent="onDateChange">Save</a>                              
                              </div>
                              <div v-else class="info-item">
                                    <label>Team name:</label>
                                    <span>{{teamInfo[0].reamName}}</span>
                                    <a v-if="uFnCheck(4) && this.agencyId !== 0" href="" @click.prevent="teamEditable = true">Change</a>
                              </div>
                              <div class="info-item">
                                    <label>Director:</label>
                                    <span>{{teamInfo[0].directorName}}</span>
                              </div>
                              <div v-if="!targetEditable" class="info-item cost-info">
                                    <label>Team target:</label>
                                    <span>{{noSeparator(teamInfo[0].teamTarget)}}</span>
                                    <a v-if="uFnCheck(5) && this.teamId !== 0" href="" @click.prevent="targetEditable = true">Change</a>
                              </div>
                              <div v-else class="info-item cost-info">
                                    <label>Team target:</label>
                                    <input type="text" id="proNameEdit" v-model="teamInfo[0].teamTarget" @keypress="onlyNumber($event)">
                                    <a href="" @click.prevent="targetChange()">Save</a>
                              </div>

                              
                              <div class="info-item">
                                    <label>Employees no:</label>
                                    <span>{{teamInfo[0].employeeNo}}</span>
                                    <a href="" @click.prevent="showEmp = true">Show</a>
                              </div>
                              <div class="info-item">
                                    <label>Projects no:</label>
                                    <span>{{teamInfo[0].projectNo}}</span>
                              </div>
                              <div class="info-item">
                                    <label>Jobs no:</label>
                                    <span>{{teamInfo[0].jobsNo}}</span>
                              </div>
                              <div class="info-item cost-info ex-cost">
                                    <label>External cost</label>
                                    <span>{{noSeparator(teamInfo[0].cost)}}</span>
                              </div>
                              <div class="info-item cost-info">
                                    <label>Total income</label>
                                    <span>{{noSeparator(teamInfo[0].income)}}</span>
                              </div>
                              <div class="info-item cost-info">
                                    <label>Total revenue</label>
                                    <span>{{noSeparator((teamInfo[0].income - teamInfo[0].cost).toFixed(2))}}</span> 
                              </div>
                              <div class="info-item cost-ts-info">
                                    <label>Total TS hours</label>
                                    <span>{{teamInfo[0].tShours.toFixed(1)}}</span>
                              </div>
                              <div class="info-item cost-ts-info">
                                    <label>Total TS amount</label>
                                    <span>{{noSeparator(teamInfo[0].tSamount.toFixed(2))}}</span>
                              </div>
                              <div class="info-item cost-ts-info">
                                    <label>Total TS amount-30</label>
                                    <span>{{noSeparator((teamInfo[0].tSamount - (teamInfo[0].tSamount * 0.3)).toFixed(2))}}</span>
                              </div>


                              <div class="info-item-q">    
                                                                   
                                    <table v-if="showQrtl">
                                          <tr>
                                                <th>Q1</th>
                                                <th>Q2</th>
                                                <th>Q3</th>
                                                <th>Q4</th>
                                          </tr>
                                          <tr>
                                                <td v-for="item in quartelyNo" :key="item.quarter" style="color: #d91204 !important;">
                                                      {{noSeparator(item.cost.toFixed(2))}}
                                                </td>                                                
                                          </tr>
                                          <tr>
                                                <td v-for="item in quartelyNo" :key="item.quarter" style="color: #42b982 !important;">
                                                      {{noSeparator(item.income.toFixed(2))}}
                                                </td>                                                
                                          </tr>
                                          <tr>
                                                <td v-for="item in quartelyNo" :key="item.quarter" style="color: #42b982 !important;">
                                                      {{noSeparator(item.reve.toFixed(2))}}
                                                </td>                                                
                                          </tr>
                                          <tr>
                                                <td v-for="item in quartelyNo" :key="item.quarter" style="color: #a11ca3 !important;">
                                                      {{item.tsHours.toFixed(1)}}
                                                </td>                                                
                                          </tr>
                                          <tr>
                                                <td v-for="item in quartelyNo" :key="item.quarter" style="color: #a11ca3 !important;">
                                                      {{noSeparator(item.tsAmount.toFixed(2))}}
                                                </td>                                                
                                          </tr>
                                           <tr>
                                                <td v-for="item in quartelyNo" :key="item.quarter" style="color: #a11ca3 !important;">
                                                      {{noSeparator(item.tsAmount30.toFixed(2))}}
                                                </td>                                                
                                          </tr>   
                                    </table>
                                    <table v-else class="tb-h">
                                          <tr>
                                                <th>H1</th>
                                                <th>H2</th>
                                          </tr>
                                          <tr>
                                                <td v-for="item in halfNo" :key="item.half" style="color: #d91204 !important;">
                                                      {{noSeparator(item.cost.toFixed(2))}}
                                                </td>                                                
                                          </tr>
                                          <tr>
                                                <td v-for="item in halfNo" :key="item.half" style="color: #42b982 !important;">
                                                      {{noSeparator(item.income.toFixed(2))}}
                                                </td>                                                
                                          </tr>
                                          <tr>
                                                <td v-for="item in halfNo" :key="item.half" style="color: #42b982 !important;">
                                                      {{noSeparator(item.reve.toFixed(2))}}
                                                </td>                                                
                                          </tr>
                                          <tr>
                                                <td v-for="item in halfNo" :key="item.half" style="color: #a11ca3 !important;">
                                                      {{item.tsHours.toFixed(1)}}
                                                </td>                                                
                                          </tr>
                                          <tr>
                                                <td v-for="item in halfNo" :key="item.half" style="color: #a11ca3 !important;">
                                                      {{noSeparator(item.tsAmount.toFixed(2))}}
                                                </td>                                                
                                          </tr>
                                           <tr>
                                                <td v-for="item in halfNo" :key="item.half" style="color: #a11ca3 !important;">
                                                      {{noSeparator(item.tsAmount30.toFixed(2))}}
                                                </td>                                                
                                          </tr>   
                                    </table>
                                    

                                    <a v-if="showQrtl" href="" @click.prevent="showQrtl = false">Show Half</a>
                                    <a v-else href="" @click.prevent="showQrtl = true">Show Quarter</a>
                                    
                              </div>

                              

                        </div>


                        </div>
                        <div class="right-pan">
                        
                              <LineChart 
                                    :chartData="lineData1"
                                    :options="lineOptions1"
                                    :height="320"
                              />
                        

                        </div> 
                  </div>


                  <div class="chart-0 chrt">
                        <div class="pan">

                              <h6>Client list</h6>
                              <br/>

                              <EasyDataTable 
                                    :headers="headers" 
                                    :items="items"    
                                    table-class-name="outer-custom"   
                                    body-text-direction="right"
                                    header-text-direction="center"                                             
                              >
                                    <template #item-clientName="{ clientName }">
                                          <div style="text-align: left;">{{ clientName }}</div>
                                    </template>

                                    <template #expand="item">
                                          <div class="inner-table">
                                                <!-- <table style="width: 100%; border: 1px solid lightgray;" cellspacing="0" cellpadding="0" >
                                                      <tr v-for="row in item.expand" :key="row.id">
                                                            <td style="padding: 5px; border: 1px solid lightgray;">{{row.firstName}}</td>
                                                            <td style="padding: 5px; border: 1px solid lightgray;">{{row.lastName}}</td>
                                                            <td style="padding: 5px; border: 1px solid lightgray;">{{row.phone}}</td>
                                                            <td style="padding: 5px; border: 1px solid lightgray;">{{row.id}}</td>
                                                      </tr>
                                                </table> -->

                                                <EasyDataTable 
                                                      :headers="headers2" 
                                                      :items="item.projects" 
                                                      :hide-footer="true"
                                                      table-class-name="inner-custom"   
                                                      body-text-direction="right"
                                                      header-text-direction="center"
                                                >
                                                      <template #empty-message>
                                                            <p>no data</p>
                                                      </template>
                                                      
                                                      <template #item-projectName="{ projectName }">
                                                            <div style="text-align: left; padding-left: 72px">{{ projectName }}</div>
                                                      </template>

                                                </EasyDataTable>
                                          
                                          </div>
                                    </template>
                                    <template #empty-message>
                                          <p>no data</p>
                                    </template>
                                    
                              </EasyDataTable>
                  
                        </div>
                  </div>

            </div>

  
  
</template>

<script>
import shared from "@/shared";
import adminService from "@/services/admin.js";
import financesService from "@/services/finances.js";
import Loader from '../../components/Loader.vue';
//import { DoughnutChart } from 'vue-chart-3';
//import { BarChart } from 'vue-chart-3';
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);


export default {
      components: {Loader, LineChart},
      name: "App",
    

      data: function () {
            return {
                  year: 2022,
                  years: [],
                  targetEditable: false,
                  teamId: 0,
                  agencyId: this.$store.getters.userData.agencyId,
                  agencies: [],
                  teamEditable: false,
                  teamInfo: [{
                        teamId: 0,
                        reamName:"",
                        directorName:"",
                        employeeNo:0,
                        teamTarget:0,
                        projectNo:0,
                        jobsNo:0,
                        income:0.0,
                        cost:0.0,
                        tShours:0.0,
                        tSamount:0.0
                        }
                  ],
                  teamEmp: [],
                  tableEmpColumns: [
                        {
                        label: 'Id',
                        field: 'id',
                        type: 'number',
                        tdClass: 'text-f14'
                        },
                        {
                        label: 'First Name',
                        field: 'firstName',
                        tdClass: 'text-f14'
                        },
                        {
                        label: 'Last Name',
                        field: 'lastName',                        
                        tdClass: 'text-f14'

                        },
                        {
                        label: 'Job Title',
                        field: 'jobTitle',                        
                        tdClass: 'text-f12'

                        }

                  ],
                  showEmp: false,
                  showNav: true,
                  error: null,
                  loader: false,
                  showDialog: false,
                  dialogTitle: '',
                  settDarkMode: false,
                  teamList: [],
                  quartelyNo: [],
                  halfNo: [],
                  showQrtl: true,
                  headers: [
                        { text: "Client Name", value: "clientName", sortable: true },
                        { text: "Total Cost", value: "totalClientCost", sortable: true },
                        { text: "Total Income", value: "totalClientInv", sortable: true },
                        { text: "Revenue", value: "totalClientReve", sortable: true },
                  ],
                  headers2: [
                        { text: "Project Name", value: "projectName", sortable: true },
                        { text: "Total Cost", value: "totalClientCost", sortable: true },
                        { text: "Total Income", value: "totalClientInv", sortable: true },
                        { text: "Revenue", value: "totalClientReve", sortable: true },
                      
                  ],
                  items: [],
                  lineData1: {
                        labels: [],
                        datasets: [
                              {
                                    label: 'Target (absolute)',
                                    data: [],
                                    backgroundColor: ['#830d85'],
                                    borderColor: ['#830d85'],
                                    pointStyle: 'circle',
                                    radius: 1,
                                    hoverRadius: 6                              
                              },
                              {
                                    label: 'Target (calculated)',
                                    data: [],
                                    backgroundColor: ['#bd6bd6'],
                                    borderColor: ['#bd6bd6'],
                                    pointStyle: 'circle',
                                    radius: 1,
                                    hoverRadius: 6 
                              },
                              {
                                    label: 'Realization',
                                    data: [],
                                    borderColor: ['#105233'],
                                    backgroundColor: ['#105233'],
                                    tension: 0.4
                              }
                        ]
                        },
                        lineOptions1: {
                        
                        responsive: true,
                        plugins: {
                        legend: {
                              position: 'bottom',
                              display: true
                        },
                        title: {
                              display: true,
                              text: 'Target Realization',
                        },
                        },
                        scales: {
                              x: {
                              title: {
                              display: true,
                              text: 'Month'
                              },
                              grid: {
                              color: '#a4a4a4'
                              }
                              },
                              y: {
                              title: {
                              display: true,
                              text: 'PLN'
                              },
                              grid: {
                              color: '#a4a4a4'
                              }             
                              }
                        }
                  },
                 
            }
      },

      created() {
            this.yearList = shared.yearList;
            this.noSeparator = shared.noSeparator;
            this.uFnCheck = shared.uFnCheck;

            this.years = this.yearList();
            
      },

      mounted() {

            this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
            this.getSettingFromArr = shared.getSettingFromArr;
            this.setTheme = shared.setTheme;
            this.onlyNumber = shared.onlyNumber;
            this.CookieCheckGoToLogin();
            this.CookieRemove = shared.CookieRemove;

            this.year = this.$store.getters.getCurrYear; 
            this.agencyId = this.$store.getters.userData.agencyId;

            this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
            this.setTheme(this.settDarkMode);

            let userData = this.$store.getters.userData;   

            this.teamId = parseInt(userData.teamId);
            if (this.uFnCheck(11)) {
                  this.teamId = 0;
            }

            if (typeof this.teamId === 'undefined' ||  this.teamId == null || isNaN(this.teamId) ) {
                

                  const data = {
                        ResultName: "teamShortToId",
                        Short: userData.team
                  };

                  financesService
                  .uniRap(data)
                  .then((response) => {

                  if (response.status === 200) {
                     
                        this.teamId = JSON.parse(response.data)[0].teamId;                   

                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";        
                  
                  if (e.response.status === 401) {
                        console.log("go to CR (1)");
                        this.CookieRemove();
                  }
                  })

            }


            const data = {ResultName: "AgencyList"};
            adminService
                  .uniRap(data)
                  .then((response) => {

                  if (response.status === 200) {                  
            
                        this.agencies = response.data;    

                        // console.log('agencies');
                        // console.log(this.agencies);

                        this.agencies.unshift({Id: 0, Description: '-- All Agencies --'});

                         setTimeout(() => {                         
                              
                              this.agencyId = parseInt(this.$store.getters.userData.agencyId);
                              //this.dashboardData();
                        },480);
                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";
                  
                  
                  if (e.response.status === 401) {
                        console.log("go to CR (2)");
                        this.CookieRemove();
                  }
                  });




            this.getDictTeam();

            setTimeout(() => {
                   this.onDateChange();
            }, 100);
                       
      },

      methods: {

            onDateChange() {

                  
                  this.clientTable();
                  this.line1DataRefresh();
                  this.dashboardRefresh();
                  this.quartelyDashboard();
                  this.halfYDashboard();
                  this.getDictTeam();


                  this.teamEditable = false;

            },


            onDateChangeAgency() {
                  this.teamId = 0;
                  this.onDateChange();

            },

            targetChange() {

                  const data = {
                        "year": this.year,
                        "tamId": this.teamId,
                        "target": this.teamInfo[0].teamTarget
                  };


                  // console.log('targetChange- Save');
                  // console.log(data);

                  financesService
                  .targetEdit(data)
                  .then((response) => {

                  if (response.status === 200) {
                  
                    this.targetEditable = false;

                  setTimeout(() => {
                        this.onDateChange();
                  }, 100);

                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";
                  
                  
                  if (e.response.status === 401) {
                        console.log("go to CR (3)");
                        this.CookieRemove();
                  }
                  })
                  .finally(() => {
                        console.log('targetEdit-fin');
                  });


            },

            getDictTeam() {

                  console.log('this.agencyId');
                  console.log(this.agencyId);

                  const data = {
                        "ResultName": "teamAgencyList",
                        "AgencyId": this.agencyId
                  };

                  financesService
                  // .teamList()
                  .uniRap(data)
                  .then((response) => {
 
                  if (response.status === 200) {
                  
                        //  console.log('this.teamList');
                        // console.log(this.teamList);
                    
                        this.teamList = JSON.parse(response.data); 
                        
                        this.teamList.unshift({Id: 0, ProjectGroup: '-- All Teams --', Short: 'all', Financial: true});

                        // console.log("----this.teamList----");
                        // console.log(this.teamList);  

                  }
                  
                  })
                  .catch((e) => {
                        this.error = e.message || "Something went wrong!";                  
                  
                        if (e.response.status === 401) {
                              console.log("go to CR (4)");
                              this.CookieRemove();
                        }
                  })
                  .finally(() => {
                        console.log('teamAgencyList-fin');
                  });

            },


            clientTable() {

                  const data = {
                        "ResultName": "dashboardClientProject",
                        "Year": this.year,
                        "TeamId": this.teamId,
                        "AgencyId": this.agencyId
                  };

                  //  console.log("----clientTable - this.teamId----");
                  //  console.log(this.teamId); 

                  financesService
                  .uniRap(data)
                  .then((response) => {

                  if (response.status === 200) {
                  
                
                  this.items = JSON.parse(response.data);    

                  if (this.items == null) {
                        this.items = [];
                  }
                  
                  //  console.log("----clientTable----");
                  //  console.log(this.items); 

                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";
                  
                  
                  if (e.response.status === 401) {
                        console.log("go to CR (5)");
                        this.CookieRemove();
                  }
                  })
                  .finally(() => {
                        console.log('dashboardClientProject-fin');
                  });

            },


            quartelyDashboard() {

                  const data = {
                        "ResultName": "dashboardQuartely",
                        "Year": this.year,
                        "TeamId": this.teamId,
                        "AgencyId": this.agencyId
                  };
                 
                  this.loader = true;

                  financesService
                  .uniRap(data)
                  .then((response) => {

                  if (response.status === 200) {
                  
                
                  this.quartelyNo = JSON.parse(response.data);    

                                   
                  //  console.log("----quartelyDashboard----");
                  //  console.log(this.quartelyNo); 

                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";
                  
                  
                  if (e.response.status === 401) {
                        console.log("go to CR (6)");
                        this.CookieRemove();
                  }
                  })
                  .finally(() => {
                  this.loader = false;
                  });

            },

            halfYDashboard() {

                  const data = {
                        "ResultName": "dashboardHalf",
                        "Year": this.year,
                        "TeamId": this.teamId,
                        "AgencyId": this.agencyId
                  };
                 

                  financesService
                  .uniRap(data)
                  .then((response) => {

                  if (response.status === 200) {
                  
                
                  this.halfNo = JSON.parse(response.data);    

                                   
                  //  console.log("----halfNoDashboard----");
                  //  console.log(this.quartelyNo); 

                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";
                  
                  
                  if (e.response.status === 401) {
                        console.log("go to CR (7)");
                        this.CookieRemove();
                  }
                  })
                  .finally(() => {
                  this.loader = false;
                  });

            },

            dashboardRefresh() {

                  const data = {
                        "Year": this.year,
                        "TeamId": this.teamId,
                        "AgencyId": this.agencyId
                  };

                  financesService
                  .dashboard(data)
                  .then((response) => {

                  if (response.status === 200) {                        
                   
                        // console.log('this.dashboard');
                        // console.log(response.data);

                        this.teamInfo = response.data.teams;
                        this.teamInfo[0].teamId = this.teamId;
                        this.teamEmp = response.data.persona;

                        // console.log('this.teamInfo');
                        // console.log( this.teamInfo);
                        //console.log( this.teamInfo[0].directorName);

                        // console.log('this.teamEmp');
                        // console.log(this.teamEmp);

                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";        
                  
                  if (e.response.status === 401) {
                        console.log("go to CR (8)");
                        this.CookieRemove();
                  }
                  })

            },

            line1DataRefresh() {

                  const data = {
                        "Year": this.year,
                        "TeamId": this.teamId,
                        "AgencyId": this.agencyId
                  };

                  financesService
                  .realization(data)
                  .then((response) => {

                  if (response.status === 200) {
                        

                        this.lineData1.labels = [];
                        for (let i = 0; i < 3; i++) {
                              this.lineData1.datasets[i].data = [];
                        }
                        
                        response.data.forEach(element => {
                        this.lineData1.labels.push(element.monthName);
                        this.lineData1.datasets[0].data.push(element.targetAbs);
                        this.lineData1.datasets[1].data.push(element.targetRel);
                        this.lineData1.datasets[2].data.push(element.cumulative);
                        });

                        

                        //  console.log('this.lineData1');
                        //  console.log(response.data);

                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";        
                  
                  if (e.response.status === 401) {
                        console.log("go to CR (9)");
                        this.CookieRemove();

                  }
                  })


            },
            

      }

}
</script>

<style>


      


      .inner-wrap2.w-fin h6 {
            font-size: 13px;
            text-align: center;
            margin: 8px;
      }

      .inner-wrap2.w-fin .general-info {
           text-align: left;
           padding-left: 10%;
           padding-top: 8px;
            position: relative;
      }

     

      .inner-wrap2.w-fin .general-info .info-item a {
            font-size: 13px;
            font-weight: 800;          
      }


      .inner-wrap2.w-fin .general-info .info-item .vue-select {
            display: inline-block;
            border-radius: initial;
            border-radius: unset;
            z-index: 2;
            border: 1px solid #e3e3e3;
            background-color: #fff;
            width: 258px;
            margin-right: 12px;                      
      }

      .inner-wrap2.w-fin .general-info .info-item .vue-input {
            padding: 2px 6px;
      }

      .inner-wrap2.w-fin .general-info .info-item .vue-input input {
            font-size: 13px;
      }

      .inner-wrap2.w-fin .general-info .info-item input {
            margin-right: 12px;
      }




      .inner-wrap2.w-fin .chart-1 {
            display: flex;
      }

      .inner-wrap2.w-fin .chart-1 .left-pan, 
      .inner-wrap2.w-fin .chart-1 .right-pan {
            flex-grow: 1;
      }

      .inner-wrap2.w-fin .chart-1 .left-pan {
            width: 40%;
      }

      .inner-wrap2.w-fin .chart-1 .right-pan {
            width: 60%;
      }

      

      .inner-wrap2.w-fin .criteria {
            font-size: 13px;
            text-align: center;
            margin: 8px;
      }

      .inner-wrap2.w-fin .criteria .control-wrap:nth-child(1) {
            justify-content: end;
            padding-right: 12px;
      }
      .inner-wrap2.w-fin .criteria .control-wrap:nth-child(2) {
            justify-content: start;
            padding-left: 12px;
      }

      .inner-wrap2.w-fin.w-fin-d .criteria .control-wrap:first-child {
            justify-content: center;
      }

      .inner-wrap2.w-fin div.vue3-easy-data-table__main {
            overflow: hidden !important;
      }

      .inner-wrap2.w-fin .inner-table {
            margin: -1px -11px -1px -11px;
      }

      .inner-wrap2.w-fin .outer-custom,
      .inner-wrap2.w-fin .inner-custom {
            --easy-table-header-background-color: #f2f4f7;     
      }


      .inner-wrap2.w-fin .vue-select {
            border-radius: 4px;
      }

    

      .inner-wrap2.w-fin .vue-select,
      .inner-wrap2.w-fin .vue-select .vue-dropdown {
            z-index: 4;
      }


      .inner-wrap2.w-fin .chart-0 {
            display: flex;
      }

      .inner-wrap2.w-fin .chart-0 .pan {
            width: 100%;
      }

      .inner-wrap2.w-fin .pan,
      .inner-wrap2.w-fin .center-pan,
      .inner-wrap2.w-fin .left-pan,
      .inner-wrap2.w-fin .right-pan {
            box-shadow: 0px 3px 16px -5px rgb(120 122 123);
            position: relative;
            background-color: white;
            margin: 10px 20px;
            padding: 18px;
      }

      .inner-wrap2.w-fin .center-pan,
      .inner-wrap2.w-fin .left-pan,
      .inner-wrap2.w-fin .right-pan {
            margin: 10px 10px;
      }

      .inner-wrap2.w-fin .left-pan {
            margin-left: 20px;
      }

      .inner-wrap2.w-fin .right-pan {
            margin-right: 20px;
      }

      .inner-wrap2.w-fin .chrt h5 {
            color: #666666;
            margin-top: 9px;
            margin-bottom: 10px;
      }


      .info-item-q {
            position: absolute;
            right: 5px;
            bottom: -21px;
            text-align: right;
      }

      .info-item-q table {
            font-size: 12px;
            padding: 0;
           
      }

      .info-item-q table th {
            text-align: center;
            border-bottom: 1px solid #dcdfe6;
      }

      .info-item-q table td {
            min-width: 60px;
            text-align: right;
            padding: 1px 3px;

      }

       .info-item-q table.tb-h td {
            min-width: 90px;
      }

      .info-item-q a {
            font-size: 13px;
            font-weight: 800;
      }


      @media (max-width: 1850px) {

            .inner-wrap2.w-fin .general-info {
                  padding-left: 4%;
            }
      }

      @media (max-width: 1740px) {

            .inner-wrap2.w-fin .chart-1 .left-pan {
            width: 50%;
            }

            .inner-wrap2.w-fin .chart-1 .right-pan {
                  width: 50%;
            }
      }

      @media (max-width: 1475px) {

            .info-item-q table {
                  font-size: 10px;
            }

            .info-item-q table td {
                  min-width: 52px;
                  padding: 2px 1px 3px;
            }           
      }

      @media (max-width: 1350px) {

            .info-item-q {
                  display: none;
            }     
      }




      /***  dark mode ****/


      .dark-mode .general-info a {
            color: #515151;
      }

      .dark-mode .general-info a:hover,
      .dark-mode .general-info a:active  {
            color: #c25a10;
      }




      .dark-mode.w-fin .pan,
      .dark-mode .inner-wrap2.w-fin .pan, 
      .dark-mode .inner-wrap2.w-fin .center-pan, 
      .dark-mode .inner-wrap2.w-fin .left-pan, 
      .dark-mode .inner-wrap2.w-fin .right-pan {
            box-shadow: 0px 3px 16px -5px rgb(24 24 24) !important;
            background-color: #1c2630 !important;
      
      }



      .dark-mode .outer-custom,
      .dark-mode .inner-custom {

            --easy-table-border: 1px solid #445269;
            --easy-table-row-border: 1px solid #3e6487;

            --easy-table-header-font-size: 14px;
            --easy-table-header-height: 50px;
            --easy-table-header-font-color: #c1cad4;
            --easy-table-header-background-color: #1e3245 !important;

            --easy-table-header-item-padding: 10px 15px;

            --easy-table-body-even-row-font-color: #fff;
            --easy-table-body-even-row-background-color: #1e3245;

            --easy-table-body-row-font-color: #c0c7d2;
            --easy-table-body-row-background-color: #1e3245;
            --easy-table-body-row-height: 50px;
            --easy-table-body-row-font-size: 14px;

            --easy-table-body-row-hover-font-color: #c0c7d2;
            --easy-table-body-row-hover-background-color: #2c2f36;

            --easy-table-body-item-padding: 10px 15px;

            --easy-table-footer-background-color: #1e3245;
            --easy-table-footer-font-color: #c0c7d2;
            --easy-table-footer-font-size: 14px;
            --easy-table-footer-padding: 0px 10px;
            --easy-table-footer-height: 50px;

            --easy-table-rows-per-page-selector-width: 70px;
            --easy-table-rows-per-page-selector-option-padding: 10px;
            --easy-table-rows-per-page-selector-z-index: 1;


            --easy-table-scrollbar-track-color: #2d3a4f;
            --easy-table-scrollbar-color: #2d3a4f;
            --easy-table-scrollbar-thumb-color: #4c5d7a;;
            --easy-table-scrollbar-corner-color: #2d3a4f;

            --easy-table-loading-mask-background-color: #2d3a4f;

      }


</style>