<template>
  <nav-header :showNav="showNav" :clearButtons="true">
      <template v-slot:navView>
          <h3>Settings</h3>
      </template>      
     
      <template v-slot:navLog>
        <!-- <button class="nav-btn" @click="showMenu = !showMenu"><i :class="[showMenu ? 'far':'fas', 'fa-user']"></i></button> -->
        <button class="nav-btn" @click="showMenu = !showMenu"><i :class="[showMenu ? 'fa-ellipsis-v':'fa-ellipsis-h', 'fas']"></i></button>
      </template>
  </nav-header>

  
    <div class="wrap wrap-settings">

      <form @submit.prevent="settingsSave">
        <div class="form-container">
          
          <ul class="settings-list">
            <li v-for="(item, index) in settingsTab" :key="item.settingsId">
              <div>
                <span>{{item.description}}</span>
              </div>
                
              <ul class="answer-list">
                  <li v-for="itemAnswer in answers(item.settingsId)" :key="itemAnswer.id">
                      <!-- <input type="radio" :name="'name'+item.settingsId" :value="itemAnswer.value" v-model="settingsTab[index].value" :checked="itemAnswer.value == item.value" />  -->
                        <label class="rad-label">
                          <input type="radio" class="rad-input" :name="'name'+item.settingsId" :value="itemAnswer.value" v-model="settingsTab[index].value" :checked="itemAnswer.value == item.value" />
                          <div class="rad-design"></div>
                          <div class="rad-text">{{itemAnswer.name}}</div>
                        </label>
                        
                  </li>
              </ul>
                  
            </li>
          </ul>
          
        
          <div class="btn-container">
            <button type="submit" class="d-btn"><i class="fas fa-save"></i></button>
          </div>
          <div class="msg">
            {{statusMessage}}
          </div>
        </div>
      </form>
      
    </div>
 
  <nav-menu :showMenu="showMenu" ></nav-menu>
</template>

<script>
import shared from "@/shared";
import settings from "@/services/settings.js";

export default {
  components: { },
  name: "App",
  
  created() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    this.getSettingFromArr = shared.getSettingFromArr;
    this.setTheme = shared.setTheme;
    this.CookieCheckGoToLogin();
    this.answersSettings();

    this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
    this.setTheme(this.settDarkMode);

    //this.answers(2);  

    setTimeout(() => {
        if (!this.settingsTab.length) {
          this.settingsTab = this.$store.getters.userSettings;          
        }       
        this.questionSettings();
    }, 200);

    
  },

  data: function () {
    return {
      showMenu: true,
      showNav: true,
      statusMessage: null,
      settingsTab: this.$store.getters.userSettings,
      settingsAnswer: [],
      settDarkMode: false,
      error: null
    };
  },
  watch: {

    settingsTab: {
      handler: function () {
        this.clearMsg();
        this.settingsSave();

      },
      deep: true 
    },

    statusMessage: {
      handler: function () {
        setTimeout(() => {
            this.statusMessage = null; 
        }, 3000);


      },
      deep: true

    }
  },
   
  methods: {
    clearMsg() {
        this.statusMessage = '';
       
    },  
    
    
    questionSettings() {
      settings
        .userSettingsList()
        .then((response) => {
        
              if (response.status === 200) {

                    console.log('catch - response');
                    console.log(response);

                    const userSettingsInLocalStorage = JSON.parse(localStorage.getItem('userSettings')) || [];
                    const userSettingsInDb = response.data;

                    if (JSON.stringify(userSettingsInLocalStorage) !== JSON.stringify(userSettingsInDb)) {

                      this.settingsTab = userSettingsInDb;
                      this.$store.commit('setUserSettings', this.settingsTab);
                      localStorage.userSettings = JSON.stringify(this.settingsTab);          
                    } 
                   

              }
            
        })       
        .catch((e) => {
          this.error = e.message || "Something went wrong!";
          this.statusMessage = this.error;
          shared.handleError.call(this, '990', true, e);
        });


    },

    
    answersSettings() {
       settings
        .settingsAnswerList(0)
        .then((response) => {
        
          if (response.status === 200) {
            this.settingsAnswer =  response.data;
          }
        })
        .catch((e) => {
          this.error = e.message || "Something went wrong!";
          this.statusMessage = this.error;
          shared.handleError.call(this, '991', true, e);
        });

    },

    answers(id) {
      let newarr = [];

      newarr = this.settingsAnswer.filter( function(el) {
        return el.settingsId == id;
      });
      return newarr;
    },

    settingsSave() {

      settings
      .userSettingsSave(this.settingsTab)
          .then((response) => {
            

            if ((response.status === 200) & (response.data.Code === "OK")) {
              // console.log('response------');
              // console.log(response);

              console.log('--SAVED--');
                 
              this.$store.commit('setUserSettings', this.settingsTab);
              localStorage.userSettings = JSON.stringify(this.settingsTab);

              this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
              this.setTheme(this.settDarkMode);
              
              this.statusMessage = 'Magnificently! Your settings have been saved.';
            }
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";
            this.statusMessage = this.error;
            shared.handleError.call(this, '992', true, e);
          });



    }

  },
  computed: {
    
  }

}
</script>

<style scoped>



.wrap.wrap-settings {
  flex-direction: column;
  padding-right: 62px;
}

.wrap.wrap-settings::before {
  content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 640px;
      height: 640px;
      opacity: 0.1;
      background-image: url('../assets/settings.png');
      background-repeat: no-repeat;
      z-index: -1;
}

.wrap.wrap-settings::after {
  content: ' ';
      display: block;
      position: absolute;
      right: 71px;
      bottom: 50px;
      width: 320px;
      height: 314px;;
      opacity: 0.1;
      background-image: url('../assets/settings2.png');
      background-repeat: no-repeat;
      background-position: right bottom;
      z-index: -1;
}

.form-container {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  text-align: left;
  padding: 16px;
  padding-top: 26px;
  padding-bottom: 40px;
  box-shadow: 0px 3px 16px -5px rgb(120 122 123);
  position: relative;
  background-color: white;
}

.btn-container {
  text-align: center;
  margin: 26px 0 18px;

  display: none;
}

ul {
  list-style-type: none;
}

ul, li {
  padding: 0;
  margin: 0;
}

.settings-list>li {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  padding-left: 10px;
  border-bottom: 1px solid #e0e0e0;
  
  
}

.settings-list li>div {
  width: 75%;
  color: hsl(0, 0%, 40%);
  margin-top: 6px;
  
}

.wrap.wrap-settings .d-btn {
  font-size: 22px;
  cursor: pointer;
  padding: 5px 15px;
  margin: 10px 5px;
  min-width: 80px;
  display: inline-block;
}

/* ul.answer-list {
  display: flex;
  flex-wrap: wrap;
}

ul.answer-list li {
  width: 50%;
  box-sizing: border-box;
} */

.msg {
    color: #c25a10;
    font-size: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 3px;
    text-align: center;
    height: 28px;

}


.rad-label {
  display: flex;
  align-items: center;

  border-radius: 100px;
  padding: 3px 16px;
  margin: 3px 0;

  cursor: pointer;
  transition: .3s;
}

.rad-label:hover,
.rad-label:focus-within {
  background: hsla(0, 0%, 80%, .14);
}

.rad-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.rad-design {
  width: 16px;
  height: 16px;
  /* border-radius: 6px; */

  background: linear-gradient(to right bottom,  #c25a10, #ffdb6e);
  position: relative;
}

.rad-design::before {
  content: '';

  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: .3s;
}

.rad-input:checked+.rad-design::before {
  transform: scale(0);
}

.rad-text {
  color: hsl(0, 0%, 60%);
  margin-left: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 900;
  transition: .3s;
}

.rad-input:checked~.rad-text {
  color: hsl(0, 0%, 40%);
}


.dark-mode .form-container {

    box-shadow: 0px 3px 16px -5px rgb(24 24 24);
    background-color: #1c2630;

}

.dark-mode .settings-list li>div {
    color: #d1d1d1;
}

.dark-mode .rad-design::before {
    background-color: rgb(200, 200, 200);
}





</style>