<template>
      <Loader :loader="loader" />
      <div class="inner-wrap2 w-fin">
            <h3>Clients and Agencies by period</h3>
            <div class="criteria">
                  <div class="control-wrap r-fin">
                        <span>Year: &nbsp;</span>
                        <vue-select 
                        v-model="year" 
                        :options="years" 
                        label-by="value" 
                        value-by="no" 
                        :blur="onDateChange"
                        close-on-select />


                        <span>Period: &nbsp;</span>
                        <vue-select 
                        v-model="period" 
                        :options="periods" 
                        label-by="value" 
                        value-by="no" 
                        :blur="onDateChange"
                        close-on-select />
                  
                  </div>
            
                  <div class="control-wrap r-fin excel">
                        <vue-excel-xlsx class="d-btn ex-btn"
                              :data="itemsExcel"
                              :columns="headersExcel"
                              :file-name="filename"
                              :file-type="'xlsx'"
                              :sheet-name="'ClientsData'"
                              :disabled="itemsExcel == null"
                              title="export to Excel"
                        >
                        <i class="fas fa-file-excel"></i>
                        </vue-excel-xlsx>
                  </div>
            </div>

             
            <div class="pan center-pan">
         
                  <div class="p-view a-view">

                        <EasyDataTable 
                              :headers="headers" 
                              :items="items"    
                              table-class-name="outer-custom"   
                              body-text-direction="right"
                              header-text-direction="center"                                             
                        >
                              <template #item-clientName="{ clientName }">
                                    <div style="text-align: left;">{{ clientName }}</div>
                              </template>

                              <template #item-totalClientHours="{ totalClientHours }">
                                    <div style="margin-right: 48px;">{{ totalClientHours }}</div>
                              </template>

                              <template #expand="item">
                                    <div class="inner-table">  

                                          <EasyDataTable 
                                                :headers="headers2" 
                                                :items="item.agencies" 
                                                :hide-footer="true"
                                                table-class-name="inner-custom"   
                                                body-text-direction="right"
                                                header-text-direction="center"
                                          >
                                                <template #empty-message>
                                                      <p>no data</p>
                                                </template>
                                                
                                                <template #item-agencyName="{ agencyName }">
                                                      <div style="text-align: left; padding-left: 72px">{{ agencyName }}</div>
                                                </template>

                                          </EasyDataTable>
                                    
                                    </div>
                              </template>
                              <template #empty-message>
                                    <p>no data</p>
                              </template>
                              
                        </EasyDataTable>
                        

                  </div>
                  
                  
            </div>

      </div>

</template>

<script>
import shared from "@/shared";
import moment from "moment";
//import reportService from "@/services/reports.js";
import financesService from "@/services/finances.js";
import Loader from '../../components/Loader.vue';

export default {
      components: {
            Loader, 
 
      },
      name: "App",

      data: function () {
      return {
            year: 2022,
            period: "Year",
            showNav: true,
            error: null,
            loader: false,
            filename: 'filename',
            years: [],
            periods: [
                  {no: "Year", value: "Year"},
                  {no: "H1", value: "H1"},
                  {no: "H2", value: "H2"},
                  {no: "Q1", value: "Q1"},
                  {no: "Q2", value: "Q2"},
                  {no: "Q3", value: "Q3"},
                  {no: "Q4", value: "Q4"},
            ],
            headers: [
                        { text: "Client Name", value: "clientName", sortable: true },
                        { text: "Total Hours", value: "totalClientHours", sortable: true },                      
                  ],
            headers2: [
                        { text: "Agency Name", value: "agencyName", sortable: true },
                        { text: "Total Hours", value: "totalAgencyHours", sortable: true },

                      
                  ],
           
            items: [],
            itemsExcel: [],
            headersExcel: [],
            headersExcelType1: [
                        { label: 'Client Name', field: 'clientName'},
                        { label: 'Agency Name', field: 'agencyName'},  
                        { label: 'Total Hours', field: 'totalAgencyHours', type: 'number'},
                      
                  ]
      };
      },

      created() {
            this.yearList = shared.yearList;
            this.uFnCheck = shared.uFnCheck;

            this.years = this.yearList();
            this.year = this.$store.getters.getCurrYear;

      },

      mounted() {
            this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
            this.CookieRemove = shared.CookieRemove;
            this.CookieCheckGoToLogin(); 

            if (!this.uFnCheck(11)) {
                  this.CookieRemove();    
            }
           
            this.setFileName();   

            setTimeout(() => {
                        this.getData();
            }, 300);

            
            
      },

      methods: {

            setFileName() {

                  let prefix = 'Clients-Agencies';
                  this.filename = ''+prefix+'-'+this.period+'-y'+this.year+'-'+moment().format("YYMMDD.HHmmss");
                  // console.log('setFileName');
                  // console.log(this.filename);

            },

            

            onDateChange() {

                  this.setFileName();                  
                  this.getData();                
                                    
            },


            getData() {

                  let res = 'rapClientAgencies';

                  const data = {
                        "ResultName": res,
                        "Year": this.year,
                        "Period": this.period                        
                  };
                 

                  this.loader = true;
                  financesService
                  .uniRap(data)
                  .then((response) => {

                        if (response.status === 200) {

 
                                    this.items = JSON.parse(response.data);
                                    //this.itemsExcel = this.items;
                                    this.headersExcel = this.headersExcelType1;

                                    if (this.items == null) {
                                          this.items = [];
                                    }
                                    

                                    this.itemsExcel = [].concat(...this.items.map(client => {
                                          return client.agencies.map(agency => {
                                                return {
                                                      clientId: client.clientId,
                                                      clientName: client.clientName,
                                                      agencyId: agency.agencyId,
                                                      agencyName: agency.agencyName,
                                                      totalAgencyHours: agency.totalAgencyHours

                                                }
                                          });
                                    }));

                              
                             
                              

                               console.log('itemsExcel');
                               console.dir(this.itemsExcel);


                              

                        }
                        
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";
                  
                  
                  if (e.response.status === 401) {
                        this.CookieRemove();
                  }
                  })
                  .finally(() => {
                  this.loader = false;

                  });

            },
      },

      computed: {
            // showRes() {                 
            //      return this.items;
            // },
            // showResJ() {                 
            //      return this.itemsJ;
            // }
      }


}
</script>

<style>


      .a-view .vue3-easy-data-table.inner-custom tbody tr td {
            color: darkblue;
      }

      .a-view .vue3-easy-data-table.inner-custom tbody tr td:nth-child(2) {
            padding-right: 76px;
      }

</style>