<template>
  <teleport to="body">
  

    <transition name="dialog">
      <div v-if="show" class="glass-morph">      
        <div id="ballWrapper">
					<div id="ball"></div>
					<div id="ballShadow"></div>			
				</div>
        <h4 @click="tryClose">Don't play, complete TimeSheet !</h4>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      min: 'rotate(0deg)',
      sec: 'rotate(0deg)',
      hour: 'rotate(0deg)'
    };
  },
  emits: ["close"],
  mounted() {
    
  },
  methods: {
    tryClose() {
      this.$emit("close");
    },
  }
};
</script>

<style>


.glass-morph {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  z-index: 99;
  /* border-radius: 12px; */
  border: none;
  padding: 0;
  margin: 0;
  overflow: hidden;

  background: rgba( 255, 255, 255, 0.2 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 7px );
  -webkit-backdrop-filter: blur( 7px );
  border-radius: 10px;
}

.glass-morph h4 {
      text-align: center;
      z-index: 199;
      cursor: pointer;
}


#ballWrapper {
	width: 140px;
	height: 300px;
	position: fixed;
	left: 50%;
	top: 35%;
	margin-left: -70px;
	z-index: 100;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 5s linear 0s;
	-moz-transition: all 5s linear 0s;
	transition: all 5s linear 0s;
	cursor: pointer;
}



#ballWrapper:active {
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	cursor: pointer;
}

#ball {
	width: 140px;
	height: 140px;
	border-radius: 70px;
	background: rgb(187,187,187);
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2JiYmJiYiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijk5JSIgc3RvcC1jb2xvcj0iIzc3Nzc3NyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
	background: -moz-linear-gradient(top,  rgba(187,187,187,1) 0%, rgba(119,119,119,1) 99%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(187,187,187,1)), color-stop(99%,rgba(119,119,119,1)));
	background: -webkit-linear-gradient(top,  rgba(187,187,187,1) 0%,rgba(119,119,119,1) 99%);
	background: -o-linear-gradient(top,  rgba(187,187,187,1) 0%,rgba(119,119,119,1) 99%);
	background: -ms-linear-gradient(top,  rgba(187,187,187,1) 0%,rgba(119,119,119,1) 99%);
	background: linear-gradient(top,  rgba(187,187,187,1) 0%,rgba(119,119,119,1) 99%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bbbbbb', endColorstr='#777777',GradientType=0 );
	box-shadow: inset 0 -5px 15px rgba(255,255,255,0.4), 
				inset -2px -1px 40px rgba(0,0,0,0.4), 
				0 0 1px #000;	
	position: absolute;
	top: 0;	
	z-index: 11;
	-webkit-animation: jump 1s infinite;
	-moz-animation: jump 1s infinite;
	-o-animation: jump 1s infinite;
	-ms-animation: jump 1s infinite;
	animation: jump 1s infinite;
	cursor: pointer;
}

#ball::after {
	content: "";
	width: 80px; 
	height: 40px; 
	position: absolute;
	left: 30px;
	top: 10px;
	z-index: 10;
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U4ZThlOCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjElIiBzdG9wLWNvbG9yPSIjZThlOGU4IiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
	background: -moz-linear-gradient(top,  rgba(232,232,232,1) 0%, rgba(232,232,232,1) 1%, rgba(255,255,255,0) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(232,232,232,1)), color-stop(1%,rgba(232,232,232,1)), color-stop(100%,rgba(255,255,255,0)));
	background: -webkit-linear-gradient(top,  rgba(232,232,232,1) 0%,rgba(232,232,232,1) 1%,rgba(255,255,255,0) 100%);
	background: -o-linear-gradient(top,  rgba(232,232,232,1) 0%,rgba(232,232,232,1) 1%,rgba(255,255,255,0) 100%);
	background: -ms-linear-gradient(top,  rgba(232,232,232,1) 0%,rgba(232,232,232,1) 1%,rgba(255,255,255,0) 100%);
	background: linear-gradient(top,  rgba(232,232,232,1) 0%,rgba(232,232,232,1) 1%,rgba(255,255,255,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e8e8e8', endColorstr='#00ffffff',GradientType=0 );
	border-radius: 40px / 20px;	
}

#ballShadow {
	position: absolute;
	left: 50%;
	bottom: 0;
	z-index: 10;
	margin-left: -30px;
	width: 60px;
	height: 75px;
	background: rgba(20, 20, 20, .1);
	box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
	border-radius: 30px / 40px;	
	-webkit-transform: scaleY(.3);
	-moz-transform: scaleY(.3);
	-ms-transform: scaleY(.3);
	-o-transform: scaleY(.3);
	transform: scaleY(.3);
	-webkit-animation: shrink 1s infinite;
	-moz-animation: shrink 1s infinite;
	-o-animation: shrink 1s infinite;
	-ms-animation: shrink 1s infinite;
	animation: shrink 1s infinite;
}

/* Animations */

@-webkit-keyframes jump {
	0% {top: 0;
		-webkit-animation-timing-function: ease-in;
	}
	40% {}
	50% {top: 140px;
		height: 140px;
		-webkit-animation-timing-function: ease-out;
	}
	55% {top: 160px; height: 120px; border-radius: 70px / 60px;
		-webkit-animation-timing-function: ease-in;}
	65% {top: 120px; height: 140px; border-radius: 70px;
		-webkit-animation-timing-function: ease-out;}
	95% {
		top: 0;		
		-webkit-animation-timing-function: ease-in;
	}
	100% {top: 0;
		-webkit-animation-timing-function: ease-in;
	}
}

@-moz-keyframes jump {
	0% {top: 0;
		-moz-animation-timing-function: ease-in;
	}
	40% {}
	50% {top: 140px;
		height: 140px;
		-moz-animation-timing-function: ease-out;
	}
	55% {top: 160px; height: 120px; border-radius: 70px / 60px;
		-moz-animation-timing-function: ease-in;}
	65% {top: 120px; height: 140px; border-radius: 70px;
		-moz-animation-timing-function: ease-out;}
	95% {
		top: 0;		
		-moz-animation-timing-function: ease-in;
	}
	100% {top: 0;
		-moz-animation-timing-function: ease-in;
	}
}

@-o-keyframes jump {
	0% {top: 0;
		-o-animation-timing-function: ease-in;
	}
	40% {}
	50% {top: 140px;
		height: 140px;
		-o-animation-timing-function: ease-out;
	}
	55% {top: 160px; height: 120px; border-radius: 70px / 60px;
		-o-animation-timing-function: ease-in;}
	65% {top: 120px; height: 140px; border-radius: 70px;
		-o-animation-timing-function: ease-out;}
	95% {
		top: 0;		
		-o-animation-timing-function: ease-in;
	}
	100% {top: 0;
		-o-animation-timing-function: ease-in;
	}
}

@-ms-keyframes jump {
	0% {top: 0;
		-ms-animation-timing-function: ease-in;
	}
	40% {}
	50% {top: 140px;
		height: 140px;
		-ms-animation-timing-function: ease-out;
	}
	55% {top: 160px; height: 120px; border-radius: 70px / 60px;
		-ms-animation-timing-function: ease-in;}
	65% {top: 120px; height: 140px; border-radius: 70px;
		-ms-animation-timing-function: ease-out;}
	95% {
		top: 0;		
		-ms-animation-timing-function: ease-in;
	}
	100% {top: 0;
		-ms-animation-timing-function: ease-in;
	}
}

@keyframes jump {
	0% {
		top: 0;
		animation-timing-function: ease-in;
	}
	50% {
		top: 140px;
		height: 140px;
		animation-timing-function: ease-out;
	}
	55% {
		top: 160px; 
		height: 120px; 
		border-radius: 70px / 60px;
		animation-timing-function: ease-in;
	}
	65% {
		top: 120px; 
		height: 140px; 
		border-radius: 70px;
		animation-timing-function: ease-out;
	}
	95% {
		top: 0;		
		animation-timing-function: ease-in;
	}
	100% {
		top: 0;
		animation-timing-function: ease-in;
	}
}



@-webkit-keyframes shrink {
	0% {
		bottom: 0;
		margin-left: -30px;
		width: 60px;
		height: 75px;
		background: rgba(20, 20, 20, .1);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
		border-radius: 30px / 40px;
		-webkit-animation-timing-function: ease-in;
	}
	50% {
		bottom: 30px;
		margin-left: -10px;
		width: 20px;
		height: 5px;
		background: rgba(20, 20, 20, .3);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.3);
		border-radius: 20px / 20px;
		-webkit-animation-timing-function: ease-out;
	}
	100% {
		bottom: 0;
		margin-left: -30px;
		width: 60px;
		height: 75px;
		background: rgba(20, 20, 20, .1);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
		border-radius: 30px / 40px;
		-webkit-animation-timing-function: ease-in;
	}
}

@-moz-keyframes shrink {
	0% {
		bottom: 0;
		margin-left: -30px;
		width: 60px;
		height: 75px;
		background: rgba(20, 20, 20, .1);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
		border-radius: 30px / 40px;
		-moz-animation-timing-function: ease-in;
	}
	50% {
		bottom: 30px;
		margin-left: -10px;
		width: 20px;
		height: 5px;
		background: rgba(20, 20, 20, .3);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.3);
		border-radius: 20px / 20px;
		-moz-animation-timing-function: ease-out;
	}
	100% {
		bottom: 0;
		margin-left: -30px;
		width: 60px;
		height: 75px;
		background: rgba(20, 20, 20, .1);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
		border-radius: 30px / 40px;
		-moz-animation-timing-function: ease-in;
	}
}

@-o-keyframes shrink {
	0% {
		bottom: 0;
		margin-left: -30px;
		width: 60px;
		height: 75px;
		background: rgba(20, 20, 20, .1);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
		border-radius: 30px / 40px;
		-o-animation-timing-function: ease-in;
	}
	50% {
		bottom: 30px;
		margin-left: -10px;
		width: 20px;
		height: 5px;
		background: rgba(20, 20, 20, .3);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.3);
		border-radius: 20px / 20px;
		-o-animation-timing-function: ease-out;
	}
	100% {
		bottom: 0;
		margin-left: -30px;
		width: 60px;
		height: 75px;
		background: rgba(20, 20, 20, .1);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
		border-radius: 30px / 40px;
		-o-animation-timing-function: ease-in;
	}
}

@-ms-keyframes shrink {
	0% {
		bottom: 0;
		margin-left: -30px;
		width: 60px;
		height: 75px;
		background: rgba(20, 20, 20, .1);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
		border-radius: 30px / 40px;
		-ms-animation-timing-function: ease-in;
	}
	50% {
		bottom: 30px;
		margin-left: -10px;
		width: 20px;
		height: 5px;
		background: rgba(20, 20, 20, .3);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.3);
		border-radius: 20px / 20px;
		-ms-animation-timing-function: ease-out;
	}
	100% {
		bottom: 0;
		margin-left: -30px;
		width: 60px;
		height: 75px;
		background: rgba(20, 20, 20, .1);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
		border-radius: 30px / 40px;
		-ms-animation-timing-function: ease-in;
	}
}

@keyframes shrink {
	0% {
		bottom: 0;
		margin-left: -30px;
		width: 60px;
		height: 75px;
		background: rgba(20, 20, 20, .1);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
		border-radius: 30px / 40px;
		animation-timing-function: ease-in;
	}
	50% {
		bottom: 30px;
		margin-left: -10px;
		width: 20px;
		height: 5px;
		background: rgba(20, 20, 20, .3);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.3);
		border-radius: 20px / 20px;
		animation-timing-function: ease-out;
	}
	100% {
		bottom: 0;
		margin-left: -30px;
		width: 60px;
		height: 75px;
		background: rgba(20, 20, 20, .1);
		box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
		border-radius: 30px / 40px;
		animation-timing-function: ease-in;
	}
}




</style>
