<template>
  <div :class="['nav-header', 'vuecal__flex', {'show-nav-bt': clearButtons}]">
      <div class="nav-buttons">
            <slot name="navButtons"></slot>
      </div>
      <div :class="[{'show-nav': showNav}, 'nav-date']">
            <slot name="navDate"></slot>
      </div>
      <div class="nav-view">
            <slot name="navView"></slot>
      </div>
       <div :class="[{'show-nav': showNav}, 'nav-log']">
            <slot name="navLog"></slot>
      </div>
  </div>
</template>

<script>
export default {
      props: {
               showNav: Boolean,
               clearButtons: Boolean
      },
      methods: {
            
      }
}
</script>

<style >

/* Górny header */

.nav-header {
  width: 100%;
  background-color: #efefef;
  min-height: 50px;
  margin-bottom: 10px;
  align-items: center;
}

.nav-header .nav-buttons {
  width: 240px;
  padding-left: 16px;
  text-align: left;
  display: flex;
  
}

body.mobile .nav-header .nav-buttons {
  width: unset;
}

/* .nav-header.show-nav-bt .nav-buttons {
  display: none;
} */

.nav-header .nav-buttons .angle {
  display: inline-block;
  border: solid currentColor;
  border-width: 0 2px 2px 0;
  padding: 0.25em;
  transform: rotate(-45deg);
}

.nav-header .nav-buttons .angle.prev {
  border-width: 2px 0 0 2px;
}

.nav-header .nav-date {
  text-align: left;
  padding-left: 16px;
  font-family: 'Anton-Regular';
  font-size: 18px;
  color: #777;

}


.nav-header .show-nav.nav-date {
  -webkit-animation: tracking-in-expand 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	animation: tracking-in-expand 0.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}



@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.nav-header.show-nav-bt .nav-date {
  display: none;
}

.nav-header .nav-view {
  flex-grow: 2;
  padding-right: 18px;
  text-align: right;
}

.nav-header.show-nav-bt .nav-view {
  text-align: center;
  padding-right: 264px;
}

body.mobile .nav-header.show-nav-bt .nav-view {
    text-align: center;
    padding-right: 24px;
}

.nav-header.show-nav-bt .nav-view h3 {
  margin: 0;
}


.nav-header .nav-log {
  padding-right: 16px;
  text-align: right;
  margin-right: -65px;
}

body.mobile .nav-header .nav-log {
  margin-right: 0px;
}

.nav-header .nav-log.show-nav {
  -webkit-animation: slide-in-nav 0.6s ease-out both;
	animation: slide-in-nav 0.6s ease-out both;
}


@-webkit-keyframes slide-in-nav {
  0% {
    margin-right: -65px; 
    opacity: 0.5;
  }
  100% {
    margin-right: 0px; 
    opacity: 1;
  }
}
@keyframes slide-in-nav {
  0% {
    margin-right: -65px; 
    opacity: 0.5;
  }
  100% {
    margin-right: 0px; 
    opacity: 1;
  }
}


 .dark-mode .nav-header {
   background-color: #1c2630;
   color:#e1e1e1;
 }

</style>