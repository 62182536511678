<template>
    <Loader :loader="loader" />
   
    <div class="inner-wrap2">
      <!-- <p style="font-size: 28px; font-family: 'Anton-Regular';">coming soon</p> -->
     <h3>Annual quota summary</h3>
      <div class="criteria">
        <div class="control-wrap">
            <span>Year: &nbsp;</span>
              <vue-select 
                v-model="year" 
                :options="years" 
                label-by="value" 
                value-by="no" 
                :blur="onDateChange"
                close-on-select />


            <span>Pagination: </span>
            <label class="rad-label">
                  <input type="checkbox" class="rad-input" name="pagination" v-model="pagination" />
                  <div class="rad-design"></div>
          </label>
        </div>
        
        <div class="control-wrap excel">
            <vue-excel-xlsx class="d-btn ex-btn"
            :data="tableRows"
            :columns="tableColumns"
            :file-name="filename"
            :file-type="'xlsx'"
            :sheet-name="'AnnualQuotaSummary'"
            :disabled="tableRows == null"
            title="export to Excel"
            >
            <i class="fas fa-file-excel"></i>
          </vue-excel-xlsx>
        </div>
      </div>
      <div class="radio-type">
            <ul class="answer-list">
                  <li>
                        <label class="rad-label">
                          <input type="radio" class="rad-input" name="name1" value="1" v-model="rapType" :checked="rapType == 1" @change="onChangeType($event)" />
                          <div class="rad-design"></div>
                          <div class="rad-text">Jobs</div>
                        </label>                        
                  </li>
                  <li>
                        <label class="rad-label">
                          <input type="radio" class="rad-input" name="name2" value="2" v-model="rapType" :checked="rapType == 2" @change="onChangeType($event)" />
                          <div class="rad-design"></div>
                          <div class="rad-text">Projects</div>
                        </label>                        
                  </li>
                  <li>
                        <label class="rad-label">
                          <input type="radio" class="rad-input" name="name3" value="3" v-model="rapType" :checked="rapType == 3" @change="onChangeType($event)" />
                          <div class="rad-design"></div>
                          <div class="rad-text">Teams</div>
                        </label>                        
                  </li>
            </ul>
        </div>

      <div class="center-pan">

        <vue-good-table
            :columns="tableColumns"
            :rows="tableRows"
            :row-style-class="rowStyleClass"
            :line-numbers="false"
            :search-options="{enabled: true}"
            :sort-options="{enabled: true}"  
            :pagination-options="{
              enabled: pagination,
              mode: 'records'
            }" 
                          
          >
            <template v-slot:emptystate>
                No data
            </template>
            
          </vue-good-table>
      </div>
        

    </div>
 
  
</template>

<script>
import shared from "@/shared";
import moment from "moment";
import reportService from "@/services/reports.js";
import Loader from '../../components/Loader.vue';
// import { DoughnutChart } from 'vue-chart-3';
// import { BarChart } from 'vue-chart-3';
// import { Chart, registerables } from "chart.js";

//Chart.register(...registerables);

export default {
  components: {
      Loader, 
      //DoughnutChart, 
      //BarChart 
  },
  name: "App",
  
  

  data: function () {
    return {
      year: 2022,
      agencyId: this.$store.getters.userData.agencyId,
      showNav: true,
      error: null,
      loader: false,
      pagination: true,
      rapType: 1, // 1 - joby, 2 - projekty, 3 - dyrektorzy
      filename: 'filename',
      years: [],
      tableColumns: [],
      tableRows: [],
      table1columns: [
        {
          label: 'Client Name',
          field: 'clientName',
          tdClass: 'text-f12'
        },
        {
          label: 'Job No',
          field: 'jobNo',
          tdClass: 'text-f14'
        },
        {
          label: 'Job Name',
          field: 'jobName',
          tdClass: 'text-f12'
        },  
        { label: 'SFM Cost [pln]', field: 'sfmCost_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'SFM Income [pln]', field: 'sfmInvoice_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries [h]', field: 'entries_h', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries PC [pln]', field: 'entriesAmount_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries PC-30 [pln]', field: 'entriesAmount30_pln', type: 'number', tdClass: 'text-f14'}
      ],
      table1rows: [],
      table2columns: [
        {
          label: 'Client Name',
          field: 'clientName',
          tdClass: 'text-f12'
        },
        {
          label: 'Project Name',
          field: 'projectName',
          tdClass: 'text-f12'
        },  
        { label: 'SFM Cost [pln]', field: 'sfmCost_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'SFM Income [pln]', field: 'sfmInvoice_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries [h]', field: 'entries_h', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries PC [pln]', field: 'entriesAmount_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries PC-30 [pln]', field: 'entriesAmount30_pln', type: 'number', tdClass: 'text-f14'}
      ],
      table2rows: [],
      table3columns: [
        {
          label: 'Team',
          field: 'teamName',
          tdClass: 'text-f12'
        },
        {
          label: 'Client Name',
          field: 'clientName',
          tdClass: 'text-f12'
        }, 
        {
          label: 'Job No',
          field: 'jobNo',
          tdClass: 'text-f14'
        },  
        {
          label: 'Job Name',
          field: 'jobName',
          tdClass: 'text-f12'
        }, 
        { label: 'SFM Cost [pln]', field: 'sfmCost_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'SFM Income [pln]', field: 'sfmInvoice_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Revenue [pln]', field: 'reve_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries [h]', field: 'entries_h', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries PC [pln]', field: 'entriesAmount_pln', type: 'number', tdClass: 'text-f14'},
        { label: 'Entries PC-30 [pln]', field: 'entriesAmount30_pln', type: 'number', tdClass: 'text-f14'}
      ],
      table3rows: [],
      
    };
  },

  created() {
    this.yearList = shared.yearList;

    this.years = this.yearList();
    this.year = this.$store.getters.getCurrYear;

    // console.log('years');
    // console.log(this.years);
    // console.log(this.year);

  },
 
  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    //this.yearList = shared.yearList;
    this.CookieCheckGoToLogin(); 
    
    this.uFnCheck = shared.uFnCheck;
    if (!this.uFnCheck(8)) {
      this.CookieRemove();    
    }

    
    this.onDateChange();
   
  },
   
  methods: {

  
    onDateChange() {

      console.log('data change');

      this.loader = true;
      Promise.all([reportService.rap7Jobs(this.year, 0, this.agencyId), reportService.rap7Projects(this.year, 0, this.agencyId), reportService.rap8(this.year, 0, this.agencyId)])
      .then(([rapJobs, rapProjects, rapDirectors]) => {
        
        if (rapJobs.status === 200) {

          this.table1rows = rapJobs.data; 
            // console.log("----rapJobs----");
            // console.log(this.table1rows);      
        }

        if (rapProjects.status === 200) {

          this.table2rows = rapProjects.data;
        }

        if (rapDirectors.status === 200) {

          this.table3rows = rapDirectors.data;
          // console.log("----rapDirectors----");
          //   console.log(this.table3rows);      
        }
      })
      .catch((e) => {
        console.log("----error----");
        console.dir(e);

        this.error = e.message || "Something went wrong!";

        if (e.responses[0].status === 401) {
            this.CookieRemove();
        }
      })
      .finally(() => {
               
        this.onChangeType();
        this.loader = false;
      });

      

    },

    rowStyleClass(row) {

      return row.name == '-Total-' ? 'cl-total' : (row.status == 'Inactive' ? 'cl-row-inactive' : 'cl-row');
    },

    onChangeType() {
        
          console.log('rapType');
          console.log(this.rapType);

      let prefix = '';

      if (this.rapType == 1) {
            this.tableColumns = this.table1columns;
            this.tableRows = this.table1rows;
            prefix = 'Amounts-Jobs';
      } 
      else if (this.rapType == 2) {
            this.tableColumns = this.table2columns;
            this.tableRows = this.table2rows;
            prefix = 'Amounts-Projects';
      }
      else {
            this.tableColumns = this.table3columns;
            this.tableRows = this.table3rows;
            prefix = 'Amounts-Directors';
      }

      this.filename = ''+prefix+'-year-'+this.year+'-'+moment().format("YYMMDD.HHmmss");
      
        // console.log(this.tableColumns);
        // console.log(this.tableRows);

        
    }

  },
  computed: {
    
  }

}
</script>

<style >

.inner-wrap2 {
  text-align: center;

}

.inner-wrap2 h3 {
  font-family: 'Anton-Regular';
  font-size: 22px;
  letter-spacing: 2px;
}

.inner-wrap2 .dp__main {
  width: 260px;
}


.inner-wrap2 .criteria {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 26px;
}


.inner-wrap2 .criteria span { 
  margin-left: 10px; 
  }

.inner-wrap2 .vue-select {
  border-radius:initial;
  border-radius: 4px;
  z-index: 2;
  border: 1px solid #e3e3e3;
  background-color: white;
}

.inner-wrap2 .vue-input {
  padding: 10px 6px 10px 6px;
}
.inner-wrap2 .vue-input input {
  background-color: transparent;
  font-size: 16px;
}

.inner-wrap2 .vue-dropdown {
  border: 1px solid #e3e3e3;
}

.inner-wrap2 .vue-input .icon.arrow-downward  {
  border-width: 8px 5px 0;
}

.inner-wrap2 .vue-dropdown-item.highlighted {
    background-color: #fff8e3;
}

.inner-wrap2 .vue-dropdown-item.selected.highlighted {
    background-color: #fceec2;
}

/* .dialog-box.edit .vue-select {
  width: 100%;

} */


.inner-wrap2 .center-pan {
    box-shadow: 0px 3px 16px -5px rgb(120 122 123);
    position: relative;
    background-color: white;
    margin: 10px;
    margin-left: 20px;
    padding: 18px;
    min-height: 380px;
   
}

.inner-wrap2 .text-f14 {
    font-size: 14px;
}

.inner-wrap2 .text-f14-b {
    font-size: 14px;
    font-weight: bold;
}

.inner-wrap2 table.vgt-table {
  font-size: 14px;
}

.inner-wrap2 table.vgt-table .cl-total {
  background-color: #fafaf1;
}

.inner-wrap2 table.vgt-table .cl-row-inactive td span {
  color: #d1d1d1 !important;
}



.inner-wrap2 .vgt-wrap__footer {
  padding: 10px 16px;
}

.inner-wrap2 .vgt-wrap__footer .footer__navigation, 
.inner-wrap2 .vgt-wrap__footer .footer__row-count__label,
.inner-wrap2 .vgt-wrap__footer .footer__navigation__page-btn span {
  font-size: 14px;
}


.control-wrap {
  display: flex;
  justify-content:flex-end;
  flex-grow: 1;
  align-items: center;
}


.control-wrap .rad-label {
    padding: 0px 0 0 6px;
}

.control-wrap.excel {
  padding-right: 5px;
}

.control-wrap.excel .d-btn {
  width: auto;
  padding: 7px 18px;
  font-size: 18px;
}

.radio-type {
  margin-top: -12px;
}

.radio-type ul {
  list-style-type: none;
  margin-right: 84px;
}

.radio-type ul li {
  display: inline-block;
}


.dark-mode .inner-wrap2 table.vgt-table .cl-row-inactive td span {
  color: #808080 !important;
}


</style>