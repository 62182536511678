<template>
    <div
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
      class="swipe-area"
    >
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        startX: 0,
        startY: 0,
        distX: 0,
        distY: 0,
        startTime: 0,
        threshold: 50, // Minimalna odległość w pikselach, aby uznać ruch za "swipe"
        restraint: 100, // Maksymalna dopuszczalna odległość w przeciwnym kierunku
        allowedTime: 300, // Maksymalny czas trwania gestu
      };
    },
    methods: {
      handleTouchStart(e) {
        const touchObj = e.changedTouches[0];
        this.startX = touchObj.pageX;
        this.startY = touchObj.pageY;
        this.startTime = new Date().getTime();
      },
      handleTouchMove(e) {
        console.log('-e-');
        console.log(e);

      },
      handleTouchEnd(e) {
        const touchObj = e.changedTouches[0];
        this.distX = touchObj.pageX - this.startX;
        this.distY = touchObj.pageY - this.startY;
        const elapsedTime = new Date().getTime() - this.startTime;
  
        if (elapsedTime <= this.allowedTime) {
          if (
            Math.abs(this.distX) >= this.threshold &&
            Math.abs(this.distY) <= this.restraint
          ) {
            if (this.distX > 0) {
              this.$emit('swipe-right');
              console.log('Swipe w prawo');
            } else {
              this.$emit('swipe-left');
              console.log('Swipe w lewo');
            }
          }
        }
      },
    },
  };
  </script>
  
  <style>
  .swipe-area {
    /* Dodaj style dla obszaru, który będzie nasłuchiwał gestów */
    width: 100%;
    height: 100%;
  }
  </style>
  