<template>
 <div>Mobile - Report</div>
</template>

<script>
import shared from "@/shared";
//import calendar from "../components/Calendar.vue";

export default {
  
  created() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieCheckGoToLogin();
  },
};
</script>

<style>

</style>