import axios from 'axios';
import store from "../store";

const API_URL = store.getters.getApiUrl;


export default {

      async projectList() {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "project/list", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
     
      async projectInfo(id) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "project/info/" + id, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async projectjobList(id) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "project-job/list/" + id, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },


      async projectjobInvoice(id) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "project-job/invoice/" + id, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async projectjobCost(id) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "project-job/cost/" + id, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      
      async projectEdit(entry) {
            entry.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.put(API_URL + "project/edit", entry, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },


      async jobInvAdd(invData) {
            invData.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "project-job/invoice", invData, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async jobInvDel(invData) {
            invData.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.delete(API_URL + "project-job/invoice", {headers: headers, data: invData});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async projectjobTShours(id) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "project-job/hours/" + id, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async projectJobAdd(entry) {
            entry.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "project/job", entry, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async projectJobRemove(entry) {
            entry.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.delete(API_URL + "project/job", {headers: headers, data: entry});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async projectRap1(projectId) {

            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "project/rap1/" + projectId, {}, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async projectRap2(projectId) {

            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "project/rap2/" + projectId, {}, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async projectRap3(projectId) {

            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "project/rap3/" + projectId, {}, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async projectRap4(projectId) {

            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "project/rap4/" + projectId, {}, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async uniRap(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "project/report", data, {headers});
            return {
                  'status': response.status,
                  'data': JSON.parse(response.data)
            };
      },

     
     
}