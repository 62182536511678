<template>
  <button :class="mode">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: "/",
    },
    mode: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style scoped>
button,
a {
  text-decoration: none;
  
  font: inherit;
  background-color: #fff8e3;
  border: 1px solid #ffdb6e;
  cursor: pointer;
  padding: 5px 15px;
  margin: 10px 5px;
  min-width: 80px;
  display: inline-block;
}

a:hover,
a:active,
button:hover,
button:active {
 background-color: #faecc3;
}

.flat {
  background-color: transparent;
  color: black;
  border: none;
}

.outline {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.1);
  color: black;
}

.flat:hover,
.flat:active,
.outline:hover,
.outline:active {
  background-color: #edd2ff;
}
</style>
