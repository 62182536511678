<template>
  <calendar></calendar>
</template>

<script>
import shared from "@/shared";
import calendar from "../components/Calendar.vue";

export default {
  components: { calendar },
  created() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieCheckGoToLogin();
  },
};
</script>

<style>

</style>