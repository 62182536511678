<template>
      <teleport to="body">
            <div v-if="loader" class="loader">
                  <div class="lds-hourglass"></div>
            </div>
      </teleport>  
</template>

<script>
export default {
  props: {
    loader: {
      type: Boolean,
      required: true
    }

  },
  
};
</script>

<style scoped>

      .loader {
            position: fixed;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-color: white;
            opacity: 0.6;
            z-index: 999;
      }

      .lds-hourglass {
            display: inline-block;

            width: 80px;
            height: 80px;
            z-index: 999;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
      }
      .lds-hourglass:after {
            content: " ";
            display: block;
            border-radius: 50%;
            width: 0;
            height: 0;
            margin: 8px;
            box-sizing: border-box;
            border: 32px solid black;
            border-color: black transparent black transparent;
            animation: lds-hourglass 1.2s infinite;
      }
      @keyframes lds-hourglass {
            0% {
                  transform: rotate(0);
                  animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            }
            50% {
                  transform: rotate(900deg);
                  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            }
            100% {
                  transform: rotate(1800deg);
            }
      }

      .dark-mode .loader {            
            background-color: #454545;           
      }

</style>