<template>
    <Loader :loader="loader" />
   
    <div class="inner-wrap2 w-fin">

      <h3>Agencies by month</h3>
      <div class="criteria r-abm">
        <div class="control-wrap r-fin">
            <span>Year: &nbsp;</span>
              <vue-select 
                v-model="year" 
                :options="years" 
                label-by="value" 
                value-by="no" 
                :blur="onDateChange"
                close-on-select />


            <span>Quarterly: </span>
            <label class="rad-label">
                  <input type="checkbox" class="rad-input" name="quarterly" v-model="quarterly" @change="onDateChange()" />
                  <div class="rad-design"></div>
            </label>
           
        </div>
        
        <div class="control-wrap r-fin excel">
            <vue-excel-xlsx class="d-btn ex-btn"
            :data="itemsEx"
            :columns="headersEx"
            :file-name="filename"
            :file-type="'xlsx'"
            :sheet-name="'Agency Hours'"
            :disabled="itemsEx == null"
            title="export to Excel"
            >
            <i class="fas fa-file-excel"></i>
          </vue-excel-xlsx>
        </div>

        <div class="radio-type">
            <ul class="answer-list">
                  <li>
                        <label class="rad-label">
                          <input type="radio" class="rad-input" name="name1" value="1" v-model="rapType" :checked="rapType == 1" @change="onChangeType($event)" />
                          <div class="rad-design"></div>
                          <div class="rad-text">Hours</div>
                        </label>                        
                  </li>
                  <li>
                        <label class="rad-label">
                          <input type="radio" class="rad-input" name="name2" value="2" v-model="rapType" :checked="rapType == 2" @change="onChangeType($event)" />
                          <div class="rad-design"></div>
                          <div class="rad-text">Users</div>
                        </label>                        
                  </li>
                 
            </ul>
        </div>
      </div>
            

      <div class="center-pan">
         
        <div v-if="!quarterly" class="m-view">
          <vue-good-table
            :columns="this.rapType == 1 ? headersM : headersMu"
            :rows="itemsM"
            row-style-class="rowStyleClass"
            :line-numbers="false"
            :search-options="{enabled: true}"
            :sort-options="{enabled: true}"  
            :pagination-options="{
              enabled: false,
              mode: 'records'
            }" 
                          
          >
            <template v-slot:emptystate>
                No data
            </template>

            <template #table-column="props">
              <div v-if="this.rapType == 1 ? regMonth(props.column.field) : regUsr(props.column.field)">
                <div class="abs-m">
                  {{props.column.label2}} 
                </div>
                <div>
                  {{props.column.labelT}}
                </div>
              </div>
             
              <div v-else>
                  {{props.column.labelT}}
              </div>
            </template>
            
          </vue-good-table>

        </div>
        <div v-else class="q-view">
          <vue-good-table
            :columns="this.rapType == 1 ? headersQ : headersQu"
            :rows="itemsQ"
            row-style-class="rowStyleClass"
            :line-numbers="false"
            :search-options="{enabled: true}"
            :sort-options="{enabled: true}"  
            :pagination-options="{
              enabled: false,
              mode: 'records'
            }" 
                          
          >
            <template v-slot:emptystate>
                No data
            </template>

            <template #table-column="props">
              <div v-if="this.rapType == 1 ? regMonth(props.column.field) : regUsr(props.column.field)">
                <div class="abs-m">
                  {{props.column.label2}} 
                </div>
                <div>
                  {{props.column.labelT}}
                </div>
              </div>
             
              <div v-else>
                  {{props.column.labelT}}
              </div>
            </template>
            
          </vue-good-table>

        </div>
        
      </div>
        

    </div>
 
  
</template>

<script>
import shared from "@/shared";
import moment from "moment";
//import reportService from "@/services/reports.js";
import projectService from "@/services/project.js";
import Loader from '../../components/Loader.vue';
// import { DoughnutChart } from 'vue-chart-3';
// import { BarChart } from 'vue-chart-3';
// import { Chart, registerables } from "chart.js";

//Chart.register(...registerables);

export default {
  components: {
      Loader, 
      //DoughnutChart, 
      //BarChart 
  },
  name: "App",
  
  

  data: function () {
    return {
      year: 2022,
      showNav: true,
      error: null,
      loader: false,
      rapType: 1,
      quarterly: false,
      filename: 'filename',
      years: [],
      headersM: [
                        { label2: "Agency", labelT: "Agency", label: "Agency", field: "AgencyName", tdClass: 'text-f14' },
                        { label2: "Users", labelT: "Users", label: "Users", field: "AgencyTotalUsers", tdClass: 'text-f14' },
                        { label2: "Jan", labelT: "Hrs", label: "Jan-Hrs", field: "m1h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Jan", labelT: "[%]", label: "Jan-[%]", field: "m1pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm1pr') },
                        { label2: "Feb", labelT: "Hrs", label: "Feb-Hrs", field: "m2h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Feb", labelT: "[%]", label: "Feb-[%]", field: "m2pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm2pr') },
                        { label2: "Mar", labelT: "Hrs", label: "Mar-Hrs", field: "m3h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Mar", labelT: "[%]", label: "Mar-[%]", field: "m3pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm3pr') },
                        { label2: "Apr", labelT: "Hrs", label: "Apr-Hrs", field: "m4h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Apr", labelT: "[%]", label: "Apr-[%]", field: "m4pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm4pr') },
                        { label2: "May", labelT: "Hrs", label: "May-Hrs", field: "m5h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "May", labelT: "[%]", label: "May-[%]", field: "m5pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm5pr') },
                        { label2: "Jun", labelT: "Hrs", label: "Jun-Hrs", field: "m6h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Jun", labelT: "[%]", label: "Jun-[%]", field: "m6pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm6pr') },
                        { label2: "Jul", labelT: "Hrs", label: "Jul-Hrs", field: "m7h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Jul", labelT: "[%]", label: "Jul-[%]", field: "m7pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm7pr') },
                        { label2: "Aug", labelT: "Hrs", label: "Aug-Hrs", field: "m8h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Aug", labelT: "[%]", label: "Aug-[%]", field: "m8pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm8pr') },
                        { label2: "Sep", labelT: "Hrs", label: "Sep-Hrs", field: "m9h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Sep", labelT: "[%]", label: "Sep-[%]", field: "m9pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm9pr') },
                        { label2: "Oct", labelT: "Hrs", label: "Oct-Hrs", field: "m10h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Oct", labelT: "[%]", label: "Oct-[%]", field: "m10pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm10pr') },
                        { label2: "Nov", labelT: "Hrs", label: "Nov-Hrs", field: "m11h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Nov", labelT: "[%]", label: "Nov-[%]", field: "m11pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm11pr') },
                        { label2: "Dec", labelT: "Hrs", label: "Dec-Hrs", field: "m12h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Dec", labelT: "[%]", label: "Dec-[%]", field: "m12pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm12pr') }
                  ],
      headersMu: [
                        { label2: "Agency", labelT: "Agency", label: "Agency", field: "AgencyName", tdClass: 'text-f14' },
                        { label2: "Users", labelT: "Users", label: "Users", field: "AgencyTotalUsers", tdClass: 'text-f14' },
                        { label2: "Jan", labelT: "Usr", label: "Jan-Usr", field: "m1regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Jan", labelT: "[%]", label: "Jan-[%]", field: "m1regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm1regUpr') },
                        { label2: "Feb", labelT: "Usr", label: "Feb-Usr", field: "m2regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Feb", labelT: "[%]", label: "Feb-[%]", field: "m2regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm2regUpr') },
                        { label2: "Mar", labelT: "Usr", label: "Mar-Usr", field: "m3regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Mar", labelT: "[%]", label: "Mar-[%]", field: "m3regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm3regUpr') },
                        { label2: "Apr", labelT: "Usr", label: "Apr-Usr", field: "m4regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Apr", labelT: "[%]", label: "Apr-[%]", field: "m4regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm4regUpr') },
                        { label2: "May", labelT: "Usr", label: "May-Usr", field: "m5regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "May", labelT: "[%]", label: "May-[%]", field: "m5regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm5regUpr') },
                        { label2: "Jun", labelT: "Usr", label: "Jun-Usr", field: "m6regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Jun", labelT: "[%]", label: "Jun-[%]", field: "m6regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm6regUpr') },
                        { label2: "Jul", labelT: "Usr", label: "Jul-Usr", field: "m7regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Jul", labelT: "[%]", label: "Jul-[%]", field: "m7regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm7regUpr') },
                        { label2: "Aug", labelT: "Usr", label: "Aug-Usr", field: "m8regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Aug", labelT: "[%]", label: "Aug-[%]", field: "m8regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm8regUpr') },
                        { label2: "Sep", labelT: "Usr", label: "Sep-Usr", field: "m9regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Sep", labelT: "[%]", label: "Sep-[%]", field: "m9regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm9regUpr') },
                        { label2: "Oct", labelT: "Usr", label: "Oct-Usr", field: "m10regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Oct", labelT: "[%]", label: "Oct-[%]", field: "m10regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm10regUpr') },
                        { label2: "Nov", labelT: "Usr", label: "Nov-Usr", field: "m11regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Nov", labelT: "[%]", label: "Nov-[%]", field: "m11regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm11regUpr') },
                        { label2: "Dec", labelT: "Usr", label: "Dec-Usr", field: "m12regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Dec", labelT: "[%]", label: "Dec-[%]", field: "m12regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'm12regUpr') }
                  ],

      headersQ: [
                        { label2: "Agency", label: "Agency", field: "AgencyName", tdClass: 'text-f14' },
                        { label2: "Users", labelT: "Users", label: "Users", field: "AgencyTotalUsers", tdClass: 'text-f14' },
                        { label2: "Q-1", labelT: "Hrs", label: "Q1-Hrs", field: "q1h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-1", labelT: "[%]", label: "Q1-[%]", field: "q1pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'q1pr') },
                        { label2: "Q-2", labelT: "Hrs", label: "Q2-Hrs", field: "q2h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-2", labelT: "[%]", label: "Q2-[%]", field: "q2pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'q2pr') },
                        { label2: "Q-3", labelT: "Hrs", label: "Q3-Hrs", field: "q3h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-3", labelT: "[%]", label: "Q3-[%]", field: "q3pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'q3pr') },
                        { label2: "Q-4", labelT: "Hrs", label: "Q4-Hrs", field: "q4h", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-4", labelT: "[%]", label: "Q4-[%]", field: "q4pr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'q4pr') }
                       
                  ],
       headersQu: [
                        { label2: "Agency", label: "Agency", field: "AgencyName", tdClass: 'text-f14' },
                        { label2: "Users", labelT: "Users", label: "Users", field: "AgencyTotalUsers", tdClass: 'text-f14' },
                        { label2: "Q-1", labelT: "Usr", label: "Q1-Usr", field: "q1regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-1", labelT: "[%]", label: "Q1-[%]", field: "q1regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'q1regUpr') },
                        { label2: "Q-2", labelT: "Usr", label: "Q2-Usr", field: "q2regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-2", labelT: "[%]", label: "Q2-[%]", field: "q2regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'q2regUpr') },
                        { label2: "Q-3", labelT: "Usr", label: "Q3-Usr", field: "q3regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-3", labelT: "[%]", label: "Q3-[%]", field: "q3regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'q3regUpr') },
                        { label2: "Q-4", labelT: "Usr", label: "Q4-Usr", field: "q4regU", type: 'number', tdClass: 'text-f14', thClass: 'th-custom' },
                        { label2: "Q-4", labelT: "[%]", label: "Q4-[%]", field: "q4regUpr", type: 'number', tdClass: (row) => this.tdClassFn(row, 'q4regUpr') }
                       
                  ],
      itemsM: [],
      itemsQ: [],
      headersEx: [],
      itemsEx: [],
      
    };
  },

  created() {
    this.yearList = shared.yearList;
    this.uFnCheck = shared.uFnCheck;

    this.years = this.yearList();
    this.year = this.$store.getters.getCurrYear;


  },
 
  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    this.CookieCheckGoToLogin();   

    if (!this.uFnCheck(11)) {
      this.CookieRemove();    
    }

 
    this.setFileName();   

    setTimeout(() => {
            this.getData();
    }, 360);

   
   
  },
   
  methods: {

    regMonth(str) {
      const regex = /^[a-zA-Z]\d+[a-zA-Z]$/;
      return regex.test(str);
    },

    regUsr(str) {
      const regex = /^[a-zA-Z]\d+[a-zA-Z]{4}$/;
      return regex.test(str);
    },

    setFileName() {

      let prefix = this.rapType == 1 ? 'Agency-Hours' : 'Agency-Users';
      let type = this.quarterly ? 'Q' : 'M' ;          

      this.filename = ''+prefix+'-'+type+'-year-'+this.year+'-'+moment().format("YYMMDD.HHmmss");

    },

   
    getData() {

          let res = this.quarterly ? 'rapGlobalAgenciesQ' : 'rapGlobalAgenciesM';

          const data = {
                ResultName: res,
                Year: this.year
          };

          

          this.loader = true;
          projectService
          .uniRap(data)
          .then((response) => {

          if (response.status === 200) {

            
            // console.log("----data----");
            //  console.log(data); 
          
            if (this.quarterly) {
              this.itemsQ = response.data;

              
              this.headersEx = this.rapType == 1 ? this.headersQ : this.headersQu;
              this.itemsEx = this.itemsQ;


            }
            else {
              this.itemsM = response.data;

              
              this.headersEx = this.rapType == 1 ? this.headersM : this.headersMu;
              this.itemsEx = this.itemsM;
            }

            


          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";
          
          
          if (e.response.status === 401) {
                this.CookieRemove();
          }
          })
          .finally(() => {
          this.loader = false;
          });

    },

    onChangeType() {
        
      if (this.quarterly) {
          
          this.headersEx = this.rapType == 1 ? this.headersQ : this.headersQu;
        }
        else {
          
          this.headersEx = this.rapType == 1 ? this.headersM : this.headersMu;
        }
   


      

      this.setFileName();

        
    },

  
    onDateChange() {

      console.log('data change');

      this.setFileName();
      this.getData();   
      
     },


    tdClassFn(row, key) {
      if (row[key] == 0) {
        return 'red1-class';
      } else if (row[key] > 0 && row[key] < 20) {
        return 'red2-class';
      } else if (row[key] >= 20 && row[key] < 60) {
        return 'yellow-class';
      } else {
        return 'green-class';
      }
    },


  }

}
</script>
<style scoped>

  .inner-wrap2.w-fin .criteria .control-wrap.r-fin {
      justify-content: flex-end;
  }


  .inner-wrap2.w-fin .r-fin .vue-select, 
  .inner-wrap2.w-fin .r-fin .vue-select .vue-dropdown {
    z-index: 6;
  }


  

  .th-custom .abs-m {
      position: absolute;
      top: 0px;
      right: -12px;
      background-color: #F1F3F6;
      padding: 5px 2px 9px 2px;
      z-index: 2;
  }


  .inner-wrap2.w-fin .th-custom {
    padding-top: 30px;
    font-size: 13px;
  }

  
  .inner-wrap2.w-fin .vgt-table th.sortable button:before,
  .inner-wrap2.w-fin .vgt-table th.sortable button:after {
    right: 5px;
    top: 69%;
  }

  .r-abm.criteria {
    flex-wrap: wrap;
  }

  .r-abm .radio-type {
    width: 100%;
    margin-top: 2px;
  } 

  .dark-mode .th-custom .abs-m {

      background-color: #2c2f36;

  }

</style>

