<template>
    <Loader :loader="loader" />
   
    <div class="inner-wrap3">
      <!-- <p style="font-size: 28px; font-family: 'Anton-Regular';">coming soon</p> -->
     
     <h3>Job info</h3>
      <div :class="['criteria', uFnCheck(11) ? 'v-sa-a':'' ]">
        <div class="control-wrap">
          <span>Job number: &nbsp;</span>
          <vue-select  
            v-model="jobId" 
            :options="jobs" 
            label-by="jobNoName" 
            value-by="jobId"
            placeholder="select Job number" 
            searchable 
            clear-on-select 
            clear-on-close 
            close-on-select
            @update:modelValue="loadData"
            class="v-select" 
          >

              <template #dropdown-item="{ option }">
                <div :class="this.$store.getters.getCurrYear == option.jobYear ? 'y-active':'y-inactive'">{{ option.jobNoName }}</div>
              </template>

          </vue-select>
           
        </div>

        <div v-if="uFnCheck(11)" class="control-wrap agency-a">
              <span>Agency: &nbsp;</span>
              <vue-select 
              v-model="agencyId" 
              :options="agencies" 
              label-by="Description" 
              value-by="Id" 
              :blur="onDateChangeAgency"
              close-on-select />  
                                
        </div>  
      </div>

      <div class="center-pan">
        <div v-if="!jobId" class="c-no">
            Please, select Job number
        </div>  
        <div v-else>
          <div v-if="jobInfo.job[0]" class="general-info-wrap">
            <div class="general-left">
              
               <div class="info-item">
                <label>Job Number</label>
                <span :title="jobInfo.job[0].id" class="f-bold">{{jobInfo.job[0].jobNo}}</span>
              </div>
              <div class="info-item">
                <label>Client name</label>
                <span>{{jobInfo.job[0].clientName}}</span>
              </div>
               <div class="info-item">
                <label>Job name</label>
                <span class="f-bold">{{jobInfo.job[0].jobName}}</span>
              </div>
              <div class="info-item">
                <label>SFM registered User</label>
                <span>{{jobInfo.job[0].registeredUser}}</span>
              </div>
              
            </div>
            <div class="general-right">
              <div class="info-item">
                <label>First registered entry date</label>
                <span>{{jobInfo.job[0].dateFrom}}</span>
              </div>
              <div class="info-item">
                <label>Last registered entry date</label>
                <span>{{jobInfo.job[0].dateTo}}</span>
              </div>
              <div class="info-item">
                <label>SFM date from</label>
                <span>{{jobInfo.job[0].sfmDateFrom}}</span>
              </div>
              <div class="info-item">
                <label>SFM date to</label>
                <span>{{jobInfo.job[0].sfmDateTo}}</span>
              </div>              
            </div>
          </div>
          <div class="hour-info-wrap">

            <vue-good-table
            :columns="table1columns"
            :rows="jobInfo.hours"
            :row-style-class="rowStyleClass"
            :line-numbers="false"
            :search-options="{enabled: true}"
            :sort-options="{enabled: true}"  
            :pagination-options="{
              enabled: true,
              mode: 'records'
            }" 
                          
          >
            <template v-slot:emptystate>
                No data
            </template>
            
          </vue-good-table>

          </div>
          <div class="ex-wrap">
            <vue-excel-xlsx class="d-btn ex-btn"
              :data="table2rows"
              :columns="table2columns"
              :file-name="filename"
              :file-type="'xlsx'"
              :sheet-name="'HoursByDay'"
              :disabled="table2rows === null"
              title="export to Excel"
              >
              <i class="fas fa-file-excel"></i>
            </vue-excel-xlsx>
            <span> Hours by day</span>
          </div>
          <div class="chart-wrap">

            <BarChart 
              :chartData="barData"
              :options="barOptions"
              :height="350"
            />

          </div>
          

          
        </div>   
        
      </div>
        

    </div>
 
  
</template>

<script>
import shared from "@/shared";
import moment from "moment";
import reportService from "@/services/reports.js";
import jobsService from "@/services/jobs.js";
import projectsService from "@/services/project.js";
import Loader from '../../components/Loader.vue';
import adminService from "@/services/admin.js";
//import { DoughnutChart } from 'vue-chart-3';
import { BarChart } from 'vue-chart-3';
//import { Chart, registerables } from "chart.js";

//Chart.register(...registerables);

export default {
  components: {
      Loader, 
      //DoughnutChart, 
      BarChart 
  },
  name: "App",
  
  

  data: function () {
    return {      
      showNav: true,
      error: null,
      loader: false,  
      filename: 'filename',
      jobs: [], 
      jobId: null,
      agencyId: this.$store.getters.userData.agencyId, 
      agencies: [],
      agencyTeams: [],
      colorTable: [
        'rgba(33, 191, 117, 0.8)',
        'rgba(33, 167, 191, 0.8)',
        'rgba(33, 101, 191, 0.8)',
        'rgba(93, 53, 181, 0.8)',
        'rgba(153, 53, 181, 0.8)',
        'rgba(181, 53, 115, 0.8)',
        'rgba(99, 25, 25, 0.8)',
        'rgba(140, 98, 20, 0.8)',
        'rgba(168, 166, 20, 0.8)',
        'rgba(240, 237, 70, 0.8)',
        'rgba(138, 15, 95, 0.8)',
        'rgba(89, 6, 60, 0.8)',
        'rgba(247, 67, 103, 0.8)',
        'rgba(168, 8, 40, 0.8)',
        'rgba(28, 28, 145, 0.8)',
        'rgba(204, 132, 31, 0.8)',
        'rgba(247, 116, 22, 0.8)',
        'rgba(102, 76, 58, 0.8)'
      ],
      jobInfo: {hours: [],
                chart: [],
                job: []},
      table1columns: [
        {
          label: 'Team',
          field: 'team',
          tdClass: 'text-f14'
        },
        {
          label: 'Name',
          field: 'name',
          tdClass: 'text-f14'
        },
        {
          label: 'Hours',
          field: 'hours',
          type: 'number',
          tdClass: 'text-f14'
        }
      ],
      table2columns: [
        {
          label: 'Date',
          field: 'date',
          tdClass: 'text-f14'
        },
        {
          label: 'Team',
          field: 'team',
          tdClass: 'text-f14'
        },
        {
          label: 'Name',
          field: 'name',
          tdClass: 'text-f14'
        },
        {
          label: 'Hours',
          field: 'hours',
          type: 'number',
          tdClass: 'text-f14'
        }
      ],
      table2rows: [],
      barOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            display: true
          },
          title: {
            display: true,
            text: 'Hours in a week',
          },
        },
         scales: {
            x: {
              title: {
                display: true,
                text: 'Week'
              },
              grid: {
                color: '#545354'
              },
              ticks: {
                font: {
                  size: 10,
                }
              }
            },
            y: {
              title: {
                display: true,
                text: 'Hours'
              },
              grid: {
                color: '#545354'
              }            
            }
         }
      },
      barData: {
        labels: [],
        datasets: []
      },
      
    };
  },

  created() {
    
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    this.uFnCheck = shared.uFnCheck;
  },
 
  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    this.CookieCheckGoToLogin();

    
    if (!this.uFnCheck(9)) {
      this.CookieRemove();    
    }

      jobsService
      .jobsForAgency(this.agencyId)
      .then((response) => {

        if (response.status === 200) {
          this.jobs = response.data;          
        }
        
      })
      .catch((e) => {
        this.error = e.message || "Something went wrong!";
        
        
        if (e.response.status === 401) {
            this.CookieRemove();
        }
      })

      // this.loadData();

      this.filename = 'JobInfo-HoursByDay-'+moment().format("YYMMDD.HHmmss");

      this.agencyTeamsList();

      const data = {ResultName: "AgencyList"};
      adminService
            .uniRap(data)
            .then((response) => {

            if (response.status === 200) {                  
      
                  this.agencies = response.data;    

                  // console.log('agencies');
                  // console.log(this.agencies);

                  //this.agencies.unshift({Id: 0, Description: '-- All Agencies --'});

                    setTimeout(() => {                         
                        
                        this.agencyId = parseInt(this.$store.getters.userData.agencyId);
                        //this.dashboardData();
                  },480);
            }
            
            })
            .catch((e) => {
            this.error = e.message || "Something went wrong!";
            
            
            if (e.response.status === 401) {
                  this.CookieRemove();
            }
            });
        
  },
   
  methods: {

    onDateChangeAgency(){
      console.log('data change agency');
      this.jobId = null;

      this.agencyTeamsList();

      jobsService
      .jobsForAgency(this.agencyId)
      .then((response) => {

        if (response.status === 200) {
          this.jobs = response.data;          
        }
        
      })
      .catch((e) => {
        this.error = e.message || "Something went wrong!";
        
        
        if (e.response.status === 401) {
            this.CookieRemove();
        }
      })

       //this.onDateChange();
    },

    agencyTeamsList() {

        const data = {
                ResultName: "teamRapListAgency",
                AgencyId: this.agencyId
          };

        this.agencyTeams = [];

        console.log('agencyTeams-data');
                 console.log(data);  

          projectsService
          .uniRap(data)
          .then((response) => {

          if (response.status === 200) {
              
                //this.teamId = JSON.parse(response.data)[0].teamId;   
                this.agencyTeams = response.data;
                
                 console.log('agencyTeams');
                 console.log(this.agencyTeams);                

          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";        
          
          if (e.response.status === 401) {
                this.CookieRemove();
          }
          })

   
    },


    loadData() {
      console.log("-data-");
      //console.log(this.jobId);

      this.jobInfo = { hours: [],
                  chart: [],
                  job: []
                };
      
      this.barData.datasets = [];
      this.barData.labels = [];
      

      if (this.jobId != null) {

      
      
      
      this.loader = true;     
        reportService
        .rap6(this.jobId, this.agencyId)
        .then((response) => {

          if (response.status === 200) {
          this.jobInfo = response.data;

             console.log("----jobInfo----");
             console.log(this.jobInfo);

            
            let tmpCount = 0;
            
            this.barData.labels = this.jobInfo.charts.map(item =>  'wk: '+item["Week"]+' ('+item["dateFrom"]+' : '+item["dateTo"]+')' );


            console.log("----agencyTeams----");
             console.log(this.agencyTeams);

            this.agencyTeams.forEach(element => {

              this.barData.datasets.push(
                { label: element.projectGroup,
                  data: this.jobInfo.charts.map(item => item[element.shortRap]),
                  backgroundColor: this.colorTable[tmpCount],
                  borderRadius: 1,
                }
                );

              tmpCount++;

            });


            // console.log("----datasets----");
            // console.log(this.barData.datasets);


  
            // console.log("----labels----");
            // console.log(this.barData.labels);

            // this.jobInfo.charts.forEach(element => {
            //   this.barData.labels.push('wk: '+element.Week+' ('+element.dateFrom+' : '+element.dateTo+')');
            //   this.barData.datasets[0].data.push(element.strategy);
            //   this.barData.datasets[1].data.push(element.content);
            //   this.barData.datasets[2].data.push(element.communication_JL);
            //   this.barData.datasets[3].data.push(element.communication_MB);
            //   this.barData.datasets[4].data.push(element.communication_MP);
            //   this.barData.datasets[5].data.push(element.communication_MH);
            //   this.barData.datasets[6].data.push(element.creative);
            //   this.barData.datasets[7].data.push(element.production);
            //   this.barData.datasets[8].data.push(element.gaming);

            // });


          }
          
        })
        .catch((e) => {
          this.error = e.message || "Something went wrong!";
          
          
          if (e.response.status === 401) {
              this.CookieRemove();
          }
        })
        .finally(() => {
          this.loader = false;
          
          //this.tableArr();

        });




        reportService
          .rap6Hours(this.jobId, this.agencyId)
          .then((response) => {

              if (response.status === 200) {
              
              this.table2rows = response.data;

              for(var i = 0; i <  this.table2rows.length; i++)
              {
                  //this.data[i].hours = this.data[i].hours.replace('.', ',');
                  this.table2rows[i].hours = parseFloat(this.table2rows[i].hours);
              }

              // console.log("----jobInfo----");
              // console.log(this.jobInfo);
            }
            
          })
          .catch((e) => {
            this.error = e.message || "Something went wrong!";
            
            
            if (e.response.status === 401) {
                this.CookieRemove();
            }
          })

      }
          
    },

    rowStyleClass(row) {
      return row.name == '-Total-' ? 'cl-total' : 'cl-row';
    }

  },
  computed: {
    
  }

}
</script>

<style>

.inner-wrap3 {
  text-align: center;

}

.inner-wrap3 h3 {
  font-family: 'Anton-Regular';
  font-size: 22px;
  letter-spacing: 2px;
}

.inner-wrap3 .dp__main {
  width: 260px;
}


.inner-wrap3 .criteria {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 26px;
}


.inner-wrap3 .criteria.v-sa-a {
  flex-direction: column-reverse;
}

.inner-wrap3 .criteria.v-sa-a .control-wrap {
  margin-bottom: 8px;
}



.inner-wrap3 .vue-select {
  border-radius:initial;
  border-radius: 4px;
  z-index: 2;
  border: 1px solid #e3e3e3;
  background-color: white;
  width: 600px;
}

.inner-wrap3 .criteria.v-sa-a .agency-a .vue-select {
  width: 250px;
}

.inner-wrap3 .vue-input {
  padding: 10px 6px 10px 6px;
}
.inner-wrap3 .vue-input input {
  background-color: transparent;
  font-size: 16px;
}

.inner-wrap3 .vue-dropdown {
  border: 1px solid #e3e3e3;
}

.inner-wrap3 .vue-input .icon.arrow-downward  {
  border-width: 8px 5px 0;
}


.inner-wrap3 .vue-dropdown-item.highlighted {
    background-color: #fff8e3;
}


.inner-wrap3 .vue-dropdown-item.selected.highlighted {
    background-color: #fceec2;
}

/* .dialog-box.edit .vue-select {
  width: 100%;

} */

.inner-wrap3 .v-select ul li {
   text-align: left;
}

.inner-wrap3 .v-select ul li span {
    padding: 0;
}

.inner-wrap3 .control-wrap {
    display: flex;
    justify-content: flex-end;
    flex-grow: initial;
    align-items: center;
}


.inner-wrap3 .center-pan {
    box-shadow: 0px 3px 16px -5px rgb(120 122 123);
    position: relative;
    background-color: white;
    margin: 10px;
    margin-left: 20px;
    padding: 18px;
    min-height: 380px;
   
}

.inner-wrap3 .center-pan .c-no {

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);   
}

.inner-wrap3 .text-f14 {
    font-size: 14px;
}

.inner-wrap3 .text-f12 {
    font-size: 12px;
}

.inner-wrap3 table.vgt-table {
  font-size: 14px;
}

.inner-wrap3 .vgt-wrap__footer {
  padding: 10px 16px;
}

.inner-wrap3 .vgt-wrap__footer .footer__navigation, 
.inner-wrap3 .vgt-wrap__footer .footer__row-count__label,
.inner-wrap3 .vgt-wrap__footer .footer__navigation__page-btn span {
  font-size: 14px;
}


.inner-wrap3 .center-pan .general-info-wrap {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  width: 98%;
  margin: 0 auto;
}

.inner-wrap3 .center-pan .general-info-wrap .general-left,
.inner-wrap3 .center-pan .general-info-wrap .general-right {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.inner-wrap3 .center-pan .general-info-wrap .general-left {
  margin-right: 16px;
}

.inner-wrap3 .center-pan .general-info-wrap .general-right {
  margin-left: 16px;
}

.inner-wrap3 .center-pan .info-item label {
    overflow-wrap: break-word;
    text-align: left;
    font-size: 13px;
    font-weight: 800;
    color: #c25a10;
    display: inline-block;
    width: 158px;
}

.inner-wrap3 .center-pan .general-left .info-item label {
  width: 125px;
}

.inner-wrap3 .center-pan .info-item span {
    font-size: 13px;
    
    display: inline-block;
    min-width: 350px;
    max-width: 450px;
}

.inner-wrap3 .center-pan .general-right .info-item span {
  min-width: 200px;
}


.inner-wrap3 table.vgt-table .cl-total {
  background-color: #fafaf1;
}

.inner-wrap3 table.vgt-table.bordered td, 
.inner-wrap3 table.vgt-table.bordered th {
  padding: 8px 16px;
}


.inner-wrap3 .hour-info-wrap,
.inner-wrap3 .chart-wrap {
  margin-top: 24px;
}

.inner-wrap3 .ex-wrap {
  text-align: left;
  margin-top: 12px;
}

.inner-wrap3 .ex-wrap span {
    color: #999;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 900;
}



</style>