<template>
      <Loader :loader="loader" />
 
  
    <nav-header :showNav="showNav" :clearButtons="true">
      <template v-slot:navView>
          <h3>Jobs swapping</h3>
      </template>      
     
      <template v-slot:navLog>
        <!-- <button class="nav-btn" @click="showMenu = !showMenu"><i :class="[showMenu ? 'far':'fas', 'fa-user']"></i></button> -->
        <button class="nav-btn" @click="showMenu = !showMenu"><i :class="[showMenu ? 'fa-ellipsis-v':'fa-ellipsis-h', 'fas']"></i></button>
      </template>
  </nav-header>
 

    

      <div class="wrap wrap-missing">
          
            <div class="form-container">

              <div class="bkm">
                <button :class="['d-btn', 'd-bkm', {'active': activeBkm}]" id="d-bkm-1" @click="activeBkm = !activeBkm" title="Missing jobs">
                  <i class="fas fa-not-equal"></i>

                </button>
                <button :class="['d-btn', 'd-bkm', {'active': !activeBkm}]" id="d-bkm-2" @click="activeBkm = !activeBkm" title="Jobs swap">
                  <i class="fas fa-exchange-alt"></i>

                </button>
              </div>

              <h4 class="bkm-title">{{activeBkm ? 'Missing Jobs' : 'Swap the Job'}}</h4>

              <div v-if="activeBkm" class="bkm-miss"> 
                              
                  <div class="form-col left-column">
                      <h4>Registered by me</h4>

                      <div class="no-record" v-if="noJobOwnList.length === 0">
                        Should be happy. No Job numbers to match.
                      </div>

                      <ul class="missing-list">
                        <li v-for="(item, index) in noJobOwnList" :key="item.Id">
                          <form :name="'formOwn-'+index" class="missing-list-form">
                            <div class="form-item label-1" v-bind:title="item.Id">Work descr.:</div>
                            <span class="form-item descr-1">{{item.Descr}}</span>
                            
                            <div class="form-item label-2">Registered date:</div>
                            <span class="form-item descr-2">{{item.Sysdate}}</span>

                            <div class="form-item label-3">Responsible:</div>
                            <span class="form-item descr-3">{{item.FirstName+' '+item.LastName}}</span>

                            <div class="form-item label-5">Match to:</div>
                                <vue-select class="form-item"  
                                    v-model="noJobOwnRes[index].JobId" 
                                    :options="jobs" 
                                    label-by="jobNoName" 
                                    value-by="jobId"
                                    placeholder="select Job number" 
                                    searchable 
                                    clear-on-select 
                                    clear-on-close 
                                    close-on-select
                                  >
                                    <template #dropdown-item="{ option }">
                                      <div :class="this.$store.getters.getCurrYear == option.jobYear ? 'y-active':'y-inactive'">{{ option.jobNoName }}</div>
                                    </template>

                                </vue-select>
                                
                                <div class="form-item label-6">
                                  <button class="d-btn" @click="saveForm(index, true)" :disabled="noJobOwnRes[index].JobId == null"><i class="fas fa-save"></i></button>
                                </div>
                      
                                <div class="save-msg">
                                  {{noJobOwnRes[index].Res}}
                                </div>
                      
                          </form>
                            
                        </li>
                      </ul>

                  </div>

                  <div class="form-col right-column">
                      <h4>Assigned to me</h4>

                      <div class="no-record" v-if="noJobList.length === 0">
                        Should be happy. No Job numbers to match.
                      </div>

                      <ul class="missing-list">
                        <li v-for="(item,index) in noJobList" :key="item.Id">
                          <form :name="'form-'+index" class="missing-list-form">
                            <div class="form-item label-1" v-bind:title="item.Id">Work descr.:</div>
                            <span class="form-item descr-1">{{item.Descr}}</span>
                            
                            <div class="form-item label-2">Registered date:</div>
                            <span class="form-item descr-2">{{item.Sysdate}}</span>

                            <div class="form-item label-4">Registered by:</div>
                            <span class="form-item descr-4">{{item.FirstName+' '+item.LastName}}</span>


                              
                              <div class="form-item label-5">Match to:</div>
                              <vue-select class="form-item " 
                                  v-model="noJobRes[index].JobId" 
                                  :options="jobs" 
                                  label-by="jobNoName" 
                                  value-by="jobId"
                                  placeholder="select Job number" 
                                  searchable 
                                  clear-on-select 
                                  clear-on-close 
                                  close-on-select 
                                >
                                  <template #dropdown-item="{ option }">
                                    <div :class="this.$store.getters.getCurrYear == option.jobYear ? 'y-active':'y-inactive'">{{ option.jobNoName }}</div>
                                  </template>

                              </vue-select>
                              
                              <div class="form-item label-6">
                                <button class="d-btn" @click="saveForm(index, false)" :disabled="noJobRes[index].JobId == null"><i class="fas fa-save"></i></button>
                              </div>


                              <div class="save-msg">
                                {{noJobRes[index].Res}}
                              </div>
                              
                              
                          </form>  
                        </li>
                      </ul>


                  </div>

              </div>

              <div v-else class="bkm-swap"> 

                                
                <div class="pro-wrapper">

                  <p>All my hours of the job:</p>
                  <vue-select  
                      v-model="oldJobId" 
                      :options="entriesJobs" 
                      label-by="jobNoName" 
                      value-by="jobId"
                      placeholder="select Job number" 
                      searchable 
                      clear-on-select 
                      clear-on-close 
                      close-on-select
                      class="v-select-1" 
                    >  
                      <template #dropdown-item="{ option }">
                        <div :class="this.$store.getters.getCurrYear == option.jobYear ? 'y-active':'y-inactive'">{{ option.jobNoName }}</div>
                      </template>
                  </vue-select>

                      <!-- <span class="label-swap">Selected job: </span>
                      <span class="descr-swap">{{oldJob.jobNo}}</span> -->

                      <span class="label-swap">Total hours: </span>
                      <span class="descr-swap">{{oldJob.hoursSum}}</span>

                  <p>now assign them to the job:</p>

                  <vue-select  
                      v-model="newJobId" 
                      :options="jobsList" 
                      label-by="jobNoName" 
                      value-by="jobId"
                      placeholder="select Job number" 
                      searchable 
                      clear-on-select 
                      clear-on-close 
                      close-on-select
                      class="v-select-2" 
                    >  
                      <template #dropdown-item="{ option }">
                        <div :class="this.$store.getters.getCurrYear == option.jobYear ? 'y-active':'y-inactive'">{{ option.jobNoName }}</div>
                      </template>
                  </vue-select>

                  <button title="Swap th job" class="d-btn w-70" name="newJob" @click="swapJob()" :disabled="oldJobId == null || newJobId == null">
                      <i class="fas fa-save"></i>
                  </button>
                  <div class="msg">
                    &nbsp;{{statusMessage}}
                  </div>

                </div>


              </div>
          

            </div>
        
          
      </div>

   

  <nav-menu :showMenu="showMenu" ></nav-menu>
</template>

<script>
import shared from "@/shared";
import userService from "@/services/user.js";
import jobsService from "@/services/jobs.js";
import projectsService from "@/services/project.js";
import Loader from '../components/Loader.vue';


export default {
 components: {Loader },
 name: "App",

 data: function () {
    return {
      loader: true,
      error: null,
      showMenu: true,
      showNav: false,
      statusMessage: null,
      noJobList: [],
      noJobOwnList: [],
      jobs: [],
      noJobRes: [],
      noJobOwnRes: [],
      activeBkm: true,
      userId: this.$store.getters.userData.userId,
      entriesJobs: [{        
        jobId: 0,
        jobNoName: "",
        jobYear: 0
      }],
      oldJobId: null,
      oldJob: {},
      newJobId: null ,
      jobsList: []


    };
  },

  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    this.CookieCheckGoToLogin();

    this.uFnCheck = shared.uFnCheck;

    if (!this.uFnCheck(1)) {
      this.CookieRemove();    
    }

    if (this.userId == null) {
      this.$router.push({ name: "Home" });
    }
    
    //this.$store.commit('setAxiosState', 1);

    this.loadData();

   
  },

  watch: {

    oldJobId: {
      handler: function () {

        if (this.oldJobId == null) {
          this.oldJob = {};
        } 
        else {
          this.oldJob = this.entriesJobs.find(obj => obj.jobId === this.oldJobId);
        }
        

      },
      deep: true

    },

    statusMessage: {
      handler: function () {
        setTimeout(() => {
            this.statusMessage = null; 
        }, 3000);


      },
      deep: true

    }



  },

  methods: {
    
    loadData() {

      const data = {
                ResultName: "myEntriesJobList",
                UserId: this.userId              
            };

       console.log('const data');
       console.log(data);  
      
    
      Promise.all([userService.userNoJobList(), userService.userNoJobOwnList(), jobsService.jobs(), projectsService.uniRap(data)])
      .then(([userNoJobList, userNoJobOwnList, jobs, uniRap]) => {
        if (userNoJobList.status === 200) {
          this.noJobList = userNoJobList.data;

          this.noJobList.forEach((element) => {
            this.noJobRes.push({ Id: element.Id, JobId: null, Res: "" });
          });
        }

        if (userNoJobOwnList.status === 200) {
          this.noJobOwnList = userNoJobOwnList.data;
          this.noJobOwnList.forEach((element) => {
            this.noJobOwnRes.push({ Id: element.Id, JobId: null, Res: "" });
          });
        }

        if (jobs.status === 200) {

          this.jobs = jobs.data;
          this.jobsList = this.jobs.filter(obj => obj.jobId !== 0);

          // console.log('jobs -*-*-*-*-');
          // console.log(this.jobs);   

        }

        if (uniRap.status === 200) {

          // console.log('entriesJobList -*-*-*-*-');
          // console.log(uniRap.data);   
                
          this.entriesJobs = uniRap.data;
          //console.log(this.entriesJobs); 
        }
      })
      .catch((e) => {
        console.log("----error----");
        console.dir(e);

        this.error = e.message || "Something went wrong!";

        if (e.responses[0].status === 401) {
            this.CookieRemove();
        }
      })
      .finally(() => {
        this.loader = false;
        this.showNav = true;
        //this.$store.commit('setAxiosState', 0);
      });


    },
    
    saveForm(index, own) {
      // console.log("----saveForm----");
      // console.log(this.noJobOwnRes[index].Id+':'+this.noJobOwnRes[index].JobId);

      let noJobData = {};

      if (own) {
        noJobData = {
          noJobId: this.noJobOwnRes[index].Id,
          jobId: this.noJobOwnRes[index].JobId
        };
       
      }
      else {
        noJobData = {
          noJobId: this.noJobRes[index].Id,
          jobId: this.noJobRes[index].JobId
        };

      }
   
      userService
        .userNoJobUpdate(noJobData)
        .then((response) => {
         
          if (response.status === 200) {
            if (response.data.Code === "OK") {

              if (own) {
                  this.noJobOwnRes[index].Res = 'Excellent! Saved!';
              }
              else {
                  this.noJobRes[index].Res = 'Excellent! Saved!';
              }

              this.removeFromArr(index, own);

            } 
            else {
              this.error = response.data.Text;

              if (own) {
                  this.noJobOwnRes[index].Res = this.error;
              }
              else {
                  this.noJobRes[index].Res = this.error;
              }

            }
          }
        })
        .catch((e) => {
          this.error = e.message || "Something went wrong!";

          if (own) {
                  this.noJobOwnRes[index].Res = this.error;
          }
          else {
                  this.noJobRes[index].Res = this.error;
          }

          if (e.response.status === 401) {
              this.CookieRemove();
          }
        });


    },

    removeFromArr(index, own) {
      
      setTimeout(() => {
        if (own) {
            this.noJobOwnList.splice(index, 1);
            this.noJobOwnRes.splice(index, 1);
        }
        else {
            this.noJobList.splice(index, 1);
            this.noJobRes.splice(index, 1);
        }     
      }, 1000);
   

    },

    swapJob() {

      const data = {
        ResultName: "myEntriesJobSwap",
        UserId: this.userId,
        JobId: this.oldJobId,
        NewJobId: this.newJobId,
        Syslog: this.$store.getters.userData.userName
      };

      projectsService
          .uniRap(data)
          .then((response) => {

          if (response.status === 200) {              
               
                 console.log('swapJob -----');
                 //console.log(response.data);   

            this.statusMessage = response.data.Message; 
            
            this.oldJobId = null;
            this.newJobId = null;
            this.loadData(); 

          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";    
          this.statusMessage =  this.error;
          
          console.log('ERRORRRR!!!!');
          console.log(e); 
          
          // if (e.response.status === 401) {
          //       this.CookieRemove();
          // }
          })

    }


  },

}
</script>

<style scoped>

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: white;
  opacity: 0.6;
  z-index: 999;
}

.lds-hourglass {
  display: inline-block;

  width: 80px;
  height: 80px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid black;
  border-color: black transparent black transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}




.wrap.wrap-missing {
  flex-direction: column;
  padding-right: 62px;
}

.wrap.wrap-missing::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 37px;
  top: 65px;
  width: 300px;
  height: 300px;
  opacity: 0.1;
  background-image: url('../assets/missing.png');
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
  transform: rotate(-10deg);
}

.wrap.wrap-missing::after {
    content: ' ';
    display: block;
    position: absolute;
    right: 30px;
    bottom: 20px;
    width: 353px;
    height: 148px;
    opacity: 0.1;
    background-image: url('../assets/missing2.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
}

.form-container {
    width: 100%;
    max-width: 1090px;
    min-height: 400px;
    margin: 0 auto;
    text-align: left;
    padding: 16px;
    padding-top: 26px;
    box-shadow: 0px 3px 16px -5px rgb(120 122 123);
    position: relative;
    background-color: white;
    
}

.form-container .bkm-miss {
    display: flex;
}




ul {
  list-style-type: none;
}

ul, li {
  padding: 0;
  margin: 0;
  
}

li {
  padding-bottom: 16px;
  padding-top: 10px;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
}

.left-column .missing-list li:nth-child(even),
.right-column .missing-list li:nth-child(odd) {
  background-color: #f9f9f9;
}


.form-col {
  width: 50%;
  text-align: center;
  padding: 0 16px;
}

.form-col div {
  overflow-wrap: break-word;
  text-align: left;
  font-size: 12px;
  font-weight: 800;
  color: #c25a10;
}

.form-col span {
  font-size: 13px;
  font-weight: normal;
  display: block;
}

.form-col h4 {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.form-col .no-record {
  text-align: center;
}

.missing-list-form {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.missing-list-form .form-item {
  margin-top: 5px;
}

.missing-list-form .label-1,
.missing-list-form .label-3 {
  width: 15%;
  
}

.missing-list-form .descr-1 {
  width: 84%;
  text-align: left;
}

.missing-list-form .descr-3 {
  width: 42%;
  text-align: left;
  
}

.missing-list-form .label-2 {
  width: 19%;
}

.missing-list-form .descr-2 {
  width: 23%;
  text-align: left;
}

.missing-list-form .label-4 {
  width: 16%;
}

.missing-list-form .descr-4 {
  width: 41%;
  text-align: left;
}

.missing-list-form .label-5 {
  width: 13%;
  display: block;
}

.missing-list-form .label-6 {
  width: 11%;
  text-align: right;
}

.missing-list-form .label-6 .d-btn {
  width: initial;
  width: unset;
  margin: 0;
  padding: 3px 14px;

}

.missing-list-form .vue-select {
  width: 74%;
  display: block;
  border-radius: unset;
  color: #2c3e50;
  border: 1px solid #e3e3e3;
  background-color: white;
}

.missing-list-form .save-msg {
  position: absolute;
  bottom: 1px;
  right: 10px;
  font-size: 11px;
}

.wrap-missing .bkm {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 32px;
    margin-top: 36px;
    padding: 0 16px;
    position: relative;
}

.wrap-missing .bkm-title {
  font-family: Anton-Regular;
  font-size: 18px;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 6px;
}


.wrap-missing .bkm .d-bkm {
    position: absolute;
    bottom: -1px;
    left: 16px;
    margin: 0;
    width: 120px;
    font-size: 16px;
    z-index: 5;
}

.wrap-missing .bkm .d-bkm.active {
    background-color: #faecc3;
    z-index: 6;
}

.wrap-missing .bkm #d-bkm-1 i {
    font-size: 15px;
}

.wrap-missing .bkm #d-bkm-2 {
    left: 126px;
}

.bkm-swap .pro-wrapper {
    text-align: center;
    margin-bottom: 48px;
}

.bkm-swap .pro-wrapper p {
    padding-top: 24px;
    margin-bottom: 12px;
}

.bkm-swap .pro-wrapper .vue-select {
    margin: 0 auto;
}

.bkm-swap .pro-wrapper .vue-select.v-select-1 {
    z-index: 3;
}



.bkm-swap .pro-wrapper .vue-input {
    padding: 10px 6px 10px 6px;
}

.bkm-swap .pro-wrapper .label-swap {
    overflow-wrap: break-word;
    font-size: 12px;
    font-weight: 800;
    color: #c25a10;
}

.bkm-swap .pro-wrapper .descr-swap {
    font-size: 13px;
    font-weight: normal;
    padding-right: 10px;
}


.bkm-swap .pro-wrapper button.w-70 {
    font-size: 22px;
    margin-top: 24px;
}



.bkm-swap .msg {
    color: #c25a10;
    font-size: 14px;  
    text-align: center;
}


    



.dark-mode .form-container {

    box-shadow: 0px 3px 16px -5px rgb(24 24 24);
    background-color: #1c2630;

}

.dark-mode .missing-list-form .vue-select {
  border: 1px solid #5c5c5c;
  background-color: #2c3e50;
  color:#d1d1d1;

}

.dark-mode .missing-list-form .vue-select .vue-input {
  background-color: #2c3e50;
}

.dark-mode .vue-dropdown {
  border: 1px solid #5c5c5c;
  background-color: #2c3e50;
}

.dark-mode .vue-input input {
  background-color: #2c3e50;
}

.dark-mode .left-column .missing-list li[data-v-37700edc]:nth-child(even), 
.dark-mode .right-column .missing-list li[data-v-37700edc]:nth-child(odd) {
  background-color: #1c2630;
}

/* .dark-mode .vue-dropdown-item.selected.highlighted,
.dark-mode .vue-dropdown-item.selected,
.dark-mode .vue-dropdown-item.highlighted {
    background-color: #1c2630;
}

.dark-mode .vue-dropdown-item span {
    font-weight: normal;
} */





</style>