<template>
  <div>
    <h1>Access Process</h1>
    <p>{{ data.firstname }}</p>
    <p>{{ data.error }}</p>
    <p>{{ statusMessage }}</p>
    <p>{{ authMessage }}</p>
    <div v-if="statusMessage == '200'">
  
      <p> Zostałeś pomyślnie zalogowany <br>
      <router-link to="/home">przejdź</router-link> do serwisu </p>
 
    </div>
  </div>  
</template>

<script>

import authService from "@/services/auth.js";
import userSettings from "@/services/settings.js";
import adminService from "@/services/admin.js";
import shared from "@/shared";

export default {
  name: "AccessProcess",
  props: {
   
  },
  data() {
    return {
      post: "grant_type=password&uuid=" + this.$route.params.Uid,
      data: {},
      statusMessage: "",
      authMessage: ""
    };
  },

  created() {

    console.log("AccessProcess - created!");

    this.enc = shared.enc;

    this.data = {};
    this.createAccess();

  },
  methods: {
    async createAccess() {

      try {


        // czyszczenie tablicy uprawnień
        let emptyArr = [];        
        this.$store.commit('setUserFunction', emptyArr);
        //localStorage.userFunction = JSON.stringify(emptyArr);
        //localStorage.FnUid = JSON.stringify(emptyArr);
        localStorage.FnUid = this.enc(JSON.stringify(emptyArr));

        this.$store.commit('setUserSettings', emptyArr);
        localStorage.userSettings = JSON.stringify(emptyArr);


        const postData = await authService.createAccessPost(this.post);

      
        this.data = await postData.json();
        this.statusMessage = postData.status;

        // console.log("statusMessage");
        // console.log(this.statusMessage);
        //this.data = postData;

        //console.log('createAccess-this.data');
        //console.log(this.data);

        // console.log('postData.ok');
        // console.log(postData.ok);
        // console.log('this.statusMessage');
        // console.log(this.statusMessage);

          if (!postData.ok) {
            const error = (postData && postData.message) || postData.status;
            this.data.error = error;
            return Promise.reject(error);
          }

          if (this.statusMessage == '200') {

                  this.$cookies.set("_ts_firstname",this.data.firstname, "90d");
                  this.$cookies.set("_ts_lastname",this.data.lastname, "90d");
                  this.$cookies.set("_ts_role",this.data.role, "90d");
                  this.$cookies.set("_ts_team",this.data.team, "90d");
                  this.$cookies.set("_ts_team_id",this.data.teamId, "90d");
                  this.$cookies.set("_ts_agency",this.data.agency, "90d");
                  this.$cookies.set("_ts_agency_id",this.data.agencyId, "90d");
                  this.$cookies.set("_ts_access_token",this.data.access_token, "90d");
                  this.$cookies.set("_ts_user_id",this.data.uid, "90d");

                  this.$store.commit('setUser', {
                  accessToken: this.data.access_token,
                  userName: this.data.firstname+' '+this.data.lastname,
                  role: this.data.role,
                  team: this.data.team,
                  teamId: this.data.teamId,
                  agency: this.data.agency,
                  agencyId: this.data.agencyId,
                  userId: this.data.uid

                  });

                  let settingsArr = [];

                  // console.log('::userId:');
                  // console.log(this.data.uid);

                  this.getUserFunction(this.data.uid);

                userSettings
                  .userSettingsList()
                  .then((response) => {
                  
                        if (response.status === 200) {

                              console.log('catch - response.status');
                              console.log(response.status);


                              settingsArr = response.data;
                              this.$store.commit('setUserSettings', settingsArr);
                              localStorage.userSettings = JSON.stringify(settingsArr);

                              console.log(window.navigator.userAgent);
                              console.log('isMobile: '+this.isMobile());
                              if (this.isMobile()) {
                                
                                document.body.classList.add('mobile');
                                this.$store.commit('setIsMobile', true); 
                                localStorage.isMobile = 1;                               
                                this.$router.push({name: "MobileHome"});
                              }
                              else {
                                this.$store.commit('setIsMobile', false);
                                localStorage.isMobile = 0;
                                this.$router.push({name: "Home"});
                              }

                              

                        }
                      
                  })
                  .catch((e) => {
                        this.statusMessage = e.message || "Something went wrong!";
                  
                        console.log('catch - e.response.status');
                        console.log(e.response.status);
                  
                        if (e.response.status === 401) {
                              this.CookieRemove();
                        }
                  })
           
          }


      } catch (err) {
        this.statusMessage = err.message || "Ups coś poszło nie tak!";
      }


        // console.log('E - localStorage.userSettings');
        // console.log(localStorage.userSettings);





    },

    getUserFunction(userId) {

      const data = {
                ResultName: "UserFunction",
                UserId: userId,
              
          };

                // console.log('forecastSET - 0 -----');
                // console.log(data);   

          adminService
          .uniRap(data)
          .then((response) => {

          if (response.status === 200) {
              
               
                //  console.log('userFunction -----');
                //  console.log(response.data);   

                let newArr = [];

                response.data.forEach(element => {
                  newArr.push(element.functionId);
                });           
                
                this.$store.commit('setUserFunction', newArr);
                //localStorage.userFunction = JSON.stringify(newArr);
                localStorage.FnUid = this.enc(JSON.stringify(newArr));

          }
          
          })
          .catch((e) => {
          this.error = e.message || "Something went wrong!";      
          
          console.log('ERRORRRR!!!!!!');
          console.log(e); 
          
          // if (e.response.status === 401) {
          //       this.CookieRemove();
          // }
          })


    },

    isMobile() {
      const isMobileDevice = /Mobi|Android/i.test(window.navigator.userAgent);
      const isSmallScreen = window.innerWidth < 600;
      return isMobileDevice || isSmallScreen;
      //return false;
    }


  }
};
</script>


<style scoped>
h1 {
  margin: 40px 0 0;
  color: red;
}

</style>
